import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import _ from "lodash";
import styled from "styled-components";
import { Flex, Box } from "rebass";
import * as Icon from "react-feather";
import { BorderBottom } from "../themes";

export default class Annaunce extends Component {
  render() {
    return (
      <div className="container">
        <header>
          <Icon.ArrowLeft
            style={{ paddingBottom: "6px" }}
            onClick={() => this.props.history.goBack()}
          />
          <h1 style={{ marginLeft: 8 }}>アナウンス</h1>
        </header>
        <div style={{ marginBottom: 35, padding: 15 }}>
          <h3>ホーム</h3>
          <p>送信された全員の日報が時系列に沿って表示されます。</p>
          <p>下にスクロールすると過去の日報も見ることが出来ます。</p>
          <h3>速報</h3>
          <h4>・速報一覧ページ</h4>
          <p>各部門の速報のタイトルが表示されます。</p>
          <p>タップすると詳細ページが表示されます。</p>
          <h4>・速報詳細ページ</h4>
          <p>上部の戻るアイコンをタップすると一覧ページが表示されます。</p>
          <h3>マイページ</h3>
          <h4>・プロフィール</h4>
          <p>自分の名前と所属係、所属ブロックが表示されます。</p>
          <h4>・月度数字</h4>
          <p>自分の月度内の結果の合計が表示されます。</p>
          <h4>・マイ日報</h4>
          <p>送信された自分の日報が時系列に沿って表示されます。</p>
          <p>右側の編集ボタンを押すと日報編集ページが表示されます。</p>
          <h4>・日報編集ページ</h4>
          <p>
            市場・面談数・FT数・再訪数・リクルーター制度・備考を編集出来ます。
          </p>
          <p>
            送信ボタンをタップして、小さな画面に表示されたOKをタップしたら編集完了です。
          </p>
          <p>
            上部の戻るアイコンをタップするとそのまま元のページが表示されます。
          </p>
          <h4>・設定ボタン(画面右上）</h4>
          <p>設定、ログアウト、キャンセルが選べます。</p>
          <p>設定をタップすると設定画面が表示されます。</p>
          <p>ログアウトをタップするとログイン画面が表示されます。</p>
          <p>キャンセルをタップすると小さな画面が閉じます。</p>
          <h4>・設定画面</h4>
          <p>月度目標と月度稼働日数が保存ボタンを押すことで設定出来ます。</p>
          <p>設定をタップすると設定画面が表示されます。</p>
          <p>
            上部の戻るアイコンをタップするとそのまま元のページが表示されます。
          </p>
          <h3>日報作成ページ</h3>
          <h4>・日報ボタン</h4>
          <p>
            マイページ右下の日報ボタンをタップすると日報作成ページが表示されます。
          </p>
          <h4>・日報作成ページ</h4>
          <p>
            市場・面談数・FT数・再訪数・結果・キャンセル撤回・リクルーター制度・備考を記入します。必須項目の記入漏れがあった場合は送信ボタンを押せません。
          </p>
          <p>
            送信ボタンをタップして、小さな画面に表示されたOKをタップしたら作成完了です。
          </p>
          <h4>・結果プラスボタン</h4>
          <p>結果プラスボタンを押すとオーダー作成ページが表示されます。</p>
          <h4>・オーダー作成ページ</h4>
          <p>
            申込日・オーダー時間・再訪・お名前・お名前（ふりがな）・通告日・工事日・契約書確認・入金体系・住所・電話番号（自宅）・電話番号（携帯）・営業所名・イベントNo.・イベント括弧・オーダー状況を記入します。
          </p>
          <p>必須項目の記入漏れがあった場合は送信ボタンを押せません。 </p>
          <p>
            保存ボタンをタップして、小さな画面に表示されたOKをタップしたら作成完了です。{" "}
          </p>
          <h4>・オーダーを選択するボタン</h4>
          <p>
            キャンセル撤回の右側に表示されているオーダーを選択するボタンを選択すると、カレンダーが表示されます。
          </p>
          <h4>・カレンダー</h4>
          <p>月度・イベントタブをタップすることで切り替えることが出来ます。</p>
          <p>
            月度ごとに、係日報での全開通の表示・非表示と月度結果の非表示をタップして選択出来ます。
          </p>
          <p>右側の矢印アイコンをタップするとオーダーリストが表示されます。</p>
          <h4>・オーダーリスト</h4>
          <p>
            オーダー情報が表示されたオーダーページが時系列に沿って表示されます。
          </p>
          <p>
            キャンセル撤回を計上する場合はオーダー状況のキャンセルもしくは撤回をタップして選択すると日報作成ページに反映されます。
          </p>
          <h4>・日報戻るボタン</h4>
          <p>タップすると直接日報作成ページに戻ります。</p>
        </div>
      </div>
    );
  }
}
