import React, { Component } from "react";
import styled from "styled-components";
import { Toggle } from "react-formik-ui";
import { Flex, Box } from "rebass";
import { RoundedButton } from "../themes";
import { Formik } from "formik";
import API from "../services/api";
import ReactModal from "react-modal";
import moment from "moment";

export default class UpdateReportForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false
    };

    this.deleteReport = this.deleteReport.bind(this);
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  handleOpenModal() {
    this.setState({ showModal: true });
  }

  handleCloseModal() {
    this.setState({ showModal: false });
  }

  deleteReport = async () => {
    const { report, history } = this.props;

    API.setToken(localStorage.getItem("token"));
    const res = await API.deleteReport(report.id);

    if (res.ok) {
      history.goBack();
    } else {
      alert("エラーが発生しました。しばらくたってから再度お試しください。");
    }
  };

  renderDangerSection() {
    const { report } = this.props;

    // 当日内のレポートのみ削除を許可する
    const created = moment(report.created);

    const isToday = created.isSame(new Date(), "day");
    if (!isToday) {
      return null;
    }

    return (
      <div>
        <Flex
          justifyContent="space-between"
          style={{
            marginBottom: 35,
            marginTop: 30,
            padding: 16,
            border: "1px solid #d73a49",
            borderRadius: 5
          }}
        >
          <Box width={1 / 2}>
            <div>日報を削除する</div>
            <div style={{ fontSize: 10 }}>
              ※作成したオーダー情報、C、T、再計上、工事日変更についても取り消しが行われます。
            </div>
          </Box>
          <Box width={1 / 2}>
            <div>
              <div
                style={{
                  padding: "8px 16px",
                  backgroundColor: "#fafbfc",
                  backgroundImage:
                    "linear-gradient(-180deg,#fafbfc,#eff3f6 90%)",
                  color: "#cb2431",
                  borderRadius: 5
                }}
                onClick={this.handleOpenModal}
              >
                <span>削除する</span>
              </div>
            </div>
          </Box>
        </Flex>
        <ReactModal
          style={customStyles}
          isOpen={this.state.showModal}
          contentLabel="日報削除"
          onRequestClose={this.handleCloseModal}
        >
          <div style={{ padding: 18 }}>
            <div style={{ marginBottom: 35 }}>この日報を削除しますか？</div>

            <Flex justifyContent="space-between">
              <div onClick={this.deleteReport}>削除する</div>
              <a onClick={this.handleCloseModal}>キャンセル</a>
            </Flex>
          </div>
        </ReactModal>
      </div>
    );
  }

  render() {
    const { report } = this.props;

    return (
      <Formik
        initialValues={{ ...report }}
        validate={values => {
          let errors = {};
          if (!values.marketPrefecture) {
            errors.marketPrefecture = "必須項目です";
          }
          if (!values.marketCity) {
            errors.marketCity = "必須項目です";
          }

          return errors;
        }}
        onSubmit={async (values, { setSubmitting }) => {
          API.setToken(localStorage.getItem("token"));
          let res = await API.updateReport(report.id, values);

          if (res.ok) {
            setSubmitting(false);
            this.props.history.goBack();
          } else {
            setSubmitting(false);
            alert(
              "エラーが発生しました。しばらくたってから再度お試しください。"
            );
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting
        }) => (
          <ReportForm onSubmit={handleSubmit}>
            <Flex
              justifyContent="space-between"
              style={{ marginBottom: 15, height: 45 }}
            >
              <Box width={2 / 4}>
                <span>市場</span>
              </Box>
              <Box width={1 / 4}>
                <input
                  type="text"
                  name="marketPrefecture"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.marketPrefecture}
                  placeholder="都道府県"
                />
                {errors.marketPrefecture && touched.marketPrefecture && (
                  <ErrorMessage>{errors.marketPrefecture}</ErrorMessage>
                )}
              </Box>
              <Box width={1 / 4}>
                <input
                  type="text"
                  name="marketCity"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.marketCity}
                  placeholder="市町村区"
                />
                {errors.marketCity && touched.marketCity && (
                  <ErrorMessage>{errors.marketCity}</ErrorMessage>
                )}
              </Box>
            </Flex>

            <Flex
              justifyContent="flex-end"
              style={{ marginBottom: 15, height: 60 }}
            >
              <Box width={1 / 2}>
                <input
                  type="text"
                  name="marketArea"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.marketArea}
                  placeholder="エリア（ゼンリン表記）"
                />
                {errors.marketArea && touched.marketArea && (
                  <ErrorMessage>{errors.marketArea}</ErrorMessage>
                )}
              </Box>
            </Flex>

            <Flex
              justifyContent="space-between"
              style={{ marginTop: 10, height: 45 }}
            >
              <Box
                width={1 / 2}
                style={{ display: "flex", flexDirection: "column" }}
              >
                <span>市場 (2)</span>
              </Box>
              <Box width={1 / 2}>
                <input
                  type="text"
                  name="marketPrefecture2"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.marketPrefecture2}
                  placeholder="都道府県"
                />
              </Box>
            </Flex>

            <Flex
              justifyContent="flex-end"
              style={{ marginTop: 10, height: 60 }}
            >
              <Box width={1 / 2}>
                <input
                  type="text"
                  name="marketCity2"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.marketCity2}
                  placeholder="市町村区"
                />
              </Box>
            </Flex>
            <Flex
              justifyContent="flex-end"
              style={{ marginBottom: 35, height: 60 }}
            >
              <Box width={1 / 2}>
                <input
                  type="text"
                  name="marketArea2"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.marketArea2}
                  placeholder="エリア（ゼンリン表記)"
                />
              </Box>
            </Flex>

            <Flex
              justifyContent="space-between"
              style={{ marginBottom: 15, height: 60 }}
            >
              <Box width={1 / 2}>
                <span>面談数</span>
              </Box>
              <Box width={1 / 2}>
                <NumberInput
                  type="number"
                  min="0"
                  max="100"
                  name="meetingCount"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.meetingCount}
                />
                {errors.meetingCount && touched.meetingCount && (
                  <ErrorMessage>{errors.meetingCount}</ErrorMessage>
                )}
              </Box>
            </Flex>

            <Flex
              justifyContent="space-between"
              style={{ marginBottom: 15, height: 60 }}
            >
              <Box width={1 / 2}>
                <span>FT数</span>
              </Box>
              <Box width={1 / 2}>
                <NumberInput
                  type="number"
                  min="0"
                  max="50"
                  name="talkCount"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.talkCount}
                />
                {errors.talkCount && touched.talkCount && (
                  <ErrorMessage>{errors.talkCount}</ErrorMessage>
                )}
              </Box>
            </Flex>

            <Flex
              justifyContent="space-between"
              style={{ marginBottom: 15, height: 60 }}
            >
              <Box width={1 / 2}>
                <span>再訪数</span>
              </Box>
              <Box width={1 / 2}>
                <NumberInput
                  type="number"
                  min="0"
                  max="50"
                  name="revisitCount"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.revisitCount}
                />
                {errors.revisitCount && touched.revisitCount && (
                  <ErrorMessage>{errors.revisitCount}</ErrorMessage>
                )}
              </Box>
            </Flex>

            <Flex
              justifyContent="space-between"
              style={{ marginBottom: 15, height: 30, marginTop: 30 }}
            >
              <Box width={1 / 2}>
                <span>リクルーター制度</span>
              </Box>
              <Box width={1 / 2}>
                <div>
                  <label>
                    <Toggle name="isRecruiting" />
                  </label>
                </div>
              </Box>
            </Flex>

            <div style={{ marginBottom: 16 }}>
              <div style={{ marginBottom: 8 }}>備考</div>
              <textarea
                name="description"
                placeholder="同行報告など"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.description}
              />
            </div>

            {this.renderDangerSection()}

            <div style={{ textAlign: "center" }}>
              <RoundedButton
                style={{ padding: 8, width: 200 }}
                type="submit"
                isSubmitting={isSubmitting}
                disabled={isSubmitting}
              >
                <span>{isSubmitting ? "送信中..." : "送信"}</span>
              </RoundedButton>
            </div>
          </ReportForm>
        )}
      </Formik>
    );
  }
}

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: 0,
    borderRadius: 0
  }
};

UpdateReportForm.defaultProps = {
  report: {
    marketPrefecture: "",
    marketCity: "",
    marketArea: "",
    meetingCount: 0,
    talkCount: 0,
    revisitCount: 0,
    isRecruiting: false,
    description: ""
  }
};

const NumberInput = styled.input`
  text-align: right;
`;

const ErrorMessage = styled.span`
  font-size: 10px;
`;

const ReportForm = styled.form`
  padding: 16px;

  & input {
    font-size: 16px;
    padding: 8px;
  }
`;
