import { CALENDER, UPDATE_EVENT_STATUS } from "../actions";
import Immutable from "seamless-immutable";
import { normalize, schema } from "normalizr";

const eventSchema = new schema.Entity("event");
const eventListSchema = new schema.Array(eventSchema);

const initialState = Immutable({
  entities: {},
  result: []
});

const calender = (state = initialState, action) => {
  switch (action.type) {
    case CALENDER["REQUEST"]:
      return initialState;
    case CALENDER["SUCCESS"]:
      const normalizedData = normalize(action.response, eventListSchema);
      return state.merge({
        ...normalizedData
      });
    case UPDATE_EVENT_STATUS:
      return state
        .setIn(
          ["entities", "event", [action.id], "opened"],
          action.status.opened
        )
        .setIn(["entities", "event", [action.id], "hide"], action.status.hide);
    default:
      return state;
  }
};

export default calender;
