import React, { Component } from "react";
import styled from "styled-components";
import { Flex, Box } from "rebass";
import { getOrderScore } from "../utils";
import moment from "moment";

export default class Report extends Component {
  renderTeamName() {
    const { user } = this.props.report;
    if (user.team) {
      return <TeamName>@{user.team.name}</TeamName>;
    }
  }

  render() {
    const {
      user,
      meetingCount,
      talkCount,
      description,
      orderDetails,
      created
    } = this.props.report;
    let formattedTime = moment(created).format("HH:mm");
    const { orderCount, cancelWithdrawalCount } = orderDetails;
    const {
      grossCount,
      grossStoveCount,
      grossApCount,
      cancelCount,
      cancelStoveCount,
      withdrawalCount,
      withdrawalStoveCount,
      cancelApCount,
      withdrawalApCount
    } = getOrderScore(orderCount, cancelWithdrawalCount);

    const cancelWithdrawalMonths = Object.keys(cancelWithdrawalCount);

    return (
      <Container>
        <Flex justifyContent="space-between">
          <Box
            width={1 / 3}
            style={{ display: "flex", flexDirection: "column" }}
          >
            <UserName>{user.name}</UserName>
            <div style={{ marginTop: "3px", fontSize: "11px" }}>
              {formattedTime}
            </div>
          </Box>

          <Box width={1 / 3}>
            <ScoreContainer justifyContent="flex-start">
              <Box>
                <div>結果</div>
                <Score>{meetingCount}</Score>/<Score>{talkCount}</Score>/
                <Score>{grossCount}</Score>
                <Score>
                  ({grossStoveCount}){grossApCount > 0 && ` AP${grossApCount} `}
                </Score>
              </Box>
            </ScoreContainer>
          </Box>
          <Box width={1 / 3}>
            <ScoreContainer justifyContent="flex-start">
              <Box>
                <div>C/T</div>
                <Score>{cancelCount}</Score>
                <Score>
                  ({cancelStoveCount})
                  {cancelApCount > 0 && ` AP${cancelApCount} `}
                </Score>
                /<Score>{withdrawalCount}</Score>
                <Score>
                  ({withdrawalStoveCount})
                  {withdrawalApCount > 0 && ` AP${withdrawalApCount} `}
                </Score>
                <Score>{cancelWithdrawalMonths.join()}</Score>
              </Box>
            </ScoreContainer>
          </Box>
        </Flex>

        <Flex>
          <Description>{description}</Description>
        </Flex>
      </Container>
    );
  }
}

const Container = styled.li`
  list-style: none;
  border-bottom: 1px solid;
  border-bottom-color: rgb(230, 236, 240);
  padding: 16px;
`;

const UserName = styled.span`
  font-weight: bold;
  font-size: 15px;
`;

const TeamName = styled.span`
  color: rgb(101, 119, 134);
  margin-left: 5px;
  font-size: 12px;
`;

const ScoreContainer = styled(Flex)``;

const Score = styled.span`
  font-size: 14px;
  font-weight: bold;
`;

const Description = styled.p`
  margin-top: 5px;
  margin-bottom: 0;
`;
