import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { loadEntryYearReports } from "../../actions";
import styled from "styled-components";
import * as Icon from "react-feather";
import { Flex, Box } from "rebass";
import { BorderBottom } from "../../themes";

class EntryYearReport extends Component {
  componentWillMount() {
    this.props.loadEntryYearReports();
  }

  render() {
    const { entryYearReports } = this.props;
    const { items } = entryYearReports;

    if (items.length <= 0) {
      return (
        <div className="container">
          <header>
            <Icon.ArrowLeft
              style={{ paddingBottom: "6px" }}
              onClick={() => this.props.history.goBack()}
            />
            <h1 style={{ marginLeft: 8 }}>入社年別速報</h1>
          </header>

          <div style={{ padding: 16 }}>現在準備中!</div>
        </div>
      );
    }

    return (
      <div className="container">
        <header>
          <Icon.ArrowLeft
            style={{ paddingBottom: "6px" }}
            onClick={() => this.props.history.goBack()}
          />
          <h1 style={{ marginLeft: 8 }}>入社年別速報</h1>
        </header>

        <RankingList>
          {items.map((ranking, i) => {
            return (
              <RankingItem key={i}>
                <Flex justifyContent="space-between">
                  <Box width={1 / 2}>
                    <Rank>{ranking.rank}</Rank>
                    <Name>{ranking.entryYear}</Name>
                  </Box>
                  <Box width={1 / 2} style={{ textAlign: "center" }}>
                    <Value>{ranking.prediction}</Value>
                  </Box>
                </Flex>
              </RankingItem>
            );
          })}
        </RankingList>
      </div>
    );
  }
}

const RankingList = styled.ul``;

const RankingItem = styled.li`
  padding: 16px;
  ${BorderBottom}
`;

const Rank = styled.span`
  color: rgb(101, 119, 134);
  margin-right: 16px;
  float: left;
  font-weight: bold;
`;

const Name = styled.div`
  margin-left: 5px;
  font-weight: bold;
  font-size: 18px;
  text-align: left;
  width: 100%;
`;

const Value = styled.span`
  color: rgb(101, 119, 134);
  margin-left: 5px;
  text-align: center;
  font-weight: bold;
`;

function mapStateToProps(state) {
  return {
    entryYearReports: state.entryYearReports
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    {
      loadEntryYearReports
    }
  )(EntryYearReport)
);
