import React, { Component } from "react";
import { Flex, Box } from "rebass";
import ReactModal from "react-modal";
import styled from "styled-components";
import * as moment from "moment";
import Immutable from "seamless-immutable";
import { Formik } from "formik";

export default class ChangeOrderDateModal extends Component {
  render() {
    let {
      showModal,
      handleCloseModal,
      selectedOrder,
      handleSubmit
    } = this.props;

    return (
      <ReactModal
        style={customStyles}
        isOpen={showModal}
        contentLabel="設定"
        onRequestClose={handleCloseModal}
      >
        <div>
          <Formik
            initialValues={{
              noticeDate:
                moment(selectedOrder.noticeDate).format("YYYY-MM-DD") || "",
              constructionDate:
                moment(selectedOrder.constructionDate).format("YYYY-MM-DD") ||
                ""
            }}
            validate={values => {
              let errors = {};
              // 通告日、工事日は入力必須
              if (!values.noticeDate) {
                errors.noticeDate = "必須項目です";
              }
              if (!values.constructionDate) {
                errors.constructionDate = "必須項目です";
              }
              return errors;
            }}
            onSubmit={async (values, { setSubmitting }) => {
              // 入力された通告日、工事日を設定する
              selectedOrder = Immutable.asMutable(selectedOrder);
              selectedOrder.noticeDate = values.noticeDate;
              selectedOrder.constructionDate = values.constructionDate;

              setSubmitting(false);
              handleSubmit(selectedOrder);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting
            }) => (
              <form onSubmit={handleSubmit}>
                <Flex
                  justifyContent="space-between"
                  style={{ marginBottom: 15, height: 60, padding: 30 }}
                >
                  <Box
                    width={1 / 4}
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <span>通告日</span>
                  </Box>
                  <Box width={3 / 4}>
                    <NumberInput
                      type="date"
                      name="noticeDate"
                      onChange={e => {
                        handleChange(e);

                        // 通告日を設定したら工事日を通告日をベースとして8日後に自動で設定する
                        let noticeDate = e.currentTarget.value;
                        const dateMargin = 8;
                        values.constructionDate = moment(noticeDate)
                          .add(dateMargin, "days")
                          .format("YYYY-MM-DD");
                      }}
                      onBlur={handleBlur}
                      value={values.noticeDate}
                    />
                    {errors.noticeDate && touched.noticeDate && (
                      <ErrorMessage>{errors.noticeDate}</ErrorMessage>
                    )}
                  </Box>
                </Flex>

                <Flex
                  justifyContent="space-between"
                  style={{ marginBottom: 60, height: 60, padding: 30 }}
                >
                  <Box
                    width={1 / 4}
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <span>工事日</span>
                  </Box>
                  <Box width={3 / 4}>
                    <NumberInput
                      type="date"
                      name="constructionDate"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.constructionDate}
                    />
                    {errors.constructionDate && touched.constructionDate && (
                      <ErrorMessage>{errors.constructionDate}</ErrorMessage>
                    )}
                  </Box>
                </Flex>

                <Flex justifyContent="space-between">
                  <button type="submit" disabled={isSubmitting}>
                    決定
                  </button>
                  <a onClick={handleCloseModal}>キャンセル</a>
                </Flex>
              </form>
            )}
          </Formik>
        </div>
      </ReactModal>
    );
  }
}

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: 0,
    borderRadius: 0,
    padding: 20
  }
};

const ErrorMessage = styled.span`
  margin-top: 5px;
  font-size: 12px;
  font-weight: 600;
  color: #fa8072;
`;

const NumberInput = styled.input`
  text-align: right;
`;
