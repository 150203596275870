import { ORDER } from "../actions";
import Immutable from "seamless-immutable";

const initialState = Immutable({
  isFetching: false,
  entities: {
    orders: {}
  },
  result: ""
});

const order = (state = initialState, action) => {
  switch (action.type) {
    case ORDER["REQUEST"]:
      return state.merge({
        isFetching: true
      });
    case ORDER["SUCCESS"]:
      return state.merge({ isFetching: false, ...action.response });
    default:
      return state;
  }
};

export default order;
