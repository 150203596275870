import {
  ADD_ORDER,
  UPDATE_ORDER,
  REMOVE_ORDER,
  ADD_CANCEL_ORDER,
  REMOVE_CANCEL_ORDER,
  ADD_WITHDRAWAL_ORDER,
  REMOVE_WITHDRAWAL_ORDER,
  ADD_REORDERED_ORDER,
  REMOVE_REORDERED_ORDER,
  ADD_CHANGED_ORDER,
  REMOVE_CHANGED_ORDER,
  RESET_SELECTED_ORDERS
} from "../actions";
import Immutable from "seamless-immutable";

const initialState = Immutable({
  orders:
    localStorage.getItem("createOrders") !== null
      ? JSON.parse(localStorage.getItem("createOrders"))
      : [],
  cancelOrders: [],
  withdrawalOrders: [],
  reorderedOrders: [],
  changedOrders: []
});

const createReport = (state = initialState, action) => {
  switch (action.type) {
    case ADD_ORDER:
      let addOrders = [{ ...action.order }, ...state.orders];
      localStorage.setItem("createOrders", JSON.stringify(addOrders));
      return state.merge({
        orders: addOrders
      });
    case UPDATE_ORDER:
      const { index, order } = action
      let updateOrders = JSON.parse(localStorage.getItem("createOrders"))
      updateOrders[index] = order
      localStorage.setItem("createOrders", JSON.stringify(updateOrders))
      return state.merge({
        orders: updateOrders
      })
    // return state.setIn(['orders', index, order]);
    case REMOVE_ORDER:
      let orders = state.orders.filter((item, index) => index !== action.index);
      if (orders.length !== 0) {
        localStorage.setItem("createOrders", JSON.stringify(orders));
      } else {
        localStorage.removeItem("createOrders");
      }
      return state.merge({ orders });
    case ADD_CANCEL_ORDER:
      return state.merge({
        cancelOrders: [{ ...action.order }, ...state.cancelOrders]
      });
    case REMOVE_CANCEL_ORDER:
      let cancelOrders = state.cancelOrders.filter(
        item => item.id !== action.id
      );
      return state.merge({ cancelOrders });
    case ADD_WITHDRAWAL_ORDER:
      return state.merge({
        withdrawalOrders: [{ ...action.order }, ...state.withdrawalOrders]
      });
    case REMOVE_WITHDRAWAL_ORDER:
      let withdrawalRemovedOrders = state.withdrawalOrders.filter(
        item => item.id !== action.id
      );
      return state.merge({ withdrawalOrders: withdrawalRemovedOrders });
    case ADD_REORDERED_ORDER:
      return state.merge({
        reorderedOrders: [{ ...action.order }, ...state.reorderedOrders]
      });
    case REMOVE_REORDERED_ORDER:
      let reorderedOrders = state.reorderedOrders.filter(
        item => item.id !== action.id
      );
      return state.merge({ reorderedOrders });
    case ADD_CHANGED_ORDER:
      return state.merge({
        changedOrders: [{ ...action.order }, ...state.changedOrders]
      });
    case REMOVE_CHANGED_ORDER:
      let changedOrders = state.changedOrders.filter(
        item => item.id !== action.id
      );
      return state.merge({ changedOrders });
    case RESET_SELECTED_ORDERS:
      localStorage.removeItem("createOrders");
      return state.merge({
        orders: [],
        cancelOrders: [],
        withdrawalOrders: [],
        reorderedOrders: [],
        changedOrders: []
      });
    default:
      return state;
  }
};

export default createReport;
