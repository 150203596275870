import { ORDER_SCORE } from "../actions";
import Immutable from "seamless-immutable";

const initialState = Immutable({
  count: {},
  items: []
});

const orderScore = (state = initialState, action) => {
  switch (action.type) {
    case ORDER_SCORE["REQUEST"]:
      return initialState;
    case ORDER_SCORE["SUCCESS"]:
      return state.merge({ ...action.response });
    default:
      return state;
  }
};

export default orderScore;
