import React, { Component } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Flex, Box, Heading } from "rebass";
import * as Icon from "react-feather";
import Layout from "../../components/Layout";

const menus = [
  { title: "全体・ブロック効率速報", to: "/reports/block" },
  { title: "係効率速報", to: "/reports/group" },
  { title: "個人速報", to: "/reports/solo" },
  { title: "入社年別速報", to: "/reports/entry-year" },
  { title: "先輩イチハチ効率速報", to: "/reports/old-new" }
];

export default class ReportList extends Component {
  render() {
    return (
      <Layout>
        <header>
          <Heading>速報</Heading>
        </header>

        <ul>
          {menus.map((menu, i) => {
            return (
              <ListItem key={i}>
                <Link to={menu.to}>
                  <Flex justifyContent={"space-between"}>
                    <Box>{menu.title}</Box>
                    <Box>
                      <Icon.ChevronRight />
                    </Box>
                  </Flex>
                </Link>
              </ListItem>
            );
          })}
        </ul>
      </Layout>
    );
  }
}

const ListItem = styled.li`
  display: flex;
  align-items: center;
  list-style: none;
  margin-top: 1px;
  padding: 0;
  width: 100%;
  cursor: pointer;
  border-bottom: 1px solid;
  border-bottom-color: rgb(230, 236, 240);

  & > a {
    width: 100%;
    padding: 16px;
    text-decoration: none;
  }
`;
