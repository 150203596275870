import { delay } from "redux-saga";
import { call, put, all, take, fork, select } from "redux-saga/effects";
import * as actions from "../actions";
import API from "../services/api";
import { normalize, schema } from "normalizr";

const orderSchema = new schema.Entity("orders");
const reportSchema = new schema.Entity("reports");

const {
  me,
  blockReports,
  entryYearReports,
  teamReport,
  teamReports,
  soloReports,
  feeds,
  report,
  order,
  orders,
  orderScore,
  calender,
  adminReports,
  adminReportOrders
} = actions;

function* loadOrdersPage(action) {
  API.setToken(localStorage.getItem("token"));
  const response = yield call(API.getOrders, action.year, action.month);

  if (response.ok) {
    yield put(orders.success(response.data));
  } else {
    yield put(orders.failure());
  }
}

function* loadTimelinePage() {
  API.setToken(localStorage.getItem("token"));
  const response = yield call(API.getFeeds);

  if (response.ok) {
    yield put(feeds.success(response.data));
  } else {
    yield put(feeds.failure());
  }
}

function* loadMoreTimeline() {
  API.setToken(localStorage.getItem("token"));
  const state = yield select();
  const response = yield call(API.getFeeds, state.feeds.page + 1);

  if (response.ok) {
    yield put(feeds.loadmore(response.data));
  } else {
    yield put(feeds.failure());
  }
}

function* loadMypage() {
  API.setToken(localStorage.getItem("token"));
  const response = yield call(API.getMe);

  if (response.ok) {
    const score = yield call(API.getUserScore, response.data.user.id);
    if (score.ok) {
      yield put(me.updateScore(score.data));
    }

    yield put(me.success(response.data));
  } else {
    yield put(me.failure());
  }
}

function* loadMoreMyReport() {
  API.setToken(localStorage.getItem("token"));
  const state = yield select();
  const response = yield call(API.getMyReport, state.user.page + 1);

  if (response.ok) {
    yield put(me.loadmore(response.data));
  } else {
    yield put(me.failure());
  }
}

function* loadBlockReports() {
  API.setToken(localStorage.getItem("token"));
  const response = yield call(API.getBlockReports);

  if (response.ok) {
    yield put(blockReports.success(response.data));
  } else {
    yield put(blockReports.failure());
  }
}

function* loadEntryYearReports() {
  API.setToken(localStorage.getItem("token"));
  const response = yield call(API.getEntryYearReports);

  if (response.ok) {
    yield put(entryYearReports.success(response.data));
  } else {
    yield put(entryYearReports.failure());
  }
}

function* loadTeamReports() {
  API.setToken(localStorage.getItem("token"));
  const response = yield call(API.getTeamReports);

  if (response.ok) {
    yield put(teamReports.success(response.data));
  } else {
    yield put(teamReports.failure());
  }
}

function* loadSoloReports() {
  API.setToken(localStorage.getItem("token"));
  const response = yield call(API.getSoloReports);

  if (response.ok) {
    yield put(soloReports.success(response.data));
  } else {
    yield put(soloReports.failure());
  }
}

function* loadUpdateReportPage(action) {
  yield put(report.request());
  API.setToken(localStorage.getItem("token"));
  const response = yield call(API.getReport, action.id);

  if (response.ok) {
    const normalized = normalize(response.data, reportSchema);
    yield put(report.success(normalized));
  } else {
    yield put(report.failure());
  }
}

function* loadUpdateOrderPage(action) {
  yield put(order.request());
  API.setToken(localStorage.getItem("token"));
  const response = yield call(API.getOrder, action.id);

  if (response.ok) {
    const normalized = normalize(response.data, orderSchema);
    yield put(order.success(normalized));
  } else {
    yield put(order.failure());
  }
}

// Admin
function* loadAdminReportsPage(action) {
  yield put(adminReports.request(action.date));

  API.setToken(localStorage.getItem("token"));
  yield call(delay, 500);
  const response = yield call(API.getAdminReports, 1, action.date);

  if (response.ok) {
    yield put(adminReports.success(response.data));
  } else {
    yield put(adminReports.failure());
  }
}

function* loadAdminMoreReports() {
  API.setToken(localStorage.getItem("token"));
  const state = yield select();
  const response = yield call(
    API.getAdminReports,
    state.adminReports.page + 1,
    state.adminReports.date
  );

  if (response.ok) {
    yield put(adminReports.loadmore(response.data));
  } else {
    yield put(adminReports.failure());
  }
}

function* loadAdminReportOrders(action) {
  API.setToken(localStorage.getItem("token"));
  const response = yield call(API.getAdminReportOrders, action.reportId);

  if (response.ok) {
    yield put(adminReportOrders.success(action.reportId, response.data));
  } else {
    yield put(adminReportOrders.failure());
  }
}

/******************************************************************************/
/******************************* WATCHERS *************************************/
/******************************************************************************/

// Timeline
function* watchLoadTimelinePage() {
  while (true) {
    yield take(actions.LOAD_TIMELINE_PAGE);
    yield fork(loadTimelinePage);
  }
}

function* watchLoadMoreTimeline() {
  while (true) {
    yield take(actions.LOAD_MORE_TIMELINE);
    yield fork(loadMoreTimeline);
  }
}

// Mypage
function* watchLoadMyPage() {
  while (true) {
    yield take(actions.LOAD_MYPAGE);
    yield fork(loadMypage);
  }
}

function* watchLoadMoreMyReport() {
  while (true) {
    yield take(actions.LOAD_MORE_MY_REPORT);
    yield fork(loadMoreMyReport);
  }
}

// Prompt Reports
function* watchLoadBlockReportsPage() {
  while (true) {
    yield take(actions.LOAD_BLOCK_REPORTS);
    yield fork(loadBlockReports);
  }
}

function* watchLoadEntryYearReportsPage() {
  while (true) {
    yield take(actions.LOAD_ENTRY_YEAR_REPORTS);
    yield fork(loadEntryYearReports);
  }
}

function* watchLoadTeamReportsPage() {
  while (true) {
    yield take(actions.LOAD_TEAM_REPORTS);
    yield fork(loadTeamReports);
  }
}

function* watchLoadSoloReportsPage() {
  while (true) {
    yield take(actions.LOAD_SOLO_REPORTS);
    yield fork(loadSoloReports);
  }
}

// Orders
function* watchOrdersPage() {
  while (true) {
    const action = yield take(actions.LOAD_ORDERS_PAGE);
    yield fork(loadOrdersPage, action);
  }
}

function* watchOrderPage() {
  while (true) {
    const action = yield take(actions.LOAD_UPDATE_ORDER_PAGE);
    yield fork(loadUpdateOrderPage, action);
  }
}

// Reports
function* watchReportPage() {
  while (true) {
    const action = yield take(actions.LOAD_UPDATE_REPORT_PAGE);
    yield fork(loadUpdateReportPage, action);
  }
}

// Calender
function* loadCalenderPage() {
  API.setToken(localStorage.getItem("token"));
  const response = yield call(API.getCalender);

  if (response.ok) {
    yield put(calender.success(response.data));
  } else {
    yield put(calender.failure());
  }
}
function* watchLoadCalenderPage() {
  while (true) {
    const action = yield take(actions.LOAD_CALENDER_PAGE);
    yield fork(loadCalenderPage, action);
  }
}

// TeamReport
function* loadCreateTeamReportPage() {
  yield put(teamReport.request());
  API.setToken(localStorage.getItem("token"));
  const response = yield call(API.getTeamReport);

  if (response.ok) {
    yield put(teamReport.success(response.data));
  } else {
    yield put(teamReport.failure());
  }
}
function* watchLoadCreateTeamReportPage() {
  while (true) {
    const action = yield take(actions.LOAD_CREATE_TEAM_REPORT_PAGE);
    yield fork(loadCreateTeamReportPage, action);
  }
}

// Admin
function* watchLoadAdminReportsPage() {
  while (true) {
    const action = yield take(actions.LOAD_ADMIN_REPORTS_PAGE);
    yield fork(loadAdminReportsPage, action);
  }
}

function* watchLoadAdminMoreReports() {
  while (true) {
    const action = yield take(actions.LOAD_ADMIN_MORE_REPORTS);
    yield fork(loadAdminMoreReports, action);
  }
}
function* watchLoadAdminReportOrders() {
  while (true) {
    const action = yield take(actions.LOAD_ADMIN_REPORT_ORDERS);
    yield fork(loadAdminReportOrders, action);
  }
}

export default function* root() {
  yield all([
    // Timeline
    fork(watchLoadTimelinePage),
    fork(watchLoadMoreTimeline),

    // Mypage
    fork(watchLoadMyPage),
    fork(watchLoadMoreMyReport),

    // Reports
    fork(watchLoadBlockReportsPage),
    fork(watchLoadEntryYearReportsPage),
    fork(watchLoadTeamReportsPage),
    fork(watchLoadSoloReportsPage),

    // Order List
    fork(watchOrdersPage),
    // Order Detail
    fork(watchOrderPage),

    // Report Detail
    fork(watchReportPage),

    // Calender
    fork(watchLoadCalenderPage),

    // TeamReport
    fork(watchLoadCreateTeamReportPage),

    // Admin
    fork(watchLoadAdminReportsPage),
    fork(watchLoadAdminMoreReports),
    fork(watchLoadAdminReportOrders)
  ]);
}
