import { FEEDS } from "../actions";
import Immutable from "seamless-immutable";

const initialState = Immutable({
  pageInfo: {},
  edges: [],
  page: 1
});

const feeds = (state = initialState, action) => {
  switch (action.type) {
    case FEEDS["REQUEST"]:
      return initialState;
    case FEEDS["SUCCESS"]:
      return state.merge({
        pageInfo: action.response.reportsConnection.pageInfo,
        edges: action.response.reportsConnection.edges,
        page: 1
      });
    case FEEDS["LOADMORE"]:
      return state.merge({
        pageInfo: action.response.reportsConnection.pageInfo,
        edges: [...state.edges, ...action.response.reportsConnection.edges],
        page: state.page + 1
      });
    default:
      return state;
  }
};

export default feeds;
