const REQUEST = "REQUEST";
const SUCCESS = "SUCCESS";
const LOADMORE = "LOADMORE";
const FAILURE = "FAILURE";
const UPDATE_SCORE = "UPDATE_SCORE";

function createRequestTypes(base) {
  return [REQUEST, SUCCESS, LOADMORE, UPDATE_SCORE, FAILURE].reduce(
    (acc, type) => {
      acc[type] = `${base}_${type}`;
      return acc;
    },
    {}
  );
}

function action(type, payload = {}) {
  return { type, ...payload };
}

export const FEEDS = createRequestTypes("FEEDS");
export const ME = createRequestTypes("ME");
export const ORDER_SCORE = createRequestTypes("ORDER_SCORE");
export const ORDER = createRequestTypes("ORDER");
export const ORDERS = createRequestTypes("ORDERS");
export const REPORT = createRequestTypes("REPORT");
export const REPORTS = createRequestTypes("REPORTS");
export const BLOCK_REPORTS = createRequestTypes("BLOCK_REPORTS");
export const TEAM_REPORTS = createRequestTypes("TEAM_REPORTS");
export const SOLO_REPORTS = createRequestTypes("SOLO_REPORTS");
export const ENTRY_YEAR_REPORTS = createRequestTypes("ENTRY_YEAR_REPORTS");
export const OLD_NEW_REPORTS = createRequestTypes("OLD_NEW_REPORTS");

// Admin
export const ADMIN_REPORT_ORDERS = createRequestTypes("ADMIN_REPORT_ORDERS");
export const ADMIN_REPORTS = createRequestTypes("ADMIN_REPORTS");

export const LOAD_TIMELINE_PAGE = "LOAD_TIMELINE_PAGE";
export const LOAD_MORE_TIMELINE = "LOAD_MORE_TIMELINE";
export const LOAD_BLOCK_REPORTS = "LOAD_BLOCK_REPORTS";
export const LOAD_TEAM_REPORTS = "LOAD_TEAM_REPORTS";
export const LOAD_SOLO_REPORTS = "LOAD_SOLO_REPORTS";
export const LOAD_ENTRY_YEAR_REPORTS = "LOAD_ENTRY_YEAR_REPORTS";
export const LOAD_OLD_NEW_REPORTS = "LOAD_OLD_NEW_REPORTS";
export const LOAD_MYPAGE = "LOAD_MYPAGE";
export const LOAD_MORE_MY_REPORT = "LOAD_MORE_MY_REPORT";
export const LOAD_ORDERS_PAGE = "LOAD_ORDERS_PAGE";
export const LOAD_UPDATE_ORDER_PAGE = "LOAD_UPDATE_ORDER_PAGE";
export const LOAD_UPDATE_REPORT_PAGE = "LOAD_UPDATE_REPORT_PAGE";
export const LOAD_ORDER_SCORE = "LOAD_ORDER_SCORE";

export const LOAD_ADMIN_REPORTS_PAGE = "LOAD_ADMIN_REPORTS_PAGE";
export const LOAD_ADMIN_MORE_REPORTS = "LOAD_ADMIN_MORE_REPORTS";
export const LOAD_ADMIN_REPORT_ORDERS = "LOAD_ADMIN_REPORT_ORDERS";

export const RESET_ERROR_MESSAGE = "RESET_ERROR_MESSAGE";

export const feeds = {
  request: () => action(FEEDS[REQUEST]),
  success: response => action(FEEDS[SUCCESS], { response }),
  loadmore: response => action(FEEDS[LOADMORE], { response }),
  failure: error => action(FEEDS[FAILURE], { error })
};

export const me = {
  request: () => action(ME[REQUEST]),
  success: response => action(ME[SUCCESS], { response }),
  failure: error => action(ME[FAILURE], { error }),
  loadmore: response => action(ME[LOADMORE], { response }),
  updateScore: response => action(ME[UPDATE_SCORE], { response })
};

export const orderScore = {
  request: () => action(ORDER_SCORE[REQUEST]),
  success: response => action(ORDER_SCORE[SUCCESS], { response }),
  failure: error => action(ORDER_SCORE[FAILURE], { error })
};

export const order = {
  request: () => action(ORDER[REQUEST]),
  success: response => action(ORDER[SUCCESS], { response }),
  failure: error => action(ORDER[FAILURE], { error })
};

export const orders = {
  request: () => action(ORDERS[REQUEST]),
  success: response => action(ORDERS[SUCCESS], { response }),
  loadmore: response => action(ORDERS[LOADMORE], { response }),
  failure: error => action(ORDERS[FAILURE], { error })
};

export const report = {
  request: () => action(REPORT[REQUEST]),
  success: response => action(REPORT[SUCCESS], { response }),
  failure: error => action(REPORT[FAILURE], { error })
};

export const reports = {
  request: () => action(REPORTS[REQUEST]),
  success: response => action(REPORTS[SUCCESS], { response }),
  failure: error => action(REPORTS[FAILURE], { error })
};

export const blockReports = {
  request: () => action(BLOCK_REPORTS[REQUEST]),
  success: response => action(BLOCK_REPORTS[SUCCESS], { response }),
  failure: error => action(BLOCK_REPORTS[FAILURE], { error })
};

export const teamReports = {
  request: () => action(TEAM_REPORTS[REQUEST]),
  success: response => action(TEAM_REPORTS[SUCCESS], { response }),
  failure: error => action(TEAM_REPORTS[FAILURE], { error })
};

export const soloReports = {
  request: () => action(SOLO_REPORTS[REQUEST]),
  success: response => action(SOLO_REPORTS[SUCCESS], { response }),
  failure: error => action(SOLO_REPORTS[FAILURE], { error })
};

export const entryYearReports = {
  request: () => action(ENTRY_YEAR_REPORTS[REQUEST]),
  success: response => action(ENTRY_YEAR_REPORTS[SUCCESS], { response }),
  failure: error => action(ENTRY_YEAR_REPORTS[FAILURE], { error })
};

export const oldNewReports = {
  request: () => action(OLD_NEW_REPORTS[REQUEST]),
  success: response => action(OLD_NEW_REPORTS[SUCCESS], { response }),
  failure: error => action(OLD_NEW_REPORTS[FAILURE], { error })
};

export const adminReportOrders = {
  request: () => action(ADMIN_REPORT_ORDERS[REQUEST]),
  success: (reportId, response) =>
    action(ADMIN_REPORT_ORDERS[SUCCESS], { reportId, response }),
  failure: error => action(ADMIN_REPORT_ORDERS[FAILURE], { error })
};

export const adminReports = {
  request: date => action(ADMIN_REPORTS[REQUEST], { date }),
  success: response => action(ADMIN_REPORTS[SUCCESS], { response }),
  loadmore: response => action(ADMIN_REPORTS[LOADMORE], { response }),
  failure: error => action(ADMIN_REPORTS[FAILURE], { error })
};

// Timeline
export const loadTimelinePage = () => action(LOAD_TIMELINE_PAGE);
export const loadMoreTimeline = () => action(LOAD_MORE_TIMELINE);

// Prompt Reports
export const loadBlockReports = () => action(LOAD_BLOCK_REPORTS);
export const loadTeamReports = () => action(LOAD_TEAM_REPORTS);
export const loadSoloReports = () => action(LOAD_SOLO_REPORTS);
export const loadEntryYearReports = () => action(LOAD_ENTRY_YEAR_REPORTS);
export const loadOldNewReports = () => action(LOAD_OLD_NEW_REPORTS);

// Mypage
export const loadMypage = () => action(LOAD_MYPAGE);
export const loadMoreMyReport = () => action(LOAD_MORE_MY_REPORT);

// Orders
export const loadOrdersPage = (year, month) =>
  action(LOAD_ORDERS_PAGE, { year, month });
export const loadUpdateOrderPage = id => action(LOAD_UPDATE_ORDER_PAGE, { id });

// Reports
export const loadUpdateReportPage = id =>
  action(LOAD_UPDATE_REPORT_PAGE, { id });

// Calenders
export const CALENDER = createRequestTypes("CALENDER");
export const LOAD_CALENDER_PAGE = "LOAD_CALENDER_PAGE";
export const calender = {
  request: () => action(CALENDER[REQUEST]),
  success: response => action(CALENDER[SUCCESS], { response }),
  failure: error => action(CALENDER[FAILURE], { error })
};
export const loadCalenderPage = () => action(LOAD_CALENDER_PAGE);

export const UPDATE_EVENT_STATUS = "UPDATE_EVENT_STATUS";
export const updateEventStatus = (id, status) =>
  action(UPDATE_EVENT_STATUS, { id, status });

// TeamReport
export const TEAM_REPORT = createRequestTypes("TEAM_REPORT");
export const LOAD_CREATE_TEAM_REPORT_PAGE = "LOAD_CREATE_TEAM_REPORT_PAGE";
export const teamReport = {
  request: () => action(TEAM_REPORT[REQUEST]),
  success: response => action(TEAM_REPORT[SUCCESS], { response }),
  failure: error => action(TEAM_REPORT[FAILURE], { error })
};
export const loadCreateTeamReportPage = () =>
  action(LOAD_CREATE_TEAM_REPORT_PAGE);

// CreateReport
export const ADD_ORDER = "ADD_ORDER";
export const addOrder = order => action(ADD_ORDER, { order });
export const UPDATE_ORDER = "UPDATE_ORDER";
export const updateOrder = (index, order) =>
  action(UPDATE_ORDER, { index, order });
export const REMOVE_ORDER = "REMOVE_ORDER";
export const removeOrder = index => action(REMOVE_ORDER, { index });

export const ADD_CANCEL_ORDER = "ADD_CANCEL_ORDER";
export const addCancelOrder = order => action(ADD_CANCEL_ORDER, { order });
export const REMOVE_CANCEL_ORDER = "REMOVE_CANCEL_ORDER";
export const removeCancelOrder = id => action(REMOVE_CANCEL_ORDER, { id });

export const ADD_WITHDRAWAL_ORDER = "ADD_WITHDRAWAL_ORDER";
export const addWithdrawalOrder = order =>
  action(ADD_WITHDRAWAL_ORDER, { order });
export const REMOVE_WITHDRAWAL_ORDER = "REMOVE_WITHDRAWAL_ORDER";
export const removeWithdrawalOrder = id =>
  action(REMOVE_WITHDRAWAL_ORDER, { id });

export const ADD_REORDERED_ORDER = "ADD_REORDERED_ORDER";
export const addReorderedOrder = order =>
  action(ADD_REORDERED_ORDER, { order });
export const REMOVE_REORDERED_ORDER = "REMOVE_REORDERED_ORDER";
export const removeReorderedOrder = id =>
  action(REMOVE_REORDERED_ORDER, { id });

export const ADD_CHANGED_ORDER = "ADD_CHANGED_ORDER";
export const addChangedOrder = order => action(ADD_CHANGED_ORDER, { order });
export const REMOVE_CHANGED_ORDER = "REMOVE_CHANGED_ORDER";
export const removeChangedOrder = id => action(REMOVE_CHANGED_ORDER, { id });

export const RESET_SELECTED_ORDERS = "RESET_SELECTED_ORDERS";
export const resetSelectedOrders = () => action(RESET_SELECTED_ORDERS);

export const resetErrorMessage = () => action(RESET_ERROR_MESSAGE);

// ADMIN
export const loadAdminReportsPage = date =>
  action(LOAD_ADMIN_REPORTS_PAGE, { date });
export const loadAdminMoreReports = date =>
  action(LOAD_ADMIN_MORE_REPORTS, { date });

export const loadAdminReportOrders = reportId =>
  action(LOAD_ADMIN_REPORT_ORDERS, { reportId });
