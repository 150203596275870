import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Flex, Box } from "rebass";
import * as Icon from "react-feather";
import CreateReportForm from "../../components/CreateReportForm";
import {
  removeOrder,
  updateOrder,
  removeCancelOrder,
  removeWithdrawalOrder,
  addReorderedOrder,
  removeReorderedOrder,
  addChangedOrder,
  removeChangedOrder,
  resetSelectedOrders
} from "../../actions";

class CreateReportPage extends Component {
  constructor(props) {
    super(props);

    this.handleIsRecruiting = this.handleIsRecruiting.bind(this);
  }

  state = {
    isRecruiting: false
  };

  handleIsRecruiting() {
    this.setState({ isRecruiting: !this.state.isRecruiting });
  }

  handleSubmit(event) {
    event.preventDefault();
  }

  render() {
    return (
      <div className="container">
        <header>
          <Flex justifyContent="space-between">
            <Box width={2 / 3}>
              <Icon.ArrowLeft
                style={{ paddingBottom: "6px" }}
                onClick={() => this.props.history.goBack()}
              />
              <h1>日報を作成</h1>
            </Box>
          </Flex>
        </header>

        <div style={{ marginBottom: 35 }}>
          <CreateReportForm {...this.props} />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  let cancelWithdrawalCount = {};

  const cancelWithdrawalOrders = [
    ...state.createReport.cancelOrders,
    ...state.createReport.withdrawalOrders
  ];

  cancelWithdrawalOrders.map(order => {
    if (cancelWithdrawalCount[order.month] == undefined) {
      cancelWithdrawalCount[order.month] = {
        cancel: {
          normalCount: 0,
          stoveCount: 0,
          apCount: 0
        },
        withdrawal: {
          normalCount: 0,
          stoveCount: 0,
          apCount: 0
        }
      };
    }
    if (order.depositType == "通常") {
      if (order.action == "cancel") {
        cancelWithdrawalCount[order.month].cancel.normalCount =
          cancelWithdrawalCount[order.month].cancel.normalCount + 1;
      }
      if (order.action == "withdrawal") {
        cancelWithdrawalCount[order.month].withdrawal.normalCount =
          cancelWithdrawalCount[order.month].withdrawal.normalCount + 1;
      }
    }
    if (order.depositType == "コンロ") {
      if (order.action == "cancel") {
        cancelWithdrawalCount[order.month].cancel.stoveCount =
          cancelWithdrawalCount[order.month].cancel.stoveCount + 1;
      }
      if (order.action == "withdrawal") {
        cancelWithdrawalCount[order.month].withdrawal.stoveCount =
          cancelWithdrawalCount[order.month].withdrawal.stoveCount + 1;
      }
    }
    if (order.depositType == "AP") {
      if (order.action == "cancel") {
        cancelWithdrawalCount[order.month].cancel.apCount =
          cancelWithdrawalCount[order.month].cancel.apCount +
          order.depositApartment;
      }
      if (order.action == "withdrawal") {
        cancelWithdrawalCount[order.month].withdrawal.apCount =
          cancelWithdrawalCount[order.month].withdrawal.apCount +
          order.depositApartment;
      }
    }
  });

  return {
    createReport: state.createReport,
    cancelWithdrawalCount
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    {
      removeOrder,
      updateOrder,
      removeCancelOrder,
      removeWithdrawalOrder,
      addReorderedOrder,
      removeReorderedOrder,
      addChangedOrder,
      removeChangedOrder,
      resetSelectedOrders
    }
  )(CreateReportPage)
);
