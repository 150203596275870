export const getOrderScore = (orderCount, cancelWithdrawalCount) => {
  let grossCount = 0;
  let grossStoveCount = 0;
  let grossApCount = 0;

  Object.keys(orderCount).map(month => {
    grossCount += orderCount[month].normalCount + orderCount[month].stoveCount;
    grossStoveCount += orderCount[month].stoveCount;
    grossApCount += orderCount[month].apCount;
  });

  let cancelCount = 0;
  let cancelStoveCount = 0;
  let withdrawalCount = 0;
  let withdrawalStoveCount = 0;
  let cancelApCount = 0;
  let withdrawalApCount = 0;

  Object.keys(cancelWithdrawalCount).map(month => {
    cancelCount +=
      cancelWithdrawalCount[month].cancel.normalCount +
      cancelWithdrawalCount[month].cancel.stoveCount;
    cancelStoveCount += cancelWithdrawalCount[month].cancel.stoveCount;
    withdrawalCount +=
      cancelWithdrawalCount[month].withdrawal.normalCount +
      cancelWithdrawalCount[month].withdrawal.stoveCount;
    withdrawalStoveCount += cancelWithdrawalCount[month].withdrawal.stoveCount;

    cancelApCount += cancelWithdrawalCount[month].cancel.apCount;
    withdrawalApCount += cancelWithdrawalCount[month].withdrawal.apCount;
  });

  return {
    grossCount,
    grossStoveCount,
    grossApCount,
    cancelCount,
    cancelStoveCount,
    withdrawalCount,
    withdrawalStoveCount,
    cancelApCount,
    withdrawalApCount
  };
};
