import { ADMIN_REPORTS, ADMIN_REPORT_ORDERS } from "../actions";
import Immutable from "seamless-immutable";
import * as moment from "moment";

const initialState = Immutable({
  isFetching: false,
  pageInfo: {},
  edges: [],
  page: 1,
  date: moment().format("YYYY-MM-DD")
});

const adminReports = (state = initialState, action) => {
  switch (action.type) {
    case ADMIN_REPORTS["REQUEST"]:
      return state.merge({
        isFetching: true,
        pageInfo: {},
        edges: [],
        page: 1,
        date: action.date
      });
    case ADMIN_REPORTS["SUCCESS"]:
      return state.merge({
        isFetching: false,
        pageInfo: action.response.usersConnection.pageInfo,
        edges: action.response.usersConnection.edges,
        page: 1
      });
    case ADMIN_REPORTS["LOADMORE"]:
      return state.merge({
        isFetching: false,
        pageInfo: action.response.usersConnection.pageInfo,
        edges: [...state.edges, ...action.response.usersConnection.edges],
        page: state.page + 1
      });
    case ADMIN_REPORTS["FAILURE"]:
      return state.merge({
        isFetching: false,
        pageInfo: {},
        edges: [],
        page: 1
      });
    default:
      return state;
  }
};

export default adminReports;
