import React, { Component } from "react";
import BottomTabs from "./BottomTabs";

export default class Layout extends Component {
  render() {
    return (
      <div className="container">
        {this.props.children}
        <BottomTabs />
      </div>
    );
  }
}
