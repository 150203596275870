import { REPORT } from "../actions";
import Immutable from "seamless-immutable";

const initialState = Immutable({
  isFetching: false,
  entities: {
    reports: {}
  },
  result: ""
});

const report = (state = initialState, action) => {
  switch (action.type) {
    case REPORT["REQUEST"]:
      return state.merge({
        isFetching: true
      });
    case REPORT["SUCCESS"]:
      return state.merge({ isFetching: false, ...action.response });
    default:
      return state;
  }
};

export default report;
