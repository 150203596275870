import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Flex, Box } from "rebass";
import * as Icon from "react-feather";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { Colors } from "../themes";
import { loadCalenderPage, updateEventStatus } from "../actions";
const queryString = require("query-string");

class CalenderPage extends Component {
  componentWillMount() {
    this.props.loadCalenderPage();
  }

  render() {
    const { result, entities } = this.props.calender;
    const parsed = queryString.parse(this.props.location.search);

    if (result.length == 0) {
      return (
        <div className="container">
          <header>
            <Flex justifyContent="space-between">
              <Box width={2 / 3}>
                <Icon.ArrowLeft
                  style={{ paddingBottom: "6px" }}
                  onClick={() => this.props.history.goBack()}
                />
                <h1>カレンダー</h1>
              </Box>
            </Flex>
          </header>

          <div style={{ marginBottom: 35 }}>取得中</div>
        </div>
      );
    }

    return (
      <div className="container">
        <header>
          <Flex justifyContent="space-between">
            <Box width={2 / 3}>
              <Icon.ArrowLeft
                style={{ paddingBottom: "6px" }}
                onClick={() => this.props.history.goBack()}
              />
              <h1>カレンダー</h1>
            </Box>
          </Flex>
        </header>

        <div style={{ marginBottom: 35 }} />
        <Tabs>
          <TabList style={{ textAlign: "center" }}>
            <Tab style={{ fontSize: 13 }}>月度</Tab>
            <Tab style={{ fontSize: 13 }}>イベント</Tab>
          </TabList>

          <TabPanel style={{ marginTop: 15 }}>
            <div>
              {/* <Flex justifyContent="space-between">
                <Box width={1 / 4}>
                  <ListTitle>2019</ListTitle>
                </Box>
              </Flex> */}

              {result.map((id, i) => {
                const item = entities.event[id];
                console.log(item);
                return (
                  <Container key={i}>
                    <Item>
                      <Flex
                        justifyContent="space-between"
                        style={{ width: "100%" }}
                      >
                        <Box width={2 / 10}>
                          <MetaData>
                            <a>{item.year}/{item.month}月度</a>
                          </MetaData>
                        </Box>

                        <Box width={4 / 10}>
                          <Link to={`setting/${item.id}`}>
                            <span style={{ marginRight: 5 }}>月度設定</span>
                            <Icon.Settings
                              size={12}
                              style={{
                                color: "gray",
                                cursor: "pointer"
                              }}
                            />
                          </Link>
                        </Box>

                        <Box width={4 / 10} style={{ textAlign: "right" }}>
                          {parsed.q === "disabled" ? (
                            <Link
                              to={`orders/${item.year}/${
                                item.month
                              }?q=disabled`}
                            >
                              <Icon.ChevronRight />
                            </Link>
                          ) : (
                            <Link to={`orders/${item.year}/${item.month}`}>
                              <Icon.ChevronRight />
                            </Link>
                          )}
                        </Box>
                      </Flex>
                    </Item>
                  </Container>
                );
              })}
            </div>
          </TabPanel>

          <TabPanel>
            <span style={{ padding: 16, display: "inline-block" }}>
              まだ情報がありません
            </span>
          </TabPanel>
        </Tabs>
      </div>
    );
  }
}

const ListTitle = styled.p`
  padding: 8px 16px;
  font-size: 13px;
  border-radius: 0 5px 0 0;
  background-color: #82ceff;
  font-weight: 540;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  color: ${Colors.white};
`;

const Container = styled.ul`
  width: 100%;
`;

const Item = styled.li`
  display: block;
  font-size: 15px;
  text-align: left;
  color: #333;
  display: flex;
  padding: 8px 8px;
  margin-top: 0.5em;

  border-bottom: 1px solid #c7c7cc;
`;

const MetaData = styled.div`
  font-size: 14px;
  font-weight: 400;
`;

function mapStateToProps(state) {
  return {
    calender: state.calender
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    {
      loadCalenderPage,
      updateEventStatus
    }
  )(CalenderPage)
);
