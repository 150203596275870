import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import _ from "lodash";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Flex, Box } from "rebass";
import * as Icon from "react-feather";
import { BorderBottom, Colors, Rounded } from "../themes";
import InfiniteScroll from "react-infinite-scroller";

export default class PaceChart extends Component {
  constructor() {
    super();
    this.state = {
      currentTab: "mine",
      isHidden: true
    };

    this.switchTab = this.switchTab.bind(this);
  }

  switchTab(tab) {
    this.setState({ currentTab: tab });
  }

  toggleHidden() {
    this.setState({
      isHidden: !this.state.isHidden
    });
  }

  render() {
    const { currentTab } = this.state;

    if (currentTab === "team") {
      return (
        <div className="container">
          <header>
            <Icon.ArrowLeft
              style={{ paddingBottom: "6px" }}
              onClick={() => this.props.history.goBack()}
            />
            <h1 style={{ marginLeft: 8 }}>ペース表</h1>
          </header>
          <TabWrapper>
            <Tabs>
              <Tab isCurrent={currentTab === "mine" ? true : false}>
                <TabLink onClick={() => this.switchTab("mine")}>個人</TabLink>
              </Tab>
              <Tab isCurrent={currentTab === "team" ? true : false}>
                <TabLink onClick={() => this.switchTab("team")}>係</TabLink>
              </Tab>
              <Tab isCurrent={currentTab === "block" ? true : false}>
                <TabLink onClick={() => this.switchTab("block")}>
                  ブロック
                </TabLink>
              </Tab>
            </Tabs>
          </TabWrapper>
          <div>まだ何もありません。</div>
        </div>
      );
    }

    if (currentTab === "block") {
      return (
        <div className="container">
          <header>
            <Icon.ArrowLeft
              style={{ paddingBottom: "6px" }}
              onClick={() => this.props.history.goBack()}
            />
            <h1 style={{ marginLeft: 8 }}>ペース表</h1>
          </header>

          <TabWrapper>
            <Tabs>
              <Tab isCurrent={currentTab === "mine" ? true : false}>
                <TabLink onClick={() => this.switchTab("mine")}>個人</TabLink>
              </Tab>
              <Tab isCurrent={currentTab === "team" ? true : false}>
                <TabLink onClick={() => this.switchTab("team")}>係</TabLink>
              </Tab>
              <Tab isCurrent={currentTab === "block" ? true : false}>
                <TabLink onClick={() => this.switchTab("block")}>
                  ブロック
                </TabLink>
              </Tab>
            </Tabs>
          </TabWrapper>
          <div>まだ何もありません。</div>
        </div>
      );
    }

    return (
      <div className="container">
        <header>
          <Icon.ArrowLeft
            style={{ paddingBottom: "6px" }}
            onClick={() => this.props.history.goBack()}
          />
          <h1 style={{ marginLeft: 8 }}>ペース表</h1>
        </header>

        <TabWrapper>
          <Tabs>
            <Tab isCurrent={currentTab === "mine" ? true : false}>
              <TabLink onClick={() => this.switchTab("mine")}>個人</TabLink>
            </Tab>
            <Tab isCurrent={currentTab === "team" ? true : false}>
              <TabLink onClick={() => this.switchTab("team")}>係</TabLink>
            </Tab>
            <Tab isCurrent={currentTab === "block" ? true : false}>
              <TabLink onClick={() => this.switchTab("block")}>
                ブロック
              </TabLink>
            </Tab>
          </Tabs>
        </TabWrapper>

        <div
          style={{
            margin: "20px",
            padding: "16px",
            border: "1px solid",
            borderColor: "rgb(101, 119, 134)"
          }}
        >
          <Flex
            style={{
              flexDirection: "column",
              padding: "8px"
            }}
          >
            <div style={{ fontWeight: "bold" }}>3月度</div>
          </Flex>
          <Flex
            style={{
              flexDirection: "column",
              padding: "8px"
            }}
          >
            <Category>目標</Category>
            {/* <Score>{score.myGoal}本</Score> */}
            <Score>20本</Score>
          </Flex>
          <Flex
            style={{
              flexDirection: "column",
              padding: "8px"
            }}
          >
            <Category>指針</Category>

            <Score>1day3FT、CT率20%以下</Score>
          </Flex>
          <Flex
            style={{
              flexDirection: "column",
              padding: "8px"
            }}
          >
            <Category>具体策</Category>

            {/* <Score>{score.strategy}</Score> */}
            <Score>
              型にハマり過ぎずに、自然な流れで検針票依頼する。（どのタイミングでもいい）
            </Score>
          </Flex>
        </div>
        <SubHeading>マイペース</SubHeading>
        <ul>
          <InfiniteScroll>
            <li
              style={{
                borderBottom: "1px solid",
                borderColor: "rgb(230, 236, 240)"
              }}
            >
              <Flex
                justifyContent="space-between"
                style={{ fontSize: "14px", padding: "16px 16px 0 16px" }}
              >
                <div style={{ fontWeight: "bold" }}>2/26(火)</div>
              </Flex>
              <Flex
                justifyContent="space-between"
                style={{ fontSize: "12px", padding: "16px" }}
              >
                <Box width={2 / 12} style={{ justyfyContent: "column" }}>
                  <Pace style={{ color: "#D0021B" }}>-1</Pace>
                </Box>
                <Box width={3 / 12} style={{ justyfyContent: "column" }}>
                  <Category>本日の結果</Category> <Score>3(1)</Score>
                </Box>
                <Box width={4 / 12} style={{ justyfyContent: "column" }}>
                  <Category>G/N</Category> <Score>20(3)/10(1)</Score>
                </Box>
                <Box width={4 / 12} style={{ justyfyContent: "column" }}>
                  <Category>C/T</Category> <Score>5(1)/5(1)</Score>
                </Box>
                <Box width={1 / 14} style={{ justyfyContent: "column" }}>
                  <button onClick={this.toggleHidden.bind(this)}>
                    <Icon.MoreHorizontal />
                  </button>
                </Box>
              </Flex>

              {!this.state.isHidden && (
                <div style={{ padding: 16 }}>
                  <Flex style={{ padding: "4px 0px 4px 0px" }}>
                    <Box width={1 / 2}>
                      <Category>CT率</Category>
                    </Box>
                    <Box width={1 / 2} style={{ textAlign: "right" }}>
                      <Score>20%</Score>
                    </Box>
                  </Flex>
                  <Flex style={{ padding: "4px 0px 4px 0px" }}>
                    <Box width={1 / 2}>
                      <Category>面談数/累計</Category>
                    </Box>
                    <Box width={1 / 2} style={{ textAlign: "right" }}>
                      <Score>10/30</Score>
                    </Box>
                  </Flex>
                  <Flex style={{ padding: "4px 0px 4px 0px" }}>
                    <Box width={1 / 2}>
                      <Category>FT/累計</Category>
                    </Box>
                    <Box width={1 / 2} style={{ textAlign: "right" }}>
                      <Score>1/10</Score>
                    </Box>
                  </Flex>
                  <Flex style={{ padding: "4px 0px 4px 0px" }}>
                    <Box width={1 / 2}>
                      <Category>突破率</Category>
                    </Box>
                    <Box width={1 / 2} style={{ textAlign: "right" }}>
                      <Score>10</Score>
                    </Box>
                  </Flex>
                  <Flex style={{ padding: "4px 0px 4px 0px" }}>
                    <Box width={1 / 2}>
                      <Category>決定率</Category>
                    </Box>
                    <Box width={1 / 2} style={{ textAlign: "right" }}>
                      <Score>10</Score>
                    </Box>
                  </Flex>
                  <Flex style={{ padding: "4px 0px 4px 0px" }}>
                    <Box width={1 / 2}>
                      <Category>即決数</Category>
                    </Box>
                    <Box width={1 / 2} style={{ textAlign: "right" }}>
                      <Score>10%</Score>
                    </Box>
                  </Flex>
                  <Flex style={{ padding: "4px 0px 4px 0px" }}>
                    <Box width={1 / 2}>
                      <Category>即決決定率</Category>
                    </Box>
                    <Box width={1 / 2} style={{ textAlign: "right" }}>
                      <Score>10%</Score>
                    </Box>
                  </Flex>
                  <Flex style={{ padding: "4px 0px 4px 0px" }}>
                    <Box width={1 / 2}>
                      <Category>再訪数</Category>
                    </Box>
                    <Box width={1 / 2} style={{ textAlign: "right" }}>
                      <Score>10</Score>
                    </Box>
                  </Flex>
                  <Flex style={{ padding: "4px 0px 4px 0px" }}>
                    <Box width={1 / 2}>
                      <Category>再訪決定数</Category>
                    </Box>
                    <Box width={1 / 2} style={{ textAlign: "right" }}>
                      <Score>10</Score>
                    </Box>
                  </Flex>
                  <Flex style={{ padding: "4px 0px 4px 0px" }}>
                    <Box width={1 / 2}>
                      <Category>再訪決定率</Category>
                    </Box>
                    <Box width={1 / 2} style={{ textAlign: "right" }}>
                      <Score>10 </Score>
                    </Box>
                  </Flex>
                  <Flex style={{ padding: "4px 0px 4px 0px" }}>
                    <Box width={1 / 2}>
                      <Category>予測N</Category>
                    </Box>
                    <Box width={1 / 2} style={{ textAlign: "right" }}>
                      <Score>10%</Score>
                    </Box>
                  </Flex>
                </div>
              )}
            </li>
            <li
              style={{
                borderBottom: "1px solid",
                borderColor: "rgb(230, 236, 240)"
              }}
            >
              <Flex
                justifyContent="space-between"
                style={{ fontSize: "14px", padding: "16px 16px 0 16px" }}
              >
                <div style={{ fontWeight: "bold" }}>2/26(火)</div>
              </Flex>
              <Flex
                justifyContent="space-between"
                style={{ fontSize: "12px", padding: "16px" }}
              >
                <Box width={2 / 12} style={{ justyfyContent: "column" }}>
                  <Pace>+1</Pace>
                </Box>
                <Box width={3 / 12} style={{ justyfyContent: "column" }}>
                  <Category>本日の結果</Category> <Score>3(1)</Score>
                </Box>
                <Box width={4 / 12} style={{ justyfyContent: "column" }}>
                  <Category>G/N</Category> <Score>20(3)/10(1)</Score>
                </Box>
                <Box width={4 / 12} style={{ justyfyContent: "column" }}>
                  <Category>C/T</Category> <Score>5(1)/5(1)</Score>
                </Box>
                <Box width={1 / 14} style={{ justyfyContent: "column" }}>
                  <button onClick={this.toggleHidden.bind(this)}>
                    <Icon.MoreHorizontal />
                  </button>
                </Box>
              </Flex>

              {!this.state.isHidden && (
                <div style={{ padding: 16 }}>
                  <Flex style={{ padding: "4px 0px 4px 0px" }}>
                    <Box width={1 / 2}>
                      <Category>CT率</Category>
                    </Box>
                    <Box width={1 / 2} style={{ textAlign: "right" }}>
                      <Score>20%</Score>
                    </Box>
                  </Flex>
                  <Flex style={{ padding: "4px 0px 4px 0px" }}>
                    <Box width={1 / 2}>
                      <Category>面談数/累計</Category>
                    </Box>
                    <Box width={1 / 2} style={{ textAlign: "right" }}>
                      <Score>10/30</Score>
                    </Box>
                  </Flex>
                  <Flex style={{ padding: "4px 0px 4px 0px" }}>
                    <Box width={1 / 2}>
                      <Category>FT/累計</Category>
                    </Box>
                    <Box width={1 / 2} style={{ textAlign: "right" }}>
                      <Score>1/10</Score>
                    </Box>
                  </Flex>
                  <Flex style={{ padding: "4px 0px 4px 0px" }}>
                    <Box width={1 / 2}>
                      <Category>突破率</Category>
                    </Box>
                    <Box width={1 / 2} style={{ textAlign: "right" }}>
                      <Score>10</Score>
                    </Box>
                  </Flex>
                  <Flex style={{ padding: "4px 0px 4px 0px" }}>
                    <Box width={1 / 2}>
                      <Category>決定率</Category>
                    </Box>
                    <Box width={1 / 2} style={{ textAlign: "right" }}>
                      <Score>10</Score>
                    </Box>
                  </Flex>
                  <Flex style={{ padding: "4px 0px 4px 0px" }}>
                    <Box width={1 / 2}>
                      <Category>即決数</Category>
                    </Box>
                    <Box width={1 / 2} style={{ textAlign: "right" }}>
                      <Score>10%</Score>
                    </Box>
                  </Flex>
                  <Flex style={{ padding: "4px 0px 4px 0px" }}>
                    <Box width={1 / 2}>
                      <Category>即決決定率</Category>
                    </Box>
                    <Box width={1 / 2} style={{ textAlign: "right" }}>
                      <Score>10%</Score>
                    </Box>
                  </Flex>
                  <Flex style={{ padding: "4px 0px 4px 0px" }}>
                    <Box width={1 / 2}>
                      <Category>再訪数</Category>
                    </Box>
                    <Box width={1 / 2} style={{ textAlign: "right" }}>
                      <Score>10</Score>
                    </Box>
                  </Flex>
                  <Flex style={{ padding: "4px 0px 4px 0px" }}>
                    <Box width={1 / 2}>
                      <Category>再訪決定数</Category>
                    </Box>
                    <Box width={1 / 2} style={{ textAlign: "right" }}>
                      <Score>10</Score>
                    </Box>
                  </Flex>
                  <Flex style={{ padding: "4px 0px 4px 0px" }}>
                    <Box width={1 / 2}>
                      <Category>再訪決定率</Category>
                    </Box>
                    <Box width={1 / 2} style={{ textAlign: "right" }}>
                      <Score>10 </Score>
                    </Box>
                  </Flex>
                  <Flex style={{ padding: "4px 0px 4px 0px" }}>
                    <Box width={1 / 2}>
                      <Category>予測N</Category>
                    </Box>
                    <Box width={1 / 2} style={{ textAlign: "right" }}>
                      <Score>10%</Score>
                    </Box>
                  </Flex>
                </div>
              )}
            </li>
          </InfiniteScroll>
        </ul>
      </div>
    );
  }
}

const RoundedButton = styled(Link)`
  width: 60px;
  height: 60px;
  min-height: 30px;
  color: rgb(29, 161, 242);
  border: 1px solid;
  border-color: rgb(29, 161, 242);
  display: inline-block;
  padding: 8px;

  ${Rounded}

  background-color: rgb(255, 255, 255);
  font-size: 15px;

  position: absolute;
  bottom: 80px;
  right: 15px;
  z-index: 10;

  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.22) 0px 3px 6px;
`;

const TabWrapper = styled.nav`
  width: 100%;
  background-color: #fff;
`;

const Tabs = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 25px;
  margin: 0 auto;
`;

const Tab = styled.li`
  font-size: 0.88rem;
  flex: 1;
  text-align: center;
  margin-top: 0;
  border-right: 1px solid #efefef;
  border-bottom: ${props =>
    props.isCurrent ? `3px solid ${Colors.blue}` : "1px solid #efefef"};

  :first-child {
    border-left: 1px solid #efefef;
  }
`;

const TabLink = styled.a`
  display: block;
  background-color: ${props => (props.isCurrent ? "#000" : "#fff")};
  color: ${props => (props.isCurrent ? "#fff" : "#000")};
  padding: 10px 0;
`;

const Table = styled.table`
  width: 100%;
  table-layout: fixed;
  border: none !important;
  border-collapse: separate;
  border-spacing: 2px 0px;
`;

const Score = styled.div`
  font-weight: bold;
  font-size: 14px;
`;

const Category = styled.span`
  color: rgb(101, 119, 134);
`;

const Pace = styled.div`
  font-weight: bold;
  font-size: 16px;
  color: rgb(126, 211, 33);
`;

const SubHeading = styled.div`
  font-size: 17px;
  font-weight: bold;
  padding-left: 16px;
  padding-top: 12px;
  padding-bottom: 12px;
  margin-top: 22px;
  background-color: ${Colors.lightBlue};
`;
