import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Flex, Box } from "rebass";
import * as Icon from "react-feather";
import { RoundedButton } from "../../themes";
import UpdateReportForm from "../../components/UpdateReportForm";
import { loadUpdateReportPage } from "../../actions";

class UpdateReport extends Component {
  componentWillMount() {
    const { id } = this.props.match.params;
    this.props.loadUpdateReportPage(id);
  }

  render() {
    const { match, history, report } = this.props;
    if (report.isFetching) {
      return null;
    }
    const { id } = match.params;

    return (
      <div className="container">
        <header>
          <Flex justifyContent="space-between">
            <Box width={2 / 3}>
              <Icon.ArrowLeft
              style={{ paddingBottom: "6px" }}
               onClick={() => history.goBack()} />
              <h1>日報を編集</h1>
            </Box>
          </Flex>
        </header>

        <div style={{ marginBottom: 35 }}>
          <UpdateReportForm
            report={report.entities.reports[id]}
            history={history}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    report: state.report
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    {
      loadUpdateReportPage
    }
  )(UpdateReport)
);
