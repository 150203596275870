import React, { Component } from "react";
import styled from "styled-components";
import { Link, withRouter } from "react-router-dom";
import { Flex, Box } from "rebass";

import * as Icon from "react-feather";
import { Colors } from "../themes";

class BottomTabs extends Component {
  render() {
    const { pathname } = this.props.location;
    console.log(pathname);
    return (
      <Tabs>
        <Flex as={"ul"}>
          <TabItem width={1 / 3}>
            <TabItemLink
              to="/timeline"
              isActive={pathname === "/timeline" ? true : false}
            >
              <IconContainer>
                <Icon.Home size={30} />
              </IconContainer>
              <LabelContainer>ホーム</LabelContainer>
            </TabItemLink>
          </TabItem>
          <TabItem width={1 / 3}>
            <TabItemLink
              to="/reports"
              isActive={pathname === "/reports" ? true : false}
            >
              <IconContainer>
                <Icon.Zap size={30} />
              </IconContainer>
              <LabelContainer>速報</LabelContainer>
            </TabItemLink>
          </TabItem>
          <TabItem width={1 / 3}>
            <TabItemLink
              to="/mypage"
              isActive={pathname === "/mypage" ? true : false}
            >
              <IconContainer>
                <Icon.User size={30} />
              </IconContainer>
              <LabelContainer>マイページ</LabelContainer>
            </TabItemLink>
          </TabItem>
        </Flex>
      </Tabs>
    );
  }
}

const IconContainer = styled.div`
  display: block;
  text-align: center;
`;

const LabelContainer = styled.div`
  display: block;
  text-align: center;
  font-size: 9px;
`;

const Tabs = styled.nav`
  position: fixed;
  bottom: 0;
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
  -webkit-box-shadow: 0 -1px 4px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 -1px 4px 0 rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 -1px 4px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 -1px 4px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
`;

const TabItem = styled(Box)`
  padding: 8px;
`;

const TabItemLink = styled(Link)`
  text-align: center;
  text-decoration: none;

  color: ${props => (props.isActive ? Colors.blue : "gray")};

  &:hover {
    color: ${Colors.blue};
  }
`;

export default withRouter(props => <BottomTabs {...props} />);
