import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { loadMypage, loadMoreMyReport } from "./actions";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Flex, Box, Button, Heading } from "rebass";
import * as Icon from "react-feather";
import MyReport from "./components/MyReport";
import Layout from "./components/Layout";
import { Colors, Rounded, List, ListItem } from "./themes";
import ReactModal from "react-modal";
import moment from "moment";
import InfiniteScroll from "react-infinite-scroller";
import MonthlyScore from "./components/MonthlyScore";

class App extends Component {
  constructor() {
    super();
    this.state = {
      showModal: false
    };

    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.loadFunc = this.loadFunc.bind(this);
    this.logout = this.logout.bind(this);
  }

  componentWillMount() {
    this.props.loadMypage();
  }

  handleOpenModal() {
    this.setState({ showModal: true });
  }

  handleCloseModal() {
    this.setState({ showModal: false });
  }

  loadFunc() {
    this.props.loadMoreMyReport();
  }

  logout() {
    localStorage.removeItem("token");
    this.props.history.push("/login");
  }

  renderGroup(user) {
    let groupText = "";

    if (user.team && user.team.name) {
      groupText += user.team.name;
    }
    if (user.block && user.block.name) {
      groupText += ` ${user.block.name}`;
    }

    if (groupText == "") {
      return null;
    }

    return <TeamName>@{groupText}</TeamName>;
  }

  render() {
    const { user, score } = this.props.user;
    const { pageInfo, edges } = this.props.user.reportsConnection;

    if (user == undefined || user == {}) {
      return null;
    }

    return (
      <Layout>
        <header>
          <Flex justifyContent="space-between">
            <Box width={3 / 5}>
              <Heading>マイページ</Heading>
            </Box>
            <Box width={1 / 5} style={{ textAlign: "right" }}>
              <Link to="/calender?q=disabled">
                <div>
                  <Icon.User style={{ color: "gray" }} />
                </div>
                <span>台帳</span>
              </Link>
            </Box>
            <Box width={1 / 5} style={{ textAlign: "right" }}>
              <Icon.Settings
                onClick={this.handleOpenModal}
                style={{ color: "gray", marginTop: "4px", cursor: "pointer" }}
              />
            </Box>
          </Flex>
        </header>

        <ProfileContainer>
          <Name>{user.name}</Name>
          {this.renderGroup(user)}
        </ProfileContainer>

        <MonthlyScore score={score.currentMonthlyReport} mode={"details"} />
        <MonthlyScore score={score.previousMonthlyReport} mode={"summary"} />

        <SubHeading>マイ日報</SubHeading>
        <ul style={{ marginBottom: 120 }}>
          <InfiniteScroll
            pageStart={0}
            loadMore={this.loadFunc}
            hasMore={pageInfo.hasNextPage}
            loader={
              <div className="loader" key={0}>
                Loading ...
              </div>
            }
          >
            {edges.map((item, i) => {
              moment.locale("ja", {
                weekdaysShort: ["日", "月", "火", "水", "木", "金", "土"]
              });
              let created = moment(item.node.created).format(
                "YYYY年M月D日(ddd)"
              );
              let dateSection = null;

              if (this.currentDate != created) {
                dateSection = (
                  <div
                    style={{
                      background: "rgb(238, 238, 238)",
                      padding: "16px",
                      fontWeight: "bold"
                    }}
                  >
                    {created}
                  </div>
                );
                this.currentDate = created;
              }

              return (
                <li key={i}>
                  {dateSection}
                  <MyReport key={item.node.id} report={item.node} />
                </li>
              );
            })}
          </InfiniteScroll>
        </ul>

        <aside
          style={{
            position: "fixed",
            width: "100%",
            right: 0,
            left: 0,
            bottom: 0,
            maxWidth: "600px",
            margin: "0 auto"
          }}
        >
          <RoundedButton to="/submit">
            <div style={{ textAlign: "center" }}>
              <Icon.Plus size={16} />
              <div>日報</div>
            </div>
          </RoundedButton>
        </aside>

        <ReactModal
          style={customStyles}
          isOpen={this.state.showModal}
          contentLabel="設定"
          onRequestClose={this.handleCloseModal}
        >
          <List>
            <ListItem>
              <Link to="/team-report">係メール作成</Link>
            </ListItem>
            <ListItem>
              <a
                href="https://docs.google.com/document/d/1JUuJaA84cOS3fabbJzScVjuet_d7GYai6VSAnyllPT8/edit?usp=sharing"
                target="_blank"
              >
                操作方法
              </a>
            </ListItem>
            <ListItem>
              <a onClick={this.logout}>ログアウト</a>
            </ListItem>
            <ListItem>
              <a onClick={this.handleCloseModal}>キャンセル</a>
            </ListItem>
          </List>
        </ReactModal>
      </Layout>
    );
  }
}

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: 0,
    borderRadius: 0
  }
};

const ProfileContainer = styled.div`
  text-align: center;
`;

const Name = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin-top: 32px;
`;

const SubHeading = styled.div`
  font-size: 17px;
  font-weight: bold;
  padding-left: 16px;
  padding-top: 12px;
  padding-bottom: 12px;
  margin-top: 22px;
  background-color: ${Colors.lightBlue};
`;

const TeamName = styled.span`
  color: rgb(101, 119, 134);
  margin-left: 5px;
`;

const RoundedButton = styled(Link)`
  width: 60px;
  height: 60px;
  min-height: 30px;
  background-color: rgb(29, 161, 242);
  display: inline-block;
  padding: 8px;

  ${Rounded}

  color: rgb(255, 255, 255);
  font-size: 15px;

  position: absolute;
  bottom: 80px;
  right: 15px;
  z-index: 10;

  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.22) 0px 3px 6px;
`;

function mapStateToProps(state) {
  return {
    user: state.user
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    {
      loadMypage,
      loadMoreMyReport
    }
  )(App)
);
