import React, { Component } from "react";
import styled from "styled-components";
import { Flex, Box } from "rebass";
import { Link } from "react-router-dom";
import * as Icon from "react-feather";
import { getOrderScore } from "../utils";

export default class MyReport extends Component {
  render() {
    const { id, meetingCount, talkCount, orderDetails } = this.props.report;

    const { orderCount, cancelWithdrawalCount } = orderDetails;

    const {
      grossCount,
      grossStoveCount,
      grossApCount,
      cancelCount,
      cancelStoveCount,
      withdrawalCount,
      withdrawalStoveCount,
      cancelApCount,
      withdrawalApCount
    } = getOrderScore(orderCount, cancelWithdrawalCount);

    const cancelWithdrawalMonths = Object.keys(cancelWithdrawalCount);

    return (
      <Container>
        <Flex justifyContent="space-between">
          <Box width={4 / 9}>
            <ScoreContainer justifyContent="flex-start">
              <Box>
                <div>結果</div>
                <Score>{meetingCount}</Score>/<Score>{talkCount}</Score>/
                <Score>{grossCount}</Score>
                <Score>
                  ({grossStoveCount}){grossApCount > 0 && ` AP${grossApCount} `}
                </Score>
              </Box>
            </ScoreContainer>
          </Box>
          <Box width={4 / 9}>
            <ScoreContainer justifyContent="flex-start">
              <Box>
                <div>C/T</div>
                <Score>{cancelCount}</Score>
                <Score>
                  ({cancelStoveCount})
                  {cancelApCount > 0 && ` AP${cancelApCount} `}
                </Score>
                /<Score>{withdrawalCount}</Score>
                <Score>
                  ({withdrawalStoveCount})
                  {withdrawalApCount > 0 && ` AP${withdrawalApCount} `}
                </Score>
                <Score>{cancelWithdrawalMonths.join()}</Score>
              </Box>
            </ScoreContainer>
          </Box>
          <Box width={1 / 9}>
            <Link
              to={`report/${id}`}
              style={{
                float: "right",
                marginTop: "8px"
              }}
            >
              <Icon.Edit size={18} />
            </Link>
          </Box>
        </Flex>
      </Container>
    );
  }
}

const Container = styled.li`
  list-style: none;
  border-bottom: 1px solid;
  border-bottom-color: rgb(230, 236, 240);
  padding: 16px;
`;

const ScoreContainer = styled(Flex)``;

const Score = styled.span`
  font-size: 14px;
  font-weight: bold;
`;
