import { ADMIN_REPORT_ORDERS } from "../actions";
import Immutable from "seamless-immutable";

const initialState = Immutable({
  isFetching: false,
  entities: {
    createdOrders: [],
    cancelOrders: [],
    withdrawalOrders: [],
    reOrders: [],
    changedDateOrders: []
  }
});

const adminReportOrders = (state = initialState, action) => {
  switch (action.type) {
    case ADMIN_REPORT_ORDERS["REQUEST"]:
      return state.merge({
        isFetching: true
      });
    case ADMIN_REPORT_ORDERS["SUCCESS"]:
      return state.merge({
        isFetching: false,
        entities: { ...action.response }
      });
    default:
      return state;
  }
};

export default adminReportOrders;
