import { combineReducers } from "redux";
import { merge } from "lodash/object";
import blockReports from "./blockReports";
import entryYearReports from "./entryYearReports";
import teamReports from "./teamReports";
import soloReports from "./soloReports";
import user from "./user";
import feeds from "./feeds";
import report from "./report";
import order from "./order";
import orders from "./orders";
import orderScore from "./orderScore";
import calender from "./calender";
import teamReport from "./teamReport";
import createReport from "./createReport";
import adminReports from "./adminReports";
import adminReportOrders from "./adminReportOrders";

function entities(
  state = {
    reports: {},
    oldNewReports: {}
  },
  action
) {
  if (action.response && action.response.entities) {
    return merge({}, state, action.response.entities);
  }

  return state;
}

export default combineReducers({
  entities,
  user,
  blockReports,
  entryYearReports,
  teamReports,
  soloReports,
  feeds,
  report,
  order,
  orders,
  orderScore,
  calender,
  teamReport,
  createReport,
  adminReports,
  adminReportOrders
});
