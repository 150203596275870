import React, { Component } from "react";
import { Flex, Box } from "rebass";
import styled from "styled-components";
import { Colors } from "../themes";

export default class MonthlyScore extends Component {
  renderGrossApCount(grossApCount) {
    if (!grossApCount) {
      return null;
    }
    return <span> AP{grossApCount} </span>;
  }

  renderNetApCount(grossApCount, cancelApCount, withdrawalApCount) {
    if (!grossApCount) {
      return null;
    }
    return <span> AP{grossApCount - cancelApCount - withdrawalApCount} </span>;
  }

  render() {
    const { score, mode } = this.props;

    return (
      <div>
        <SubHeading>
          {score.month}月度 {score.workingCount}日目/全稼働日数
        </SubHeading>

        <div style={{ padding: 16 }}>
          <Flex style={{ padding: "4px 0px 4px 0px" }}>
            <Box width={1 / 2}>
              <Category>目標</Category>
            </Box>
            <Box width={1 / 2} style={{ textAlign: "right" }}>
              <Score>{score.myGoal}本</Score>
            </Box>
          </Flex>

          {mode == "details" && (
            <Flex style={{ padding: "4px 0px 4px 0px" }}>
              <Box width={1 / 2}>
                <Category>予測本数</Category>
              </Box>
              <Box width={1 / 2} style={{ textAlign: "right" }}>
                <Score>{score.estimatedCount || "-"}</Score>
              </Box>
            </Flex>
          )}

          <Flex style={{ padding: "4px 0px 4px 0px" }}>
            <Box width={1 / 2}>
              <Category>G/N</Category>
            </Box>
            <Box width={1 / 2} style={{ textAlign: "right" }}>
              <Score>
                {score.grossCount}(
                {score.stoveCount +
                  score.cancelStoveCount +
                  score.withdrawalStoveCount}
                ){this.renderGrossApCount(score.grossApCount)}/{score.netCount}(
                {score.stoveCount})
                {this.renderNetApCount(
                  score.grossApCount,
                  score.cancelApCount,
                  score.withdrawalApCount
                )}
              </Score>
            </Box>
          </Flex>

          <Flex style={{ padding: "4px 0px 4px 0px" }}>
            <Box width={1 / 2}>
              <Category>C/T</Category>
            </Box>
            <Box width={1 / 2} style={{ textAlign: "right" }}>
              <Score>
                {score.cancelCount + score.cancelStoveCount}(
                {score.cancelStoveCount})
                {score.cancelApCount > 0 ? ` AP${score.cancelApCount} ` : ""}/
                {score.withdrawalCount + score.withdrawalStoveCount}(
                {score.withdrawalStoveCount})
                {score.withdrawalApCount > 0
                  ? ` AP${score.withdrawalApCount} `
                  : ""}
              </Score>
            </Box>
          </Flex>

          <Flex style={{ padding: "4px 0px 4px 0px" }}>
            <Box width={1 / 2}>
              <Category>CT率</Category>
            </Box>
            <Box width={1 / 2} style={{ textAlign: "right" }}>
              <Score>
                {score.cancelWithdrawalRate
                  ? `${score.cancelWithdrawalRate}%`
                  : "-"}
              </Score>
            </Box>
          </Flex>

          {mode == "details" && (
            <div>
              <Flex style={{ padding: "4px 0px 4px 0px" }}>
                <Box width={1 / 2}>
                  <Category>FT数/面談数</Category>
                </Box>
                <Box width={1 / 2} style={{ textAlign: "right" }}>
                  <Score>
                    {score.talkCount || "-"} / {score.meetingCount || "-"}
                  </Score>
                </Box>
              </Flex>

              <Flex style={{ padding: "4px 0px 4px 0px" }}>
                <Box width={1 / 2}>
                  <Category>突破率</Category>
                </Box>
                <Box width={1 / 2} style={{ textAlign: "right" }}>
                  <Score>
                    {score.breakthroughRate
                      ? `${score.breakthroughRate}%`
                      : "-"}
                  </Score>
                </Box>
              </Flex>

              <Flex style={{ padding: "4px 0px 4px 0px" }}>
                <Box width={1 / 2}>
                  <Category>決定率</Category>
                </Box>
                <Box width={1 / 2} style={{ textAlign: "right" }}>
                  <Score>
                    {score.determinedRate ? `${score.determinedRate}%` : "-"}
                  </Score>
                </Box>
              </Flex>

              <Flex style={{ padding: "4px 0px 4px 0px" }}>
                <Box width={1 / 2}>
                  <Category>即決数</Category>
                </Box>
                <Box width={1 / 2} style={{ textAlign: "right" }}>
                  <Score>{score.immediateOrderCount || "-"}</Score>
                </Box>
              </Flex>

              <Flex style={{ padding: "4px 0px 4px 0px" }}>
                <Box width={1 / 2}>
                  <Category>即決率</Category>
                </Box>
                <Box width={1 / 2} style={{ textAlign: "right" }}>
                  <Score>
                    {score.immediateDeterminedRate
                      ? `${score.immediateDeterminedRate}%`
                      : "-"}
                  </Score>
                </Box>
              </Flex>

              <Flex style={{ padding: "4px 0px 4px 0px" }}>
                <Box width={1 / 2}>
                  <Category>再訪決定数/再訪数</Category>
                </Box>
                <Box width={1 / 2} style={{ textAlign: "right" }}>
                  <Score>
                    {score.revisitOrderCount || "-"} /{" "}
                    {score.revisitCount || "-"}
                  </Score>
                </Box>
              </Flex>

              <Flex style={{ padding: "4px 0px 4px 0px" }}>
                <Box width={1 / 2}>
                  <Category>再訪決定率</Category>
                </Box>
                <Box width={1 / 2} style={{ textAlign: "right" }}>
                  <Score>
                    {score.revisitDeterminedRate
                      ? `${score.revisitDeterminedRate}%`
                      : "-"}
                  </Score>
                </Box>
              </Flex>
            </div>
          )}
        </div>
      </div>
    );
  }
}

MonthlyScore.defaultProps = {
  mode: "summary"
};

const SubHeading = styled.div`
  font-size: 17px;
  font-weight: bold;
  padding-left: 16px;
  padding-top: 12px;
  padding-bottom: 12px;
  margin-top: 22px;
  background-color: ${Colors.lightBlue};
`;

const Score = styled.div`
  font-weight: bold;
  font-size: 15px;
`;

const Category = styled.span`
  color: rgb(101, 119, 134);
  font-size: 15px;
`;
