import { create } from "apisauce";

const api = create({
  baseURL: "https://nippou-api.otp-system.com/",
  headers: {
    "Cache-Control": "no-cache"
  },
  // 10 second timeout...
  timeout: 10000
});

api.addMonitor(response => {
  if (response.status == 401) {
    window.location = "/login";
  }
});

// Auth
const login = payload => api.post("/auth", payload);
const getMe = () => api.get(`/me`);
const getMyReport = (page = 1) => api.get(`/my-report`, { page });
const getUserScore = id => api.get(`/user/${id}/score`);
const updateSetting = (monthlyReportId, payload) =>
  api.put(`/setting/${monthlyReportId}`, payload);

// Aggregated Reports
const getBlockReports = () => api.get(`/reports/block`);
const getTeamReports = () => api.get(`/reports/team`);
const getSoloReports = () => api.get(`/reports/solo`);
const getEntryYearReports = () => api.get(`/reports/entry-year`);
const getOldNewReports = () => api.get(`/reports/old-new`);

// Report CRUD
const getFeeds = (page = 1) => api.get(`/feed`, { page });
const getReports = () => api.get(`/feed`);
const createReport = payload => api.post(`/report`, payload);
const getReport = reportId => api.get(`/report/${reportId}`);
const updateReport = (reportId, payload) =>
  api.put(`/report/${reportId}`, payload);
const deleteReport = reportId => api.delete(`/report/${reportId}`);

// Order
const createOrder = payload => api.post(`/order`, payload);
const getOrder = orderId => api.get(`/order/${orderId}`);
const updateOrder = (orderId, payload) => api.put(`/order/${orderId}`, payload);
const deleteOrder = orderId => api.delete(`/order/${orderId}`);

const getOrders = (year, month, page = 1) =>
  api.get(`/orders`, { year, month, page });

// Events
const getCalender = () => api.get(`/monthly-events`);
const updateEventStatus = (eventId, payload) =>
  api.put(`/monthly-events/${eventId}/setting`, payload);

// Team Reports
const getTeamReport = () => api.get(`/team-report`);

// Admin
const getAdminReports = (page = 1, date = "") =>
  api.get(`/admin/reports`, { page, date });
const getAdminReportOrders = reportId =>
  api.get(`/admin/report/${reportId}/orders`);

const setToken = token => {
  api.setHeader("Authorization", `Bearer ${token}`);
};

export default {
  setToken,

  // Auth
  login,
  getMe,
  getMyReport,
  getUserScore,
  updateSetting,

  // Aggregated Reports
  getBlockReports,
  getTeamReports,
  getEntryYearReports,
  getSoloReports,
  getOldNewReports,

  // Report CRUD
  getFeeds,
  getReports,
  createReport,
  getReport,
  updateReport,
  deleteReport,

  // Order CURD
  createOrder,
  getOrder,
  updateOrder,
  deleteOrder,

  getOrders,

  getCalender,
  updateEventStatus,

  getTeamReport,

  // Admin
  getAdminReports,
  getAdminReportOrders
};
