import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Heading } from "rebass";
import styled from "styled-components";
import Report from "../components/Report";
import Layout from "../components/Layout";
import * as Icon from "react-feather";
import { Colors, Rounded } from "../themes";
import { loadTimelinePage, loadMoreTimeline } from "../actions";
import moment from "moment";
import InfiniteScroll from "react-infinite-scroller";

class Timeline extends Component {
  currentDate = "";

  constructor(props) {
    super(props);

    this.loadFunc = this.loadFunc.bind(this);
  }

  componentWillMount() {
    // this.props.loadTimelinePage();
  }

  loadFunc() {
    // this.props.loadMoreTimeline();
  }

  render() {
    const { edges, pageInfo } = this.props.feeds;
    this.currentDate = "";
    if (edges.length === 0) {
      return (
        <Layout>
          <header>
            <Heading>ホーム</Heading>
          </header>

          <div style={{ marginBottom: 61, padding: 16 }}>
            OTP杯期間中は非表示となります
          </div>
        </Layout>
      );
    }

    return (
      <Layout>
        <header>
          <Heading>ホーム</Heading>

          {/* <div
            style={{
              position: "absolute",
              left: 0,
              right: 0,
              bottom: 0
            }}
          >
            <div
              style={{
                position: "relative",
                textAlign: "center"
              }}
            >
              <RoundedButton>
                <Icon.ChevronUp color={"#fff"} />
                <span>新しい日報を表示</span>
              </RoundedButton>
            </div>
          </div> */}
        </header>

        <ul style={{ marginBottom: 61 }}>
          <InfiniteScroll
            pageStart={0}
            loadMore={this.loadFunc}
            hasMore={pageInfo.hasNextPage}
            loader={
              <div className="loader" key={0}>
                Loading ...
              </div>
            }
          >
            {edges.map((item, i) => {
              moment.locale("ja", {
                weekdaysShort: ["日", "月", "火", "水", "木", "金", "土"]
              });

              let created = moment(item.node.created).format(
                "YYYY年M月D日(ddd)"
              );
              let dateSection = null;

              if (this.currentDate != created) {
                dateSection = <Date created={created}>{created}</Date>;

                this.currentDate = created;
              }

              return (
                <li key={i}>
                  {dateSection}
                  <Report key={item.node.id} report={item.node} />
                </li>
              );
            })}
          </InfiniteScroll>
        </ul>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    feeds: state.feeds
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    {
      loadTimelinePage,
      loadMoreTimeline
    }
  )(Timeline)
);

const RoundedButton = styled.a`
  background-color: rgb(29, 161, 242);

  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.22) 0px 3px 6px;

  transform: translate3d(0px, 3.5em, 0px);

  min-height: 30px;
  position: relative;

  z-index: 10;

  text-align: center;
  padding: 16px;
  display: inline;

  cursor: pointer;

  & > span {
    color: #fff;
  }

  ${Rounded}
`;

const Date = styled.div`
  background-color: rgb(238, 238, 238);
  padding: 16px;
  font-weight: bold;

  ${props =>
    props.created.includes("(土)") == true && "background-color: #FCCFC9"};

  ${props =>
    props.created.includes("(日)") == true && "background-color: #FCCFC9"};
`;
