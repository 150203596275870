import React, { Component } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Toggle } from "react-formik-ui";
import { Flex, Box } from "rebass";
import { RoundedButton } from "../themes";
import { Formik } from "formik";
import Modal from "react-modal";
import API from "../services/api";
import { Persist } from "formik-persist";
import * as Icon from "react-feather";
import * as moment from "moment";
import UpdateStoreOrder from "./UpdateStoreOrder";

export default class CreateReportForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalIsOpen: false,
      orderIndex: 0
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  renderCancelWithdrawalCount() {
    const { cancelWithdrawalCount, createReport } = this.props;
    return (
      <div>
        {Object.keys(cancelWithdrawalCount).map((month, i) => {
          const { cancel, withdrawal } = cancelWithdrawalCount[month];

          return (
            <Flex key={i} justifyContent="flex-end" style={{ margin: 10 }}>
              <Box width={1 / 2}>
                <div>
                  {month}月度 C/T {cancel.normalCount + cancel.stoveCount || 0}(
                  {cancel.stoveCount || 0})
                  {cancel.apCount ? `AP${cancel.apCount}` : ""} /
                  {withdrawal.normalCount + withdrawal.stoveCount || 0}(
                  {withdrawal.stoveCount || 0})
                  {withdrawal.apCount ? `AP${withdrawal.apCount}` : ""}
                </div>
              </Box>
            </Flex>
          );
        })}

        {createReport.reorderedOrders.length > 0 && (
          <Flex justifyContent="flex-end" style={{ margin: 10 }}>
            <Box width={1 / 2}>
              再計上　{createReport.reorderedOrders.length}本
            </Box>
          </Flex>
        )}
        {createReport.changedOrders.length > 0 && (
          <Flex justifyContent="flex-end" style={{ margin: 10 }}>
            <Box width={1 / 2}>
              工事日変更 {createReport.changedOrders.length}本
            </Box>
          </Flex>
        )}
      </div>
    );
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  openModal(number) {
    this.setState({
      modalIsOpen: true,
      orderIndex: number
    });
  }

  closeModal() {
    this.setState({
      modalIsOpen: false
    });
  }

  render() {
    const { report, orderScore, cancelWithdrawalCount } = this.props;

    let initialFormState = JSON.parse(
      localStorage.getItem("create-report-form")
    );

    if (initialFormState == null) {
      initialFormState = {
        marketPrefecture: "",
        marketCity: "",
        marketArea: "",
        marketPrefecture2: "",
        marketCity2: "",
        marketArea2: "",
        meetingCount: 0,
        talkCount: 0,
        revisitCount: 0,
        isRecruiting: false,
        description: ""
      };
    }

    return (
      <div>
        <Formik
          initialValues={{
            ...report,
            marketPrefecture: initialFormState.marketPrefecture,
            marketCity: initialFormState.marketCity,
            marketArea: initialFormState.marketArea,
            marketPrefecture2: initialFormState.marketPrefecture2,
            marketCity2: initialFormState.marketCity2,
            marketArea2: initialFormState.marketArea2,
            meetingCount: initialFormState.meetingCount,
            talkCount: initialFormState.talkCount,
            revisitCount: initialFormState.revisitCount,
            isRecruiting: initialFormState.isRecruiting,
            description: initialFormState.description
          }}
          validate={values => {
            let errors = {};
            if (!values.marketPrefecture) {
              errors.marketPrefecture = "必須項目です";
            }
            if (!values.marketCity) {
              errors.marketCity = "必須項目です";
            }
            if (!values.marketArea) {
              errors.marketArea = '必須項目です';
            }

            // バリデーション後、フォーム値を保持
            localStorage.setItem("create-report-form", JSON.stringify(values));

            return errors;
          }}
          onSubmit={async (values, { setSubmitting }) => {
            API.setToken(localStorage.getItem('token'));

            const {
              orders,
              cancelOrders,
              withdrawalOrders,
              reorderedOrders,
              changedOrders
            } = this.props.createReport;

            // キャンセル・撤回、再計上、工事日変更情報を含める
            values = {
              orders,
              cancelOrders,
              withdrawalOrders,
              cancelWithdrawalCount,
              reorderedOrders,
              changedOrders,
              ...values,
              marketArea: values.marketArea.trim(),
              marketArea2: values.marketArea2.trim()
            };
            let res = await API.createReport(values);

            if (res.ok) {
              // 送信後、市場以外のフォーム項目はリセットする
              const formState = {
                marketPrefecture: values.marketPrefecture,
                marketCity: '', // 2段目・3段目は毎回リセット
                marketArea: '',
                marketPrefecture2: values.marketPrefecture2,
                marketCity2: '', // 2段目・3段目は毎回リセット
                marketArea2: '',
                meetingCount: 0,
                talkCount: 0,
                revisitCount: 0,
                isRecruiting: false,
                description: values.description
              };
              localStorage.setItem(
                "create-report-form",
                JSON.stringify(formState)
              );

              this.props.history.goBack();
              this.props.resetSelectedOrders();
              setSubmitting(false);
            } else {
              alert(
                "エラーが発生しました。しばらくたってから再度お試しください。"
              );
              setSubmitting(false);
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting
          }) => {
            return (
              <ReportForm onSubmit={handleSubmit}>
                {/* 
            isSubmittingがtrueでpersistされると送信ができなくなってしまう
            <Persist name="create-report-form" />
             */}
                <Flex
                  justifyContent="space-between"
                  style={{ marginTop: 10, height: 45 }}
                >
                  <Box
                    width={1 / 2}
                    style={{ display: 'flex', flexDirection: 'column' }}
                  >
                    <span>市場</span>
                    <Required>必須項目</Required>
                  </Box>
                  <Box width={1 / 2}>
                    <input
                      type="text"
                      name="marketPrefecture"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.marketPrefecture}
                      placeholder="都道府県"
                    />
                    {errors.marketPrefecture && touched.marketPrefecture && (
                      <ErrorMessage>{errors.marketPrefecture}</ErrorMessage>
                    )}
                  </Box>
                </Flex>

                <Flex
                  justifyContent="flex-end"
                  style={{ marginTop: 10, height: 60 }}
                >
                  <Box width={1 / 2}>
                    <input
                      type="text"
                      name="marketCity"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.marketCity}
                      placeholder="市町村区"
                    />
                    {errors.marketCity && touched.marketCity && (
                      <ErrorMessage>{errors.marketCity}</ErrorMessage>
                    )}
                  </Box>
                </Flex>
                <Flex
                  justifyContent="flex-end"
                  style={{ marginBottom: 35, height: 60 }}
                >
                  <Box width={1 / 2}>
                    <input
                      type="text"
                      name="marketArea"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.marketArea}
                      placeholder="エリア（ゼンリン表記)"
                    />
                    {errors.marketArea && touched.marketArea && (
                      <ErrorMessage>{errors.marketArea}</ErrorMessage>
                    )}
                  </Box>
                </Flex>

                <Flex
                  justifyContent="space-between"
                  style={{ marginTop: 10, height: 45 }}
                >
                  <Box
                    width={1 / 2}
                    style={{ display: 'flex', flexDirection: 'column' }}
                  >
                    <span>市場 (2)</span>
                  </Box>
                  <Box width={1 / 2}>
                    <input
                      type="text"
                      name="marketPrefecture2"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.marketPrefecture2}
                      placeholder="都道府県"
                    />
                  </Box>
                </Flex>

                <Flex
                  justifyContent="flex-end"
                  style={{ marginTop: 10, height: 60 }}
                >
                  <Box width={1 / 2}>
                    <input
                      type="text"
                      name="marketCity2"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.marketCity2}
                      placeholder="市町村区"
                    />
                  </Box>
                </Flex>
                <Flex
                  justifyContent="flex-end"
                  style={{ marginBottom: 35, height: 60 }}
                >
                  <Box width={1 / 2}>
                    <input
                      type="text"
                      name="marketArea2"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.marketArea2}
                      placeholder="エリア（ゼンリン表記)"
                    />
                  </Box>
                </Flex>

                <Flex
                  justifyContent="space-between"
                  style={{ marginBottom: 35, height: 60 }}
                >
                  <Box width={1 / 2}>
                    <span>面談数</span>
                  </Box>
                  <Box
                    width={1 / 2}
                    style={{ display: 'flex', flexDirection: 'column' }}
                  >
                    <div>
                      <select
                        name="meetingCount"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.meetingCount}
                        style={{ fontSize: '16px' }}
                      >
                        <option value="0">0</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option>
                        <option value="13">13</option>
                        <option value="14">14</option>
                        <option value="15">15</option>
                        <option value="16">16</option>
                        <option value="17">17</option>
                        <option value="18">18</option>
                        <option value="19">19</option>
                        <option value="20">20</option>
                        <option value="21">21</option>
                        <option value="22">22</option>
                        <option value="23">23</option>
                        <option value="24">24</option>
                        <option value="25">25</option>
                        <option value="26">26</option>
                        <option value="27">27</option>
                        <option value="28">28</option>
                        <option value="29">29</option>
                        <option value="30">30</option>
                        <option value="31">31</option>
                        <option value="32">32</option>
                        <option value="33">33</option>
                        <option value="34">34</option>
                        <option value="35">35</option>
                        <option value="36">36</option>
                        <option value="37">37</option>
                        <option value="38">38</option>
                        <option value="39">39</option>
                        <option value="40">40</option>
                        <option value="41">41</option>
                        <option value="42">42</option>
                        <option value="43">43</option>
                        <option value="44">44</option>
                        <option value="45">45</option>
                        <option value="46">46</option>
                        <option value="47">47</option>
                        <option value="48">48</option>
                        <option value="49">49</option>
                        <option value="50">50</option>
                        <option value="51">51</option>
                        <option value="52">52</option>
                        <option value="53">53</option>
                        <option value="54">54</option>
                        <option value="55">55</option>
                        <option value="56">56</option>
                        <option value="57">57</option>
                        <option value="58">58</option>
                        <option value="59">59</option>
                        <option value="60">60</option>
                        <option value="61">61</option>
                        <option value="62">62</option>
                        <option value="63">63</option>
                        <option value="64">64</option>
                        <option value="65">65</option>
                        <option value="66">66</option>
                        <option value="67">67</option>
                        <option value="68">68</option>
                        <option value="69">69</option>
                        <option value="70">70</option>
                        <option value="71">71</option>
                        <option value="72">72</option>
                        <option value="73">73</option>
                        <option value="74">74</option>
                        <option value="75">75</option>
                        <option value="76">76</option>
                        <option value="77">77</option>
                        <option value="78">78</option>
                        <option value="79">79</option>
                        <option value="80">80</option>
                        <option value="81">81</option>
                        <option value="82">82</option>
                        <option value="83">83</option>
                        <option value="84">84</option>
                        <option value="85">85</option>
                        <option value="86">86</option>
                        <option value="87">87</option>
                        <option value="88">88</option>
                        <option value="89">89</option>
                        <option value="90">90</option>
                        <option value="91">91</option>
                        <option value="92">92</option>
                        <option value="93">93</option>
                        <option value="94">94</option>
                        <option value="95">95</option>
                        <option value="96">96</option>
                        <option value="97">97</option>
                        <option value="98">98</option>
                        <option value="99">99</option>
                        <option value="100">100</option>
                      </select>
                      面談
                    </div>
                    {errors.meetingCount && touched.meetingCount && (
                      <ErrorMessage>{errors.meetingCount}</ErrorMessage>
                    )}
                  </Box>
                </Flex>

                <Flex
                  justifyContent="space-between"
                  style={{ marginBottom: 35, height: 60 }}
                >
                  <Box width={1 / 2}>
                    <span>FT数</span>
                  </Box>
                  <Box
                    width={1 / 2}
                    style={{ display: 'flex', flexDirection: 'column' }}
                  >
                    <div>
                      <select
                        name="talkCount"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.talkCount}
                        style={{ fontSize: '16px' }}
                      >
                        <option value="0">0</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option>
                        <option value="13">13</option>
                        <option value="14">14</option>
                        <option value="15">15</option>
                        <option value="16">16</option>
                        <option value="17">17</option>
                        <option value="18">18</option>
                        <option value="19">19</option>
                        <option value="20">20</option>
                        <option value="21">21</option>
                        <option value="22">22</option>
                        <option value="23">23</option>
                        <option value="24">24</option>
                        <option value="25">25</option>
                        <option value="26">26</option>
                        <option value="27">27</option>
                        <option value="28">28</option>
                        <option value="29">29</option>
                        <option value="30">30</option>
                        <option value="31">31</option>
                        <option value="32">32</option>
                        <option value="33">33</option>
                        <option value="34">34</option>
                        <option value="35">35</option>
                        <option value="36">36</option>
                        <option value="37">37</option>
                        <option value="38">38</option>
                        <option value="39">39</option>
                        <option value="40">40</option>
                        <option value="41">41</option>
                        <option value="42">42</option>
                        <option value="43">43</option>
                        <option value="44">44</option>
                        <option value="45">45</option>
                        <option value="46">46</option>
                        <option value="47">47</option>
                        <option value="48">48</option>
                        <option value="49">49</option>
                        <option value="50">50</option>
                        <option value="51">51</option>
                        <option value="52">52</option>
                        <option value="53">53</option>
                        <option value="54">54</option>
                        <option value="55">55</option>
                        <option value="56">56</option>
                        <option value="57">57</option>
                        <option value="58">58</option>
                        <option value="59">59</option>
                        <option value="60">60</option>
                        <option value="61">61</option>
                        <option value="62">62</option>
                        <option value="63">63</option>
                        <option value="64">64</option>
                        <option value="65">65</option>
                        <option value="66">66</option>
                        <option value="67">67</option>
                        <option value="68">68</option>
                        <option value="69">69</option>
                        <option value="70">70</option>
                        <option value="71">71</option>
                        <option value="72">72</option>
                        <option value="73">73</option>
                        <option value="74">74</option>
                        <option value="75">75</option>
                        <option value="76">76</option>
                        <option value="77">77</option>
                        <option value="78">78</option>
                        <option value="79">79</option>
                        <option value="80">80</option>
                        <option value="81">81</option>
                        <option value="82">82</option>
                        <option value="83">83</option>
                        <option value="84">84</option>
                        <option value="85">85</option>
                        <option value="86">86</option>
                        <option value="87">87</option>
                        <option value="88">88</option>
                        <option value="89">89</option>
                        <option value="90">90</option>
                        <option value="91">91</option>
                        <option value="92">92</option>
                        <option value="93">93</option>
                        <option value="94">94</option>
                        <option value="95">95</option>
                        <option value="96">96</option>
                        <option value="97">97</option>
                        <option value="98">98</option>
                        <option value="99">99</option>
                        <option value="100">100</option>
                      </select>
                      FT
                    </div>
                    {errors.talkCount && touched.talkCount && (
                      <ErrorMessage>{errors.talkCount}</ErrorMessage>
                    )}
                  </Box>
                </Flex>

                <Flex
                  justifyContent="space-between"
                  style={{ marginBottom: 35, height: 60 }}
                >
                  <Box width={1 / 2}>
                    <span>再訪数</span>
                  </Box>
                  <Box
                    width={1 / 2}
                    style={{ display: 'flex', flexDirection: 'column' }}
                  >
                    <div>
                      <select
                        name="revisitCount"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.revisitCount}
                        style={{ fontSize: '16px' }}
                      >
                        <option value="0">0</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option>
                        <option value="13">13</option>
                        <option value="14">14</option>
                        <option value="15">15</option>
                        <option value="16">16</option>
                        <option value="17">17</option>
                        <option value="18">18</option>
                        <option value="19">19</option>
                        <option value="20">20</option>
                        <option value="21">21</option>
                        <option value="22">22</option>
                        <option value="23">23</option>
                        <option value="24">24</option>
                        <option value="25">25</option>
                        <option value="26">26</option>
                        <option value="27">27</option>
                        <option value="28">28</option>
                        <option value="29">29</option>
                        <option value="30">30</option>
                        <option value="31">31</option>
                        <option value="32">32</option>
                        <option value="33">33</option>
                        <option value="34">34</option>
                        <option value="35">35</option>
                        <option value="36">36</option>
                        <option value="37">37</option>
                        <option value="38">38</option>
                        <option value="39">39</option>
                        <option value="40">40</option>
                        <option value="41">41</option>
                        <option value="42">42</option>
                        <option value="43">43</option>
                        <option value="44">44</option>
                        <option value="45">45</option>
                        <option value="46">46</option>
                        <option value="47">47</option>
                        <option value="48">48</option>
                        <option value="49">49</option>
                        <option value="50">50</option>
                        <option value="51">51</option>
                        <option value="52">52</option>
                        <option value="53">53</option>
                        <option value="54">54</option>
                        <option value="55">55</option>
                        <option value="56">56</option>
                        <option value="57">57</option>
                        <option value="58">58</option>
                        <option value="59">59</option>
                        <option value="60">60</option>
                        <option value="61">61</option>
                        <option value="62">62</option>
                        <option value="63">63</option>
                        <option value="64">64</option>
                        <option value="65">65</option>
                        <option value="66">66</option>
                        <option value="67">67</option>
                        <option value="68">68</option>
                        <option value="69">69</option>
                        <option value="70">70</option>
                        <option value="71">71</option>
                        <option value="72">72</option>
                        <option value="73">73</option>
                        <option value="74">74</option>
                        <option value="75">75</option>
                        <option value="76">76</option>
                        <option value="77">77</option>
                        <option value="78">78</option>
                        <option value="79">79</option>
                        <option value="80">80</option>
                        <option value="81">81</option>
                        <option value="82">82</option>
                        <option value="83">83</option>
                        <option value="84">84</option>
                        <option value="85">85</option>
                        <option value="86">86</option>
                        <option value="87">87</option>
                        <option value="88">88</option>
                        <option value="89">89</option>
                        <option value="90">90</option>
                        <option value="91">91</option>
                        <option value="92">92</option>
                        <option value="93">93</option>
                        <option value="94">94</option>
                        <option value="95">95</option>
                        <option value="96">96</option>
                        <option value="97">97</option>
                        <option value="98">98</option>
                        <option value="99">99</option>
                        <option value="100">100</option>
                      </select>
                      件
                    </div>
                    {errors.revisitCount && touched.revisitCount && (
                      <ErrorMessage>{errors.revisitCount}</ErrorMessage>
                    )}
                  </Box>
                </Flex>

                <Flex
                  justifyContent="space-between"
                  style={{ marginBottom: 15, height: 60 }}
                >
                  <Box width={1 / 4}>
                    <span>結果</span>
                  </Box>
                  <Box width={1 / 4}>
                    <Link to="/order">
                      <Icon.PlusCircle />
                    </Link>
                  </Box>
                  <Box width={2 / 4}>
                    {/* {Object.keys(orderScore.count).map(month => {
                    return (
                      <div>
                        {month}月度: {orderScore.count[month].normalCount}(
                        {orderScore.count[month].stoveCount})
                      </div>
                    );
                  })} */}
                  </Box>
                </Flex>

                <div
                  style={{
                    backgroundColor: '#f4f4f4',
                    padding: 16,
                    marginBottom: 32
                  }}
                >
                  <h4>本日のオーダー</h4>

                  {this.props.createReport.orders.map((order, index) => {
                    return (
                      <Flex
                        key={index}
                        justifyContent="space-between"
                        style={{
                          marginBottom: 5,
                          border: '1px solid #000',
                          borderRadius: 8,
                          padding: 8
                        }}
                      >
                        <Box
                          width={1 / 2}
                          onClick={() => this.openModal(index)}
                        >
                          <div>
                            工事日:
                            {moment(order.constructionDate).format(
                              'MM/DD'
                            )}{' '}
                            {order.name}
                          </div>
                        </Box>
                        <Box>
                          <div
                            onClick={() => this.props.removeOrder(index)}
                            style={{
                              textAlign: 'right'
                            }}
                          >
                            <Icon.XCircle size={18} />
                          </div>
                        </Box>
                      </Flex>
                    );
                  })}

                  {this.props.createReport.orders.length == 0 && (
                    <div>本日のオーダーはありません</div>
                  )}
                </div>

                <Flex
                  justifyContent="space-between"
                  style={{ marginBottom: 30 }}
                >
                  <Box width={4 / 7}>
                    <div>キャンセル(C), 撤回(T）</div>
                  </Box>
                  <Box width={3 / 7}>
                    <Link
                      to="/calender"
                      style={{
                        display: 'table-cell',
                        verticalAlign: 'middle',
                        borderRadius: 5,
                        padding: 8,
                        fontSize: 12,
                        textAlign: 'right',
                        color: '#fff',
                        backgroundColor: '#808080'
                      }}
                    >
                      オーダーを選択する
                      <Icon.ArrowRight
                        size={16}
                        style={{ paddingBottom: '3px' }}
                      />
                    </Link>
                  </Box>
                </Flex>

                <div style={{ backgroundColor: '#f4f4f4', padding: 16 }}>
                  <h4>キャンセルオーダー</h4>

                  {this.props.createReport.cancelOrders.map((order, i) => {
                    return (
                      <Flex
                        key={i}
                        justifyContent="space-between"
                        style={{
                          marginBottom: 5,
                          border: '1px solid #000',
                          borderRadius: 8,
                          padding: 8
                        }}
                      >
                        <Box width={1 / 2}>
                          <div>
                            {moment(order.created).format('MM/DD')} {order.name}
                          </div>
                        </Box>
                        <Box width={1 / 2}>
                          <div
                            onClick={() =>
                              this.props.removeCancelOrder(order.id)
                            }
                            style={{
                              textAlign: 'right'
                            }}
                          >
                            <Icon.XCircle size={18} />
                          </div>
                        </Box>
                      </Flex>
                    );
                  })}

                  {this.props.createReport.cancelOrders.length == 0 && (
                    <div>キャンセルしたオーダーはありません</div>
                  )}
                </div>

                <div style={{ backgroundColor: '#f4f4f4', padding: 16 }}>
                  <h4>撤回オーダー</h4>
                  {this.props.createReport.withdrawalOrders.map((order, i) => {
                    return (
                      <Flex
                        key={i}
                        justifyContent="space-between"
                        style={{
                          marginBottom: 5,
                          border: '1px solid #000',
                          borderRadius: 8,
                          padding: 8
                        }}
                      >
                        <Box width={1 / 2}>
                          <div>
                            {moment(order.created).format('MM/DD')} {order.name}
                          </div>
                        </Box>
                        <Box width={1 / 2}>
                          <div
                            onClick={() =>
                              this.props.removeWithdrawalOrder(order.id)
                            }
                            style={{
                              textAlign: 'right'
                            }}
                          >
                            <Icon.XCircle size={18} />
                          </div>
                        </Box>
                      </Flex>
                    );
                  })}

                  {this.props.createReport.withdrawalOrders.length == 0 && (
                    <div>撤回したオーダーはありません</div>
                  )}
                </div>

                <div style={{ backgroundColor: '#f4f4f4', padding: 16 }}>
                  <h4>再計上オーダー</h4>
                  {this.props.createReport.reorderedOrders.map((order, i) => {
                    return (
                      <Flex
                        key={i}
                        justifyContent="space-between"
                        style={{
                          marginBottom: 5,
                          border: '1px solid #000',
                          borderRadius: 8,
                          padding: 8
                        }}
                      >
                        <Box width={1 / 2}>
                          <div>
                            {moment(order.created).format('MM/DD')} {order.name}
                          </div>
                        </Box>
                        <Box width={1 / 2}>
                          <div
                            onClick={() =>
                              this.props.removeReorderedOrder(order.id)
                            }
                            style={{
                              textAlign: 'right'
                            }}
                          >
                            <Icon.XCircle size={18} />
                          </div>
                        </Box>
                      </Flex>
                    );
                  })}

                  {this.props.createReport.reorderedOrders.length == 0 && (
                    <div>再計上したオーダーはありません</div>
                  )}
                </div>

                <div style={{ backgroundColor: '#f4f4f4', padding: 16 }}>
                  <h4>工事日変更オーダー</h4>
                  {this.props.createReport.changedOrders.map((order, i) => {
                    return (
                      <Flex
                        key={i}
                        justifyContent="space-between"
                        style={{
                          marginBottom: 5,
                          border: '1px solid #000',
                          borderRadius: 8,
                          padding: 8
                        }}
                      >
                        <Box width={1 / 2}>
                          <div>
                            {moment(order.created).format('MM/DD')} {order.name}
                          </div>
                        </Box>
                        <Box width={1 / 2}>
                          <div
                            onClick={() =>
                              this.props.removeChangedOrder(order.id)
                            }
                            style={{
                              textAlign: 'right'
                            }}
                          >
                            <Icon.XCircle size={18} />
                          </div>
                        </Box>
                      </Flex>
                    );
                  })}

                  {this.props.createReport.changedOrders.length == 0 && (
                    <div>工事日変更オーダーはありません</div>
                  )}
                </div>

                <div
                  style={{
                    fontSize: '14px',
                    fontWeight: 600,
                    textAlign: 'right'
                  }}
                >
                  {this.renderCancelWithdrawalCount()}
                </div>

                <Flex
                  justifyContent="space-between"
                  style={{ marginBottom: 35, height: 30, marginTop: 30 }}
                >
                  <Box width={2 / 4}>
                    <span>リクルーター制度</span>
                  </Box>
                  <Box width={1 / 4}>
                    <div>
                      <label>
                        <Toggle name="isRecruiting" />
                      </label>
                    </div>
                  </Box>
                  {values.isRecruiting == false && (
                    <Box width={1 / 4}>
                      <span>なし</span>
                    </Box>
                  )}
                  {values.isRecruiting == true && (
                    <Box width={1 / 4}>
                      <span>あり</span>
                    </Box>
                  )}
                </Flex>

                <div style={{ marginBottom: 16 }}>
                  <div style={{ marginBottom: 8 }}>備考</div>
                  <textarea
                    name="description"
                    placeholder="同行報告など"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.description}
                  />
                </div>

                <div style={{ textAlign: 'center' }}>
                  <RoundedButton
                    style={{
                      padding: 8,
                      width: 200
                    }}
                    type="submit"
                    isSubmitting={isSubmitting}
                    disabled={isSubmitting}
                  >
                    <span>{isSubmitting ? '送信中...' : '送信'}</span>
                  </RoundedButton>
                </div>
              </ReportForm>
            );
          }}
        </Formik>
        <Modal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          style={customStyles}
          contentLabel="OrderModal"
        >
          <UpdateStoreOrder
            index={this.state.orderIndex}
            order={this.props.createReport.orders[this.state.orderIndex]}
            closeModal={this.closeModal}
            {...this.props}
          />
        </Modal>
      </div>
    );
  }
}

CreateReportForm.defaultProps = {
  report: {
    marketPrefecture: "",
    marketCity: "",
    marketArea: "",
    marketPrefecture2: "",
    marketCity2: "",
    marketArea2: "",
    meetingCount: 0,
    talkCount: 0,
    revisitCount: 0,
    isRecruiting: false,
    description: ""
  }
};

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: 0,
    borderRadius: 0,
    height: "80%",
    overlfow: "scroll"
  }
};

const NumberInput = styled.input`
  text-align: right;
`;

const Required = styled.span`
  max-width: 60px;
  font-size: 10px;
  text-align: center;
  display: inline-block;
  padding: 2px;
  margin-top: 10px;
  color: #fff;
  background-color: #fa8072;
  border-radius: 5px;
`;

const ErrorMessage = styled.span`
  margin-top: 5px;
  font-size: 12px;
  font-weight: 600;
  color: #fa8072;
`;

const ReportForm = styled.form`
  padding: 16px;

  & input {
    font-size: 16px;
    padding: 8px;
  }
`;
