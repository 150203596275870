import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { Flex, Box, Heading } from "rebass";
import * as Icon from "react-feather";
import InfiniteScroll from "react-infinite-scroller";
import * as moment from "moment";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Formik } from "formik";
import { loadAdminReportsPage, loadAdminMoreReports } from "../../actions";
import { getOrderScore } from "../../utils";
import { RoundedButton, Colors } from "../../themes";
import ReportOrders from "./ReportOrders";
import ReactModal from "react-modal";

class Reports extends Component {
  constructor() {
    super();

    this.state = {
      reportId: "",
      showModal: false
    };

    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.loadFunc = this.loadFunc.bind(this);
  }

  componentWillMount() {
    this.props.loadAdminReportsPage(this.props.adminReports.date);
  }

  handleOpenModal(reports) {
    if (reports.length == 0) {
      return;
    }

    this.setState({ reportId: reports[0].id, showModal: true });
  }

  handleCloseModal() {
    this.setState({ showModal: false });
  }

  loadFunc() {
    this.props.loadAdminMoreReports();
  }

  renderOrderLabel(report) {
    if (!report) {
      return null;
    }
    if (report.createdOrders != null && report.createdOrders.length == 0) {
      return null;
    }

    return <OrderLabel>オーダー</OrderLabel>;
  }

  renderCancelWithdrawalLabel(report) {
    if (!report) {
      return null;
    }

    let hide = true;
    if (report.cancelOrders != null && report.cancelOrders.length > 0) {
      hide = false;
    }
    if (report.withdrawalOrders != null && report.withdrawalOrders.length > 0) {
      hide = false;
    }

    if (hide) {
      return null;
    }

    return <CTLabel>CT</CTLabel>;
  }

  renderReport(report) {
    if (!report) {
      return "-";
    }

    const {
      meetingCount,
      talkCount,
      orderDetails,
      created,
      description
    } = report;
    const { orderCount, cancelWithdrawalCount } = orderDetails;
    const {
      grossCount,
      grossStoveCount,
      grossApCount,
      cancelCount,
      cancelStoveCount,
      withdrawalCount,
      withdrawalStoveCount,
      cancelApCount,
      withdrawalApCount
    } = getOrderScore(orderCount, cancelWithdrawalCount);

    let formattedTime = moment(created).format("HH:mm");

    const cancelWithdrawalMonths = Object.keys(cancelWithdrawalCount);

    return (
      <div>
        <Flex justifyContent="space-between" style={{ marginTop: 8 }}>
          <Box
            width={1 / 5}
            style={{ display: "flex", flexDirection: "column" }}
          >
            <ScoreContainer justifyContent="flex-start">
              <Box>
                <div style={{ fontSize: "11px" }}>{formattedTime}</div>
              </Box>
            </ScoreContainer>
          </Box>
          <Box
            width={1 / 5}
            style={{ display: "flex", flexDirection: "column" }}
          >
            <ScoreContainer justifyContent="flex-start">
              <Box>
                <Topic>結果</Topic>
                <Score>{meetingCount}</Score>/<Score>{talkCount}</Score>/
                <Score>{grossCount}</Score>
                <Score>
                  ({grossStoveCount}){grossApCount > 0 && ` AP${grossApCount} `}
                </Score>
              </Box>
            </ScoreContainer>
          </Box>
          <Box
            width={1 / 5}
            style={{ display: "flex", flexDirection: "column" }}
          >
            <ScoreContainer justifyContent="flex-start">
              <Box>
                <Topic>C/T</Topic>
                <Score>{cancelCount}</Score>
                <Score>
                  ({cancelStoveCount})
                  {cancelApCount > 0 && ` AP${cancelApCount} `}
                </Score>
                /<Score>{withdrawalCount}</Score>
                <Score>
                  ({withdrawalStoveCount})
                  {withdrawalApCount > 0 ? ` AP${withdrawalApCount} ` : ""}
                </Score>
                <Score>{cancelWithdrawalMonths.join()}</Score>
              </Box>
            </ScoreContainer>
          </Box>
          <Box
            width={1 / 5}
            style={{ display: "flex", flexDirection: "column" }}
          />
        </Flex>
        <Flex>
          <Description>{description}</Description>
        </Flex>
      </div>
    );
  }

  renderGrossApCount(grossApCount) {
    if (!grossApCount) {
      return null;
    }
    return <span> AP{grossApCount} </span>;
  }

  renderNetApCount(grossApCount, cancelApCount, withdrawalApCount) {
    if (!grossApCount) {
      return null;
    }
    return <span> AP{grossApCount - cancelApCount - withdrawalApCount} </span>;
  }

  renderMonthlyReport(monthlyReport) {
    const {
      month,
      grossCount,
      stoveCount,
      netCount,
      netStoveCount,
      grossApCount,
      cancelApCount,
      withdrawalApCount
    } = monthlyReport;

    return (
      <Box
        key={monthlyReport.id}
        width={1 / 4}
        style={{
          flexDirection: "column",
          backgroundColor: "#E4E4E4",
          borderRadius: "5px",
          padding: "5px 0 5px 0"
        }}
      >
        <ScoreContainer justifyContent="center">
          <Box>
            <Topic>{month}月度G/N</Topic>
            <Score style={{ textAlign: "center", fontSize: "11px" }}>
              {/* ※stoveCountはgross, netStoceCountはnet*/}
              {grossCount}({stoveCount}) {this.renderGrossApCount(grossApCount)}
              /{netCount}({netStoveCount})
              {this.renderNetApCount(
                grossApCount,
                cancelApCount,
                withdrawalApCount
              )}
            </Score>
          </Box>
        </ScoreContainer>
      </Box>
    );
  }

  renderMonthlyAllReport(monthlyReport) {
    const {
      month,
      myGoal,
      opened,
      grossCount,
      stoveCount,
      netCount,
      netStoveCount,
      cancelCount,
      cancelStoveCount,
      withdrawalCount,
      withdrawalStoveCount,
      grossApCount,
      cancelApCount,
      withdrawalApCount
    } = monthlyReport;

    return (
      <div
        key={monthlyReport.id}
        style={{
          display: "flex",
          flexDirection: "row"
        }}
      >
        <Box width={1 / 2}>
          <ScoreContainer justifyContent="flex-start">
            <Box>
              <Topic style={{ textAlign: "left" }}>{month}月度G/N</Topic>
              <Score style={{ textAlign: "left" }}>
                {/* ※stoveCountはgross, netStoceCountはnet*/}
                {grossCount}({stoveCount}){" "}
                {this.renderGrossApCount(grossApCount)}/{netCount}(
                {netStoveCount})
                {this.renderNetApCount(
                  grossApCount,
                  cancelApCount,
                  withdrawalApCount
                )}
              </Score>
            </Box>
          </ScoreContainer>
        </Box>
        <Box width={1 / 2}>
          <ScoreContainer justifyContent="flex-start">
            <Box>
              <Topic style={{ textAlign: "left" }}>{month}月度 C/T </Topic>
              <Score style={{ textAlign: "left" }}>
                {cancelCount + cancelStoveCount || 0}({cancelStoveCount || 0})
                {cancelApCount ? `AP${cancelApCount}` : ""} /
                {withdrawalCount + withdrawalStoveCount || 0}(
                {withdrawalStoveCount || 0})
                {withdrawalApCount ? `AP${withdrawalApCount}` : ""}
              </Score>
            </Box>
          </ScoreContainer>
        </Box>
      </div>
    );
  }

  render() {
    const { isFetching, pageInfo, edges, date } = this.props.adminReports;

    return (
      <div className="container">
        <header>
          <Flex justifyContent="space-between">
            <Heading>管理画面</Heading>
          </Flex>
        </header>

        <Formik
          initialValues={{
            date
          }}
          onSubmit={async (values, { setSubmitting }) => {
            this.props.loadAdminReportsPage(values.date);

            setSubmitting(false);
          }}
        >
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting
          }) => (
            <form
              onSubmit={handleSubmit}
              style={{ marginTop: "0px", marginBottom: "0px" }}
            >
              <div style={{ padding: "16px", backgroundColor: "#E4E4E4" }}>
                <Flex justifyContent="space-between">
                  <Box
                    width={1 / 6}
                    style={{
                      marginTop: "10px"
                    }}
                  >
                    <span
                      style={{
                        fontWeight: "bold",
                        fontSize: "15px"
                      }}
                    >
                      日時選択
                    </span>
                  </Box>
                  <Box width={2 / 6} style={{ marginTop: "8px" }}>
                    <input
                      type="date"
                      name="date"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.date}
                    />
                  </Box>
                  <Box width={2 / 6} style={{ alignItems: "center" }}>
                    <RoundedButton
                      style={{ padding: 8, width: 100 }}
                      type="submit"
                      isSubmitting={isSubmitting}
                      disabled={isSubmitting}
                    >
                      検索
                    </RoundedButton>
                  </Box>
                </Flex>
              </div>
            </form>
          )}
        </Formik>

        <div style={{ marginBottom: 35, marginTop: 18 }}>
          <Tabs>
            <TabList style={{ textAlign: "center" }}>
              <Tab style={{ fontSize: 13, paddingLeft: 40, paddingRight: 40 }}>
                日報
              </Tab>
              <Tab style={{ fontSize: 13, paddingLeft: 40, paddingRight: 40 }}>
                月度
              </Tab>
            </TabList>
            <TabPanel>
              <div className="container">
                {isFetching ? (
                  <div style={{ margin: 16 }}>検索中..</div>
                ) : (
                  <ul style={{ marginBottom: 120 }}>
                    <InfiniteScroll
                      pageStart={0}
                      loadMore={this.loadFunc}
                      hasMore={pageInfo.hasNextPage}
                      loader={
                        <div className="loader" key={0}>
                          Loading ...
                        </div>
                      }
                    >
                      {edges.map((item, i) => {
                        const { name, reports, monthlyReports } = item.node;

                        return (
                          <Container
                            onClick={() => this.handleOpenModal(reports)}
                            key={i}
                          >
                            <Flex justifyContent="space-between">
                              <Box
                                width={1 / 4}
                                style={{
                                  display: "flex",
                                  flexDirection: "column"
                                }}
                              >
                                <UserName>{name}</UserName>
                              </Box>
                              <Box
                                width={1 / 4}
                                style={{
                                  display: "flex",
                                  flexDirection: "column"
                                }}
                              >
                                {this.renderOrderLabel(reports[0])}
                              </Box>
                              <Box
                                width={1 / 4}
                                style={{
                                  display: "flex",
                                  flexDirection: "column"
                                }}
                              >
                                {this.renderCancelWithdrawalLabel(reports[0])}
                              </Box>
                              {monthlyReports.map(monthlyReport => {
                                return this.renderMonthlyReport(monthlyReport);
                              })}
                            </Flex>

                            {this.renderReport(reports[0])}
                          </Container>
                        );
                      })}
                      {/* <MonthlyGrossNet /> */}
                    </InfiniteScroll>
                  </ul>
                )}
              </div>
            </TabPanel>
            <TabPanel>
              <div className="container">
                {isFetching ? (
                  <div style={{ margin: 16 }}>検索中..</div>
                ) : (
                  <ul style={{ marginBottom: 120 }}>
                    <InfiniteScroll
                      pageStart={0}
                      loadMore={this.loadFunc}
                      hasMore={pageInfo.hasNextPage}
                      loader={
                        <div className="loader" key={0}>
                          Loading ...
                        </div>
                      }
                    >
                      {edges.map((item, i) => {
                        const { name, reports, monthlyReports } = item.node;

                        return (
                          <Container key={i}>
                            <Flex justifyContent="space-between">
                              <Box
                                width={1 / 3}
                                style={{
                                  display: "flex",
                                  flexDirection: "column"
                                }}
                              >
                                <UserName>{name}</UserName>
                              </Box>
                              <Box width={2 / 3}>
                                {monthlyReports.map(monthlyReport => {
                                  return this.renderMonthlyAllReport(
                                    monthlyReport
                                  );
                                })}
                              </Box>
                            </Flex>
                          </Container>
                        );
                      })}
                    </InfiniteScroll>
                  </ul>
                )}
              </div>
            </TabPanel>
          </Tabs>
        </div>

        <ReactModal
          style={customStyles}
          isOpen={this.state.showModal}
          contentLabel="日報詳細"
          onRequestClose={this.handleCloseModal}
        >
          <ReportOrders id={this.state.reportId} />
        </ReactModal>
      </div>
    );
  }
}

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: 0,
    borderRadius: 0,
    width: "80%",
    height: "80%",
    overlfow: "scroll"
  }
};

const Container = styled.li`
  list-style: none;
  border-bottom: 1px solid;
  border-bottom-color: rgb(230, 236, 240);
  padding: 16px;
  cursor: pointer;
  position: relative;
  &:hover {
    border: 2px solid;
    border-color: rgb(95, 179, 232);
`;

const OrderLabel = styled.span`
  border-radius: 10px;
  text-align: center;
  background-color: #7ed321;
  color: #fff;
`;
const CTLabel = styled.span`
  border-radius: 10px;
  text-align: center;
  background-color: #fa8072;
  color: #fff;
`;

const UserName = styled.span`
  font-weight: bold;
  font-size: 15px;
`;

const ScoreContainer = styled(Flex)``;

const Topic = styled.div`
  font-size: 13px;
  text-align: center;
`;

const Score = styled.span`
  font-size: 13px;
  font-weight: bold;
`;

const Description = styled.p`
  margin-top: 5px;
  margin-bottom: 0;
`;

const TabWrapper = styled.nav`
  width: 100%;
  background-color: #fff;
`;

function mapStateToProps(state) {
  return {
    adminReports: state.adminReports
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    {
      loadAdminReportsPage,
      loadAdminMoreReports
    }
  )(Reports)
);
