import React, { Component } from "react";
import styled from "styled-components";
import * as Icon from "react-feather";
import { Flex, Box } from "rebass";
import { Colors, BorderBottom } from "../../themes";

const rankings = {
  old: [
    { rank: 1, name: "依光ブロック", estimatedN: 356.5, estimatedNet: 29.7 },
    { rank: 2, name: "西東京支店", estimatedN: 262.2, estimatedNet: 29.1 },
    { rank: 3, name: "静岡支店", estimatedN: 257.6, estimatedNet: 28.6 },
    { rank: 4, name: "粂田ブロック", estimatedN: 250.7, estimatedNet: 27.9 },
    { rank: 5, name: "千葉支店", estimatedN: 158.7, estimatedNet: 17.6 }
  ],
  new: [
    { rank: 1, name: "依光ブロック", estimatedN: 356.5, estimatedNet: 29.7 },
    { rank: 2, name: "西東京支店", estimatedN: 262.2, estimatedNet: 29.1 }
  ]
};

export default class OldNewReportPage extends Component {
  constructor() {
    super();
    this.state = {
      currentTab: "old"
    };

    this.switchTab = this.switchTab.bind(this);
  }

  switchTab(tab) {
    this.setState({ currentTab: tab });
  }

  render() {
    const { currentTab } = this.state;
    let rankingData = rankings.old;

    return (
      <div className="container">
        <header>
          <Icon.ArrowLeft
            style={{ paddingBottom: "6px" }}
            onClick={() => this.props.history.goBack()}
          />
          <h1 style={{ marginLeft: 8 }}>先輩イチハチ効率速報</h1>
        </header>

        <div style={{ padding: 16 }}>現在準備中!</div>
      </div>
    );

    if (currentTab === "new") {
      rankingData = rankings.new;
    }

    return (
      <div className="container">
        <header>
          <Icon.ArrowLeft
            style={{ paddingBottom: "6px" }}
            onClick={() => this.props.history.goBack()}
          />
          <h1>先輩イチハチ効率速報</h1>
        </header>

        <TabWrapper>
          <Tabs>
            <Tab isCurrent={currentTab === "old" ? true : false}>
              <TabLink onClick={() => this.switchTab("old")}>先輩</TabLink>
            </Tab>
            <Tab isCurrent={currentTab === "new" ? true : false}>
              <TabLink onClick={() => this.switchTab("new")}>イチハチ</TabLink>
            </Tab>
          </Tabs>
        </TabWrapper>

        <RankingList>
          {rankingData.map((ranking, i) => {
            return (
              <RankingItem key={i}>
                <Flex justifyContent="space-between">
                  <Box width={1 / 2}>
                    <Rank>{ranking.rank}</Rank>
                    <Name>{ranking.name}</Name>
                  </Box>
                  <Box width={1 / 2} style={{ textAlign: "center" }}>
                    <Value>{ranking.estimatedN}</Value>
                    <Value>{ranking.estimatedNet}</Value>
                  </Box>
                </Flex>
              </RankingItem>
            );
          })}
        </RankingList>
      </div>
    );
  }
}

const RankingList = styled.ul``;

const RankingItem = styled.li`
  padding: 16px;
  ${BorderBottom}
`;

const Rank = styled.span`
  color: rgb(101, 119, 134);
  margin-right: 16px;
  float: left;
  font-weight: bold;
`;

const Name = styled.div`
  margin-left: 5px;
  font-weight: bold;
  font-size: 18px;
  text-align: left;
  width: 100%;
`;

const Value = styled.span`
  color: rgb(101, 119, 134);
  margin-left: 5px;
  text-align: center;
  font-weight: bold;
`;

export const TabWrapper = styled.nav`
  width: 100%;
  background-color: #fff;
`;

export const Tabs = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 25px;
  margin: 0 auto;
`;

export const Tab = styled.li`
  font-size: 0.88rem;
  flex: 1;
  text-align: center;
  margin-top: 0;
  border-right: 1px solid #efefef;
  border-bottom: ${props =>
    props.isCurrent ? `3px solid ${Colors.blue}` : "1px solid #efefef"};

  :first-child {
    border-left: 1px solid #efefef;
  }
`;

export const TabLink = styled.a`
  display: block;
  background-color: ${props => (props.isCurrent ? "#000" : "#fff")};
  color: ${props => (props.isCurrent ? "#fff" : "#000")};
  padding: 20px 0;
`;
