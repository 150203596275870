import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import * as Icon from "react-feather";
import InfiniteScroll from "react-infinite-scroller";
import * as moment from "moment";
import { Flex, Box } from "rebass";
import { Colors, Rounded } from "../themes";
import ChangeOrderDateModal from "../components/ChangeOrderDateModal";
import {
  loadOrdersPage,
  addCancelOrder,
  removeCancelOrder,
  addWithdrawalOrder,
  removeWithdrawalOrder,
  addReorderedOrder,
  removeReorderedOrder,
  addChangedOrder,
  removeChangedOrder
} from "../actions";
const queryString = require("query-string");

class OrderList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      changeMode: "",
      selectedOrder: {}
    };

    this.loadFunc = this.loadFunc.bind(this);
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.cancelSelected = this.cancelSelected.bind(this);
    this.withdrawalSelected = this.withdrawalSelected.bind(this);
    this.reorderedSelected = this.reorderedSelected.bind(this);
    this.renderBottomAction = this.renderBottomAction.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  componentWillMount() {
    const { year, month } = this.props.match.params;
    this.props.loadOrdersPage(year, month);
  }

  handleOpenModal() {
    this.setState({ showModal: true });
  }

  handleCloseModal() {
    this.setState({ showModal: false });
  }

  loadFunc() {
    this.props.loadFunc();
  }

  cancelSelected(id) {
    const cancelOrder = this.props.createReport.cancelOrders.filter(
      cancelOrder => {
        if (cancelOrder.id == id) {
          return true;
        }
        return false;
      }
    );

    return cancelOrder.length > 0;
  }

  withdrawalSelected(id) {
    const withdrawalOrder = this.props.createReport.withdrawalOrders.filter(
      withdrawalOrder => {
        if (withdrawalOrder.id == id) {
          return true;
        }
        return false;
      }
    );

    return withdrawalOrder.length > 0;
  }

  reorderedSelected(id) {
    const reorderedOrder = this.props.createReport.reorderedOrders.filter(
      reorderedOrder => {
        if (reorderedOrder.id == id) {
          return true;
        }
        return false;
      }
    );

    return reorderedOrder.length > 0;
  }

  dateChangedSelected(id) {
    const dateChangedOrder = this.props.createReport.changedOrders.filter(
      dateChangedOrder => {
        if (dateChangedOrder.id == id) {
          return true;
        }
        return false;
      }
    );

    return dateChangedOrder.length > 0;
  }

  renderBottomAction(month, item) {
    const {
      addCancelOrder,
      removeCancelOrder,
      addWithdrawalOrder,
      removeWithdrawalOrder,
      removeReorderedOrder,
      removeChangedOrder
    } = this.props;

    const { id, status } = item;

    const isCanceld = this.cancelSelected(id);
    const isWithdrawed = this.withdrawalSelected(id);
    const isReordered = this.reorderedSelected(id);
    const isDateChanged = this.dateChangedSelected(id);

    // オーダーステータスがCまたはTの場合は再計上ボタンのみを表示
    if (status == "C" || status == "T") {
      return (
        <Flex justifyContent="flex-end">
          <button
            style={{ marginRight: 8 }}
            onClick={() => {
              if (isReordered) {
                removeReorderedOrder(id);
              } else {
                this.setState({
                  changeMode: "reorder",
                  selectedOrder: item
                });
                this.handleOpenModal();
              }
            }}
          >
            {isReordered ? "再計上を取り消す" : "再計上"}
          </button>
        </Flex>
      );
    }
    // 既にキャンセル、撤回に選択されている場合は取り消しボタンを表示する
    if (isCanceld || isWithdrawed || isDateChanged) {
      return (
        <Flex justifyContent="flex-end">
          <button
            style={{ marginRight: 8 }}
            onClick={() => {
              if (isCanceld) {
                removeCancelOrder(id);
              }
              if (isWithdrawed) {
                removeWithdrawalOrder(id);
              }
              if (isDateChanged) {
                removeChangedOrder(id);
              }
            }}
          >
            {isCanceld && "キャンセルを取り消す"}
            {isWithdrawed && "撤回を取り消す"}
            {isDateChanged && "工事日変更を取り消す"}
          </button>
        </Flex>
      );
    }

    // オーダーステータスが通常の場合
    return (
      <Flex justifyContent="flex-end">
        {item.status !== "C" && (
          <button
            style={{ marginRight: 8 }}
            onClick={() => {
              addCancelOrder({
                ...item,
                month,
                action: "cancel"
              });
            }}
          >
            キャンセル
          </button>
        )}
        {item.status !== "T" && (
          <button
            style={{ marginRight: 8 }}
            onClick={() => {
              addWithdrawalOrder({
                ...item,
                month,
                action: "withdrawal"
              });
            }}
          >
            撤回
          </button>
        )}

        <button
          onClick={() => {
            this.setState({
              changeMode: "change-date",
              selectedOrder: item
            });
            this.handleOpenModal();
          }}
        >
          工事日変更
        </button>
      </Flex>
    );
  }

  render() {
    const { year, month } = this.props.match.params;
    const { edges, pageInfo } = this.props.orders;
    const parsed = queryString.parse(this.props.location.search);
    var userAgent = window.navigator.userAgent.toLowerCase();
    const currentDate = moment();

    if (edges.length === 0) {
      return (
        <div className="container">
          <header>
            <Flex justifyContent="space-between">
              <Box width={2 / 3}>
                <Icon.ArrowLeft
                  style={{ paddingBottom: "6px" }}
                  onClick={() => this.props.history.goBack()}
                />
                <h1>オーダーリスト</h1>
              </Box>
            </Flex>
          </header>
          <div style={{ marginBottom: 35, padding: 16 }}>
            注文情報がありません
          </div>

          <aside
            style={{
              position: "fixed",
              width: "100%",
              right: 0,
              left: 0,
              bottom: 0,
              maxWidth: "600px",
              margin: "0 auto"
            }}
          >
            <RoundedButton
              to="/submit"
              onClick={() => {
                if (userAgent.indexOf("chrome") != -1) {
                  this.props.history.go(-2);
                } else if (userAgent.indexOf("safari") != -1) {
                  this.props.history.go(-3);
                } else {
                  this.props.history.go(-2);
                }
              }}
            >
              <div style={{ textAlign: "center" }}>
                <Icon.ArrowLeft size={16} />
                <div>日報</div>
              </div>
            </RoundedButton>
          </aside>
        </div>
      );
    }

    return (
      <div className="container">
        <header>
          <Flex justifyContent="space-between">
            <Box width={2 / 3}>
              <Icon.ArrowLeft
                style={{ paddingBottom: "6px" }}
                onClick={() => this.props.history.goBack()}
              />
              <h1>オーダーリスト</h1>
            </Box>
          </Flex>
        </header>
        <div style={{ marginBottom: 35 }} />
        <Flex
          justifyContent="space-between"
          style={{
            marginBottom: 20,
            marginRight: 16,
            height: 20
          }}
        >
          <Box width={1 / 4}>
            <ListTitle>{month}月度</ListTitle>
          </Box>
          <Box
            width={3 / 4}
            style={{
              display: "flex",
              fontSize: "12px",
              justifyContent: "flex-end"
            }}
          >
            <span>N</span>
            <Palette style={{ backgroundColor: "#c9fccf" }} />
            <span>CT</span>
            <Palette style={{ backgroundColor: "#fccfc9" }} />
            <span>開通</span>
            <Palette style={{ backgroundColor: "#cfc9fc" }} />
          </Box>
        </Flex>

        <ul style={{ marginBottom: 61, padding: 16 }}>
          <InfiniteScroll
            pageStart={0}
            loadMore={this.loadFunc}
            hasMore={pageInfo.hasNextPage}
            loader={
              <div className="loader" key={0}>
                Loading ...
              </div>
            }
          >
            {edges.map((item, i) => {
              const {
                id,
                name,
                nameKana,
                orderTime,
                isRevisited,
                noticeDate,
                constructionDate,
                confirmContract,
                depositType,
                depositApartment,
                hasElectricityApplication,
                address,
                homePhoneNumber,
                cellPhoneNumber,
                previousCompany,
                previousCompanyOffice,
                bombName,
                salesOffice,
                eventNumber,
                status,
                isEvent,
                description,
                excluded,
                created
              } = item.node;

              const outputConstructionDate = moment(constructionDate);

              return (
                <li key={i} style={{ marginBottom: 8 }}>
                  <Frame
                    status={status}
                    excluded={excluded}
                    opened={currentDate.isAfter(outputConstructionDate)}
                  >
                    <Flex justifyContent="space-between">
                      <Date>
                        <a>{moment(created).format("MM/DD")}</a>
                      </Date>
                      <Icon.Edit
                        onClick={() => this.props.history.push(`/order/${id}`)}
                      />
                    </Flex>
                    <ContentFrame>
                      <Flex
                        justifyContent="space-between"
                        style={{ marginTop: 15 }}
                      >
                        <div>オーダー時間</div>
                        <div>{orderTime}</div>
                      </Flex>
                      <Flex justifyContent="space-between">
                        <div>即決/再訪</div>
                        <div>{isRevisited ? "再訪" : "即決"}</div>
                      </Flex>
                      <Flex justifyContent="space-between">
                        <div>お名前</div>
                        <div>{name}</div>
                      </Flex>
                      <Flex justifyContent="space-between">
                        <div>お名前（ふりがな）</div>
                        <div>{nameKana}</div>
                      </Flex>
                      <Flex justifyContent="space-between">
                        <div>通告日</div>
                        <div>{moment(noticeDate).format("YYYY/MM/DD")}</div>
                      </Flex>
                      <Flex justifyContent="space-between">
                        <div>工事日</div>
                        <div>
                          {moment(constructionDate).format("YYYY/MM/DD")}
                        </div>
                      </Flex>
                      <Flex justifyContent="space-between">
                        <div>契約書確認</div>
                        <div>{confirmContract ? "有" : "無"}</div>
                      </Flex>
                      <Flex justifyContent="space-between">
                        <div>入金体系</div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column"
                          }}
                        >
                          <div>
                            {depositType}
                            {depositApartment ? `${depositApartment}本` : ""}
                          </div>
                        </div>
                      </Flex>
                      <Flex justifyContent="space-between">
                        <div>電力申込</div>
                        <div>{hasElectricityApplication ? "有" : "無"}</div>
                      </Flex>
                      <Flex justifyContent="space-between">
                        <div>住所</div>
                        <div>{address}</div>
                      </Flex>
                      <Flex justifyContent="space-between">
                        <div>電話番号（自宅）</div>
                        <div>{homePhoneNumber}</div>
                      </Flex>
                      <Flex justifyContent="space-between">
                        <div>電話番号（携帯）</div>
                        <div>{cellPhoneNumber}</div>
                      </Flex>
                      <Flex justifyContent="space-between">
                        <div>ガス会社名</div>
                        <div>{previousCompany}</div>
                      </Flex>
                      <Flex justifyContent="space-between">
                        <div>ガス会社営業所名</div>
                        <div>{previousCompanyOffice}</div>
                      </Flex>
                      <Flex justifyContent="space-between">
                        <div>ボンベ名</div>
                        <div>{bombName}</div>
                      </Flex>
                      <Flex justifyContent="space-between">
                        <div>ニチガス営業所名</div>
                        <div>{salesOffice}</div>
                      </Flex>
                      <Flex justifyContent="space-between">
                        <div>イベントNo.</div>
                        <div>{eventNumber}</div>
                      </Flex>
                      <Flex justifyContent="space-between">
                        <div>イベント括弧</div>
                        <div>{isEvent ? "有" : "無"}</div>
                      </Flex>
                      <Flex justifyContent="space-between">
                        <div>オーダー状況</div>
                        <div>{status}</div>
                      </Flex>
                      <Flex justifyContent="space-between">
                        <Box width={1 / 2}>
                          <div>メモ</div>
                        </Box>
                        <Box width={1 / 2}>
                          <div style={{ overflowWrap: "break-word" }}>
                            {description}
                          </div>
                        </Box>
                      </Flex>
                      <Flex justifyContent="space-between">
                        <div>計上ステータス</div>
                        <div>{excluded ? "非計上" : "計上"}</div>
                      </Flex>

                      {parsed.q !== "disabled" &&
                        this.renderBottomAction(month, item.node)}
                    </ContentFrame>
                  </Frame>
                </li>
              );
            })}
          </InfiniteScroll>
        </ul>

        {parsed.q !== "disabled" && (
          <aside
            style={{
              position: "fixed",
              width: "100%",
              right: 0,
              left: 0,
              bottom: -65,
              maxWidth: "600px",
              margin: "0 auto"
            }}
          >
            <RoundedButton
              to="/submit"
              onClick={() => {
                if (userAgent.indexOf("chrome") != -1) {
                  this.props.history.go(-2);
                } else if (userAgent.indexOf("safari") != -1) {
                  this.props.history.go(-3);
                } else {
                  this.props.history.go(-2);
                }
              }}
            >
              <div style={{ textAlign: "center" }}>
                <Icon.ArrowLeft size={16} />
                <div>日報</div>
              </div>
            </RoundedButton>
          </aside>
        )}

        <ChangeOrderDateModal
          selectedOrder={this.state.selectedOrder}
          showModal={this.state.showModal}
          handleCloseModal={this.handleCloseModal}
          handleSubmit={changedOrder => {
            const { changeMode } = this.state;
            if (changeMode == "reorder") {
              this.props.addReorderedOrder(changedOrder);
            } else if (changeMode == "change-date") {
              this.props.addChangedOrder(changedOrder);
            }

            this.handleCloseModal();
          }}
        />
      </div>
    );
  }
}

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: 0,
    borderRadius: 0,
    padding: 20
  }
};

const Palette = styled.div`
  border: 1px solid;
  width: 12px;
  height: 12px;
  border-radius: 3px;
  margin-left: 3px;
  margin-top: 3px;
  margin-right: 6px;
`;

const NumberInput = styled.input`
  text-align: right;
`;

const ListTitle = styled.p`
  margin-top: 8px;
  padding: 8px 16px;
  font-size: 13px;
  border-radius: 0 5px 0 0;
  background-color: #82ceff;
  font-weight: 540;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  color: ${Colors.white};
`;

const Frame = styled.div`
  margin: 12px 12pz;
  padding: 12px 12px;
  width: 100%;
  height: 100%;
  border: 1px solid;
  border-color: ${Colors.black};
  border-radius: 5px 5px;
  box-sizing: border-box;

  ${props => props.status !== "" && "background-color: #FCCFC9"};
  ${props =>
    props.status == "" && props.opened == true && "background-color: #cfc9fc"};
  ${props =>
    props.status == "" && props.opened == false && "background-color: #c9fccf"};

  ${props => props.excluded == true && "background-color: #e1e5f0"};
`;

const Date = styled.div`
  text-align: center;
  padding: 6px;
  border: 1px solid;
  border-color: ${Colors.black};
  border-radius: 5px 5px;
`;

const ContentFrame = styled.div`
  font-size: 13px;
`;

const RoundedButton = styled(Link)`
  width: 60px;
  height: 60px;
  min-height: 30px;
  color: rgb(29, 161, 242);
  border: 1px solid;
  border-color: rgb(29, 161, 242);
  display: inline-block;
  padding: 8px;

  ${Rounded}

  background-color: rgb(255, 255, 255);
  font-size: 15px;

  position: absolute;
  bottom: 80px;
  right: 15px;
  z-index: 10;

  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.22) 0px 3px 6px;
`;

const ErrorMessage = styled.span`
  margin-top: 5px;
  font-size: 12px;
  font-weight: 600;
  color: #fa8072;
`;

function mapStateToProps(state) {
  return {
    orders: state.orders,
    createReport: state.createReport
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    {
      loadOrdersPage,
      addCancelOrder,
      removeCancelOrder,
      addWithdrawalOrder,
      removeWithdrawalOrder,
      addReorderedOrder,
      removeReorderedOrder,
      addChangedOrder,
      removeChangedOrder
    }
  )(OrderList)
);
