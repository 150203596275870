import styled from "styled-components";
import { Link } from "react-router-dom";
import Colors from "./Colors";

const BorderTop = `
    border-top: 1px solid #E8E8E8;
`;
const BorderBottom = `
    border-bottom: 1px solid #E8E8E8;
`;

const Rounded = `
    border-bottom-left-radius: 9999px;
    border-bottom-right-radius: 9999px;
    border-top-right-radius: 9999px;
    border-top-left-radius: 9999px;
`;

const RoundedButton = styled.button`
  min-height: 30px;
  background-color: ${props =>
    props.isSubmitting ? "rgb(95, 179, 232)" : "rgb(29, 161, 242)"};
  display: inline-block;
  padding: 8px;

  ${Rounded}

  color: rgb(255, 255, 255);
  font-size: 15px;
`;

const List = styled.ul``;

const ListItem = styled.li`
  display: flex;

  margin-top: 1px;
  padding: 0;
  width: 100%;

  align-items: center;
  list-style: none;
  cursor: pointer;

  border-bottom: 1px solid;
  border-bottom-color: rgb(230, 236, 240);

  height: ${props => (props.height ? props.height : "60px")};
  width: ${props => (props.width ? props.width : "250px")};

  & > a {
    width: 100%;
    padding: 16px;
    text-decoration: none;
  }
`;

export {
  Colors,
  BorderTop,
  BorderBottom,
  Rounded,
  RoundedButton,
  List,
  ListItem
};
