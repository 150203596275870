import React, { Component } from "react";
import { Provider } from "react-redux";
import { Router, Route, Switch, Redirect } from "react-router";
import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import createBrowserHistory from "history/createBrowserHistory";
import reducer from "./reducers";
import mySaga from "./sagas";

// route components
import AppContainer from "./App";
import Login from "./pages/Login";
import TimelinePage from "./pages/Timeline";
import ReportList from "./pages/reports/ReportList";
import CreateReport from "./pages/reports/CreateReport";
import UpdateSettingPage from "./pages/reports/UpdateSettingPage";
import UpdateReport from "./pages/reports/UpdateReport";
import BlockReport from "./pages/reports/BlockReport";
import TeamReport from "./pages/reports/TeamReport";
import EntryYearReport from "./pages/reports/EntryYearReport";
import SoloReport from "./pages/reports/SoloReport";
import OldNewReport from "./pages/reports/OldNewReport";
import CreateOrder from "./pages/CreateOrder";
import UpdateOrder from "./pages/UpdateOrder";
import OrderList from "./pages/OrderList";
import CreateTeamReport from "./pages/CreateTeamReport";
import Calendar from "./pages/Calender";
import Annaunce from "./pages/Annaunce";
import PaceChart from "./pages/PaceChart";
import AdminReportOrders from "./pages/admin/ReportOrders";
import AdminReports from "./pages/admin/Reports";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const sagaMiddleware = createSagaMiddleware();
const store = createStore(
  reducer,
  composeEnhancers(applyMiddleware(sagaMiddleware))
);
sagaMiddleware.run(mySaga);

const browserHistory = createBrowserHistory();

function PrivateRoute({ component: Component, ...rest }) {
  const token = localStorage.getItem("token");
  return (
    <Route
      {...rest}
      render={props =>
        token ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/login" }} />
        )
      }
    />
  );
}

export const renderRoutes = () => (
  <Provider store={store}>
    <Router history={browserHistory}>
      <Switch>
        <Route exact path="/" component={Login} />
        <Route exact path="/login" component={Login} />
        <PrivateRoute exact path="/timeline" component={TimelinePage} />
        <PrivateRoute exact path="/reports" component={ReportList} />
        <PrivateRoute exact path="/mypage" component={AppContainer} />
        <PrivateRoute exact path="/submit" component={CreateReport} />
        <PrivateRoute exact path="/order" component={CreateOrder} />
        <PrivateRoute exact path="/order/:id" component={UpdateOrder} />
        <PrivateRoute exact path="/orders/:year/:month" component={OrderList} />
        <PrivateRoute exact path="/report/:id" component={UpdateReport} />
        <PrivateRoute exact path="/reports/block" component={BlockReport} />
        <PrivateRoute exact path="/reports/group" component={TeamReport} />
        <PrivateRoute
          exact
          path="/reports/entry-year"
          component={EntryYearReport}
        />
        <PrivateRoute exact path="/reports/solo" component={SoloReport} />
        <PrivateRoute exact path="/reports/old-new" component={OldNewReport} />
        <PrivateRoute exact path="/setting/:id" component={UpdateSettingPage} />
        <PrivateRoute exact path="/team-report" component={CreateTeamReport} />
        <PrivateRoute exact path="/calender" component={Calendar} />
        <PrivateRoute exact path="/annaunce" component={Annaunce} />
        <PrivateRoute exact path="/pacechart" component={PaceChart} />
        <PrivateRoute
          exact
          path="/admin/report/:id"
          component={AdminReportOrders}
        />
        <PrivateRoute exact path="/admin/reports" component={AdminReports} />
      </Switch>
    </Router>
  </Provider>
);
