import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Flex, Box } from "rebass";
import * as Icon from "react-feather";
import OrderForm from "../components/OrderForm";
import { addOrder } from "../actions";

class CreateOrderPage extends Component {
  render() {
    return (
      <div className="container">
        <header>
          <Flex justifyContent="space-between">
            <Box width={2 / 3}>
              <Icon.ArrowLeft
                style={{ paddingBottom: "6px" }}
                onClick={() => this.props.history.goBack()}
              />
              <h1>オーダーを作成</h1>
            </Box>
          </Flex>
        </header>
        <div style={{ marginBottom: 35 }}>
          <OrderForm {...this.props} />
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect(
    null,
    {
      addOrder
    }
  )(CreateOrderPage)
);
