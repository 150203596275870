import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { Flex, Box } from "rebass";
import * as Icon from "react-feather";
import { RoundedButton } from "../../themes";
import { Formik } from "formik";
import API from "../../services/api";
import { Toggle } from "react-formik-ui";

class UpdateSettingPage extends Component {
  render() {
    const { match, history, calender } = this.props;
    const { id } = match.params;

    if (calender.entities.event == undefined) {
      return null;
    }

    const { myGoal, totalWorkingCount, opened, hide } = calender.entities.event[
      id
    ];

    return (
      <div className="container">
        <header>
          <Flex justifyContent="space-between">
            <Box width={2 / 3}>
              <Icon.ArrowLeft onClick={() => this.props.history.goBack()} />
              <h1>月度設定</h1>
            </Box>
          </Flex>
        </header>

        <div style={{ marginBottom: 35 }}>
          <Formik
            initialValues={{
              myGoal,
              totalWorkingCount,
              opened,
              hide
            }}
            validate={values => {
              let errors = {};
              if (!values.myGoal) {
                errors.myGoal = "必須項目です";
              }
              if (!values.totalWorkingCount) {
                errors.totalWorkingCount = "必須項目です";
              }
              return errors;
            }}
            onSubmit={async (values, { setSubmitting }) => {
              API.setToken(localStorage.getItem("token"));
              const res = await API.updateSetting(id, values);
              if (res.ok) {
                setSubmitting(false);
                this.props.history.goBack();
              } else {
                alert(
                  "エラーが発生しました。しばらくたってから再度お試しください。"
                );
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting
            }) => (
              <Form onSubmit={handleSubmit}>
                <Flex
                  justifyContent="space-between"
                  style={{ marginBottom: 15, height: 60 }}
                >
                  <Box width={2 / 3}>
                    <span>月度目標</span>
                  </Box>
                  <Box width={1 / 3}>
                    <div>
                      <select
                        name="myGoal"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.myGoal}
                        style={{ fontSize: "16px" }}
                      >
                        <option value="">-</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option>
                        <option value="13">13</option>
                        <option value="14">14</option>
                        <option value="15">15</option>
                        <option value="16">16</option>
                        <option value="17">17</option>
                        <option value="18">18</option>
                        <option value="19">19</option>
                        <option value="20">20</option>
                        <option value="21">21</option>
                        <option value="22">22</option>
                        <option value="23">23</option>
                        <option value="24">24</option>
                        <option value="25">25</option>
                        <option value="26">26</option>
                        <option value="27">27</option>
                        <option value="28">28</option>
                        <option value="29">29</option>
                        <option value="30">30</option>
                        <option value="31">31</option>
                        <option value="32">32</option>
                        <option value="33">33</option>
                        <option value="34">34</option>
                        <option value="35">35</option>
                        <option value="36">36</option>
                        <option value="37">37</option>
                        <option value="38">38</option>
                        <option value="39">39</option>
                        <option value="40">40</option>
                        <option value="41">41</option>
                        <option value="42">42</option>
                        <option value="43">43</option>
                        <option value="44">44</option>
                        <option value="45">45</option>
                        <option value="46">46</option>
                        <option value="47">47</option>
                        <option value="48">48</option>
                        <option value="49">49</option>
                        <option value="50">50</option>
                      </select>
                      　本
                    </div>
                    {errors.myGoal && touched.myGoal && (
                      <ErrorMessage>{errors.myGoal}</ErrorMessage>
                    )}
                  </Box>
                </Flex>

                <Flex
                  justifyContent="space-between"
                  style={{ marginBottom: 15, height: 60 }}
                >
                  <Box width={2 / 3}>
                    <span>月度稼働日数</span>
                  </Box>
                  <Box width={1 / 3}>
                    <div>
                      <select
                        name="totalWorkingCount"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.totalWorkingCount}
                        style={{ fontSize: "16px" }}
                      >
                        <option value="">-</option>
                        <option value="15">15</option>
                        <option value="16">16</option>
                        <option value="17">17</option>
                        <option value="18">18</option>
                        <option value="19">19</option>
                        <option value="20">20</option>
                        <option value="21">21</option>
                        <option value="22">22</option>
                        <option value="23">23</option>
                        <option value="24">24</option>
                        <option value="25">25</option>
                        <option value="26">26</option>
                        <option value="27">27</option>
                        <option value="28">28</option>
                        <option value="29">29</option>
                        <option value="30">30</option>
                      </select>
                      　日稼働
                    </div>
                    {errors.totalWorkingCount && touched.totalWorkingCount && (
                      <ErrorMessage>{errors.totalWorkingCount}</ErrorMessage>
                    )}
                  </Box>
                </Flex>

                <Flex
                  justifyContent="space-between"
                  style={{ width: "100%", marginBottom: 35 }}
                >
                  <Box width={2 / 3}>
                    <span>全開通</span>
                  </Box>
                  <Box width={1 / 3}>
                    <Toggle name="opened" />
                  </Box>
                </Flex>

                <Flex
                  justifyContent="space-between"
                  style={{ width: "100%", marginBottom: 35 }}
                >
                  <Box width={2 / 3}>
                    <span>非表示</span>
                  </Box>
                  <Box width={1 / 3}>
                    <Toggle name="hide" />
                  </Box>
                </Flex>

                <div style={{ textAlign: "center" }}>
                  <RoundedButton
                    style={{ padding: 8, width: 200 }}
                    type="submit"
                    isSubmitting={isSubmitting}
                    disabled={isSubmitting}
                  >
                    <span>{isSubmitting ? "保存中..." : "保存"}</span>
                  </RoundedButton>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    );
  }
}

const NumberInput = styled.input`
  text-align: right;
`;

const ErrorMessage = styled.span`
  margin-top: 5px;
  font-size: 12px;
  font-weight: 600;
  color: #fa8072;
`;

const Form = styled.form`
  padding: 16px;

  & input {
    font-size: 16px;
    padding: 8px;
  }
`;

const Item = styled.li`
  display: block;
  font-size: 15px;
  text-align: left;
  color: #333;
  display: flex;
  padding: 8px 8px;
  margin-top: 0.5em;

  border-bottom: 1px solid #c7c7cc;
`;

function mapStateToProps(state) {
  return {
    calender: state.calender
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    null
  )(UpdateSettingPage)
);
