import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { Flex, Box } from "rebass";
import * as Icon from "react-feather";
import { Formik, Field } from "formik";
import { Persist } from "formik-persist";
import { RoundedButton, Colors } from "../themes";
import { loadCreateTeamReportPage } from "../actions";
import * as moment from "moment";
import { getOrderScore } from "../utils";

class CreateTeamReport extends Component {
  componentWillMount() {
    this.props.loadCreateTeamReportPage();
  }

  renderTodayReport(todayReport) {
    if (todayReport == undefined) {
      return <div>稼働なし</div>;
    }

    const { meetingCount, talkCount, orderDetails } = todayReport;

    let orderCount = orderDetails.orderCount;
    let cancelWithdrawalCount = orderDetails.cancelWithdrawalCount;

    const {
      grossCount,
      grossStoveCount,
      grossApCount,
      cancelCount,
      cancelStoveCount,
      withdrawalCount,
      withdrawalStoveCount,
      cancelApCount,
      withdrawalApCount
    } = getOrderScore(orderCount, cancelWithdrawalCount);

    return (
      <div>
        <div>
          {meetingCount} {talkCount} {grossCount}({grossStoveCount})
          {grossApCount > 0 && ` AP${grossApCount} `}
        </div>
        <div style={{ fontWeight: "bold", marginTop: 5 }}>本日CT</div>
        <div>
          {cancelCount}({cancelStoveCount})
          {cancelApCount > 0 && ` AP${cancelApCount} `}/{withdrawalCount}(
          {withdrawalStoveCount})
          {withdrawalApCount > 0 && ` AP${withdrawalApCount} `}
        </div>
      </div>
    );
  }

  renderGrossApCount(grossApCount) {
    if (!grossApCount) {
      return null;
    }
    return <span> AP{grossApCount} </span>;
  }

  renderNetApCount(grossApCount, cancelApCount, withdrawalApCount) {
    if (!grossApCount) {
      return null;
    }
    return <span> AP{grossApCount - cancelApCount - withdrawalApCount} </span>;
  }

  renderMonthlyReport(monthlyReport, i) {
    const {
      month,
      myGoal,
      opened,
      grossCount,
      stoveCount,
      netCount,
      netStoveCount,
      grossApCount,
      cancelApCount,
      withdrawalApCount,
      cancelCount,
      cancelStoveCount,
      withdrawalCount,
      withdrawalStoveCount
    } = monthlyReport;

    return (
      <div key={i} style={{ marginTop: 15 }}>
        <div style={{ fontWeight: "bold", marginBottom: 4 }}>
          {month}月度 【目標
          {myGoal}本】
          {opened && <span>全開通</span>}
        </div>

        <div>
          G/N {grossCount}({stoveCount}){this.renderGrossApCount(grossApCount)}/
          {netCount}({netStoveCount})
          {this.renderNetApCount(
            grossApCount,
            cancelApCount,
            withdrawalApCount
          )}
        </div>
        <div>
          C/T {cancelCount + cancelStoveCount}({cancelStoveCount})
          {cancelApCount > 0 ? ` AP${cancelApCount} ` : ""}/
          {withdrawalCount + withdrawalStoveCount}({withdrawalStoveCount})
          {withdrawalApCount > 0 ? ` AP${withdrawalApCount} ` : ""}
        </div>
      </div>
    );
  }

  render() {
    const { isFetching, item } = this.props.teamReport;

    if (isFetching || item == undefined) {
      return (
        <div className="container">
          <header>
            <Flex justifyContent="space-between">
              <Box width={1 / 2}>
                <Icon.ArrowLeft
                  style={{ paddingBottom: "6px" }}
                  onClick={() => this.props.history.goBack()}
                />
                <h1>係メール作成</h1>
              </Box>
            </Flex>
          </header>

          <div style={{ marginBottom: 35 }}>取得中</div>
        </div>
      );
    }

    const { mailSubject, mailContent } = item;

    const now = moment();

    return (
      <div className="container">
        <header>
          <Flex justifyContent="space-between">
            <Box width={1 / 2}>
              <Icon.ArrowLeft
                style={{ paddingBottom: "6px" }}
                onClick={() => this.props.history.goBack()}
              />
              <h1>係メール作成</h1>
            </Box>
          </Flex>
        </header>

        <Formik
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(false);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting
          }) => (
            <form onSubmit={handleSubmit}>
              <div style={{ padding: 30, fontSize: "20px" }}>
                <Persist name="create-team-report-form" />
                <TeamName>
                  {now.format("M/D")} {item.teamName}
                </TeamName>

                {/* <Box>
                  <Flex justifyContent="space-between">
                    <textarea
                      name="description"
                      placeholder="備考欄"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.description}
                    />
                  </Flex>
                </Box> */}

                <ul style={{ marginTop: 30 }}>
                  {item.members.map((member, i) => {
                    const { todayReport, monthlyReports } = member;

                    return (
                      <ReportList
                        key={i}
                        style={{ marginTop: 30, fontSize: 12 }}
                      >
                        <Flex justifyContent="space-between">
                          <div
                            style={{
                              fontWeight: "bold",
                              fontSize: 18,
                              marginBottom: 12
                            }}
                          >
                            {member.name}
                          </div>
                          {/* <div style={{ textAlign: "right" }}>
                            <Field
                              component={RadioButton}
                              name="mailCheck"
                              id="確認"
                              label="確認"
                            />
                          </div> */}
                        </Flex>

                        {/* 本日結果/CT */}
                        {this.renderTodayReport(todayReport)}

                        {/* 月度結果 */}
                        {monthlyReports.map((monthlyReport, i) => {
                          return this.renderMonthlyReport(monthlyReport, i);
                        })}
                      </ReportList>
                    );
                  })}
                </ul>
                <div style={{ textAlign: "center" }}>
                  <RoundedButton
                    style={{ margin: 20, padding: 15, width: 200 }}
                    as="div"
                  >
                    <a
                      style={{ color: "white" }}
                      href={`mailto:?subject=${mailSubject}&body=${mailContent}`}
                    >
                      係メールを作成する
                    </a>
                  </RoundedButton>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    );
  }
}

const RadioButton = ({
  field: { name, value, onChange, onBlur },
  id,
  label,
  className,
  ...props
}) => {
  return (
    <div>
      <input
        name={name}
        id={id}
        type="radio"
        value={id}
        checked={id === value}
        onChange={onChange}
        onBlur={onBlur}
        {...props}
      />
      <label htmlFor={id}>{label}</label>
    </div>
  );
};

const TeamName = styled.span`
  padding: 8px 8px;
  margin-bottom: 16px;
  display: inline-box;
  height: 100%;
  font-size: 14px;
  border: 1px solid;
  border-color: ${Colors.black};
  border-radius: 5px 5px;
`;
const ReportList = styled.li`
  list-style: none;
  border-bottom: 1px solid;
  border-bottom-color: rgb(230, 236, 240);
  padding: 16px;
  font-size: 20px;
`;

function mapStateToProps(state) {
  return {
    teamReport: state.teamReport
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    {
      loadCreateTeamReportPage
    }
  )(CreateTeamReport)
);
