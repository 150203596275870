import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import * as Icon from "react-feather";
import { Flex, Box } from "rebass";
import { Colors } from "../../themes";
import { loadAdminReportOrders } from "../../actions";
import * as moment from "moment";

class ReportOrders extends Component {
  constructor(props) {
    super(props);

    this.renderOrders = this.renderOrders.bind(this);
    this.renderOrder = this.renderOrder.bind(this);
  }
  componentWillMount() {
    const { id } = this.props;
    this.props.loadAdminReportOrders(id);
  }

  renderOrder(order) {
    const {
      name,
      nameKana,
      orderTime,
      isRevisited,
      noticeDate,
      constructionDate,
      confirmContract,
      depositType,
      depositApartment,
      hasElectricityApplication,
      address,
      homePhoneNumber,
      cellPhoneNumber,
      previousCompany,
      previousCompanyOffice,
      bombName,
      salesOffice,
      eventNumber,
      status,
      isEvent,
      description,
      excluded,
      created
    } = order;

    return (
      <Frame status={status} excluded={excluded}>
        <Flex justifyContent="space-between">
          <Date>
            <a>{moment(created).format("MM/DD")}</a>
          </Date>
        </Flex>
        <ContentFrame>
          <Flex justifyContent="space-between" style={{ marginTop: 15 }}>
            <div>オーダー時間</div>
            <div>{orderTime}</div>
          </Flex>
          <Flex justifyContent="space-between">
            <div>即決/再訪</div>
            <div>{isRevisited ? "再訪" : "即決"}</div>
          </Flex>
          <Flex justifyContent="space-between">
            <div>お名前</div>
            <div>{name}</div>
          </Flex>
          <Flex justifyContent="space-between">
            <div>お名前（ふりがな）</div>
            <div>{nameKana}</div>
          </Flex>
          <Flex justifyContent="space-between">
            <div>通告日</div>
            <div>{moment(noticeDate).format("YYYY/MM/DD")}</div>
          </Flex>
          <Flex justifyContent="space-between">
            <div>工事日</div>
            <div>{moment(constructionDate).format("YYYY/MM/DD")}</div>
          </Flex>
          <Flex justifyContent="space-between">
            <div>契約書確認</div>
            <div>{confirmContract ? "有" : "無"}</div>
          </Flex>
          <Flex justifyContent="space-between">
            <div>入金体系</div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div>
                {depositType}
                {depositApartment ? `${depositApartment}本` : ""}
              </div>
            </div>
          </Flex>
          <Flex justifyContent="space-between">
            <div>電力申込</div>
            <div>{hasElectricityApplication ? "有" : "無"}</div>
          </Flex>
          <Flex justifyContent="space-between">
            <div>住所</div>
            <div>{address}</div>
          </Flex>
          <Flex justifyContent="space-between">
            <div>電話番号（自宅）</div>
            <div>{homePhoneNumber}</div>
          </Flex>
          <Flex justifyContent="space-between">
            <div>電話番号（携帯）</div>
            <div>{cellPhoneNumber}</div>
          </Flex>
          <Flex justifyContent="space-between">
            <div>ガス屋名</div>
            <div>{previousCompany}</div>
          </Flex>
          <Flex justifyContent="space-between">
            <div>ガス屋営業所名</div>
            <div>{previousCompanyOffice}</div>
          </Flex>
          <Flex justifyContent="space-between">
            <div>ボンベ名</div>
            <div>{bombName}</div>
          </Flex>
          <Flex justifyContent="space-between">
            <div>ニチガス営業所名</div>
            <div>{salesOffice}</div>
          </Flex>
          <Flex justifyContent="space-between">
            <div>イベントNo.</div>
            <div>{eventNumber}</div>
          </Flex>
          <Flex justifyContent="space-between">
            <div>イベント括弧</div>
            <div>{isEvent ? "有" : "無"}</div>
          </Flex>
          <Flex justifyContent="space-between">
            <div>オーダー状況</div>
            <div>{status}</div>
          </Flex>
          <Flex justifyContent="space-between">
            <Box width={1 / 2}>
              <div>メモ</div>
            </Box>
            <Box width={1 / 2}>
              <div style={{ overflowWrap: "break-word" }}>{description}</div>
            </Box>
          </Flex>
          <Flex justifyContent="space-between">
            <div>計上ステータス</div>
            <div>{excluded ? "非計上" : "計上"}</div>
          </Flex>
        </ContentFrame>
      </Frame>
    );
  }

  renderOrders() {
    const { adminReportOrders } = this.props;

    const {
      createdOrders,
      cancelOrders,
      withdrawalOrders,
      reOrders,
      changedDateOrders
    } = adminReportOrders.entities;

    return (
      <div>
        <h2>新規オーダー</h2>
        {createdOrders.length == 0 && (
          <EmptyText>オーダーはありません</EmptyText>
        )}
        {createdOrders.map(order => {
          return this.renderOrder(order);
        })}
        <h2>キャンセルオーダー</h2>
        {cancelOrders.length == 0 && (
          <EmptyText>オーダーはありません</EmptyText>
        )}
        {cancelOrders.map(order => {
          return this.renderOrder(order);
        })}
        <h2>撤回オーダー</h2>
        {withdrawalOrders.length == 0 && (
          <EmptyText>オーダーはありません</EmptyText>
        )}
        {withdrawalOrders.map(order => {
          return this.renderOrder(order);
        })}
        <h2>再計上オーダー</h2>
        {reOrders.length == 0 && <EmptyText>オーダーはありません</EmptyText>}
        {reOrders.map(order => {
          return this.renderOrder(order);
        })}
        <h2>工事日変更</h2>
        {changedDateOrders.length == 0 && (
          <EmptyText>オーダーはありません</EmptyText>
        )}
        {changedDateOrders.map(order => {
          return this.renderOrder(order);
        })}
      </div>
    );
  }

  render() {
    return (
      <div>
        <header>
          <Flex justifyContent="space-between">
            <Box width={2 / 3}>
              <h1>オーダー詳細</h1>
            </Box>
          </Flex>
        </header>

        <ul style={{ marginBottom: 61, padding: 16 }}>
          <li style={{ marginBottom: 8 }}>{this.renderOrders()}</li>
        </ul>
      </div>
    );
  }
}

const Subject = styled.div`
  background-color: rgb(238, 238, 238);
  padding: 16px;
  font-weight: bold;
`;

const Frame = styled.div`
  margin: 12px 12pz;
  padding: 12px 12px;
  width: 100%;
  height: 100%;
  border: 1px solid;
  border-color: ${Colors.black};
  border-radius: 5px 5px;
  box-sizing: border-box;
  margin-bottom: 5px;

  ${props => props.status == "C" && "background-color: #FCCFC9"};
  ${props => props.status == "T" && "background-color: #FCCFC9"};
  ${props => props.excluded == true && "background-color: #e1e5f0"};
`;

const Date = styled.div`
  text-align: center;
  padding: 6px;
  border: 1px solid;
  border-color: ${Colors.black};
  border-radius: 5px 5px;
`;

const ContentFrame = styled.div`
  font-size: 13px;
`;

const EmptyText = styled.div`
  margin-bottom: 16px;
`;

function mapStateToProps(state) {
  return {
    adminReportOrders: state.adminReportOrders
  };
}

export default connect(
  mapStateToProps,
  {
    loadAdminReportOrders
  }
)(ReportOrders);
