import { BLOCK_REPORTS } from "../actions";
import { merge } from "lodash/object";

const initialState = {
  items: []
};

const blockReports = (state = initialState, action) => {
  switch (action.type) {
    case BLOCK_REPORTS["REQUEST"]:
      return initialState;
    case BLOCK_REPORTS["SUCCESS"]:
      return merge({}, state, { items: action.response });
    default:
      return initialState;
  }
};

export default blockReports;
