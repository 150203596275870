import { ME } from "../actions";
import Immutable from "seamless-immutable";

const initialState = Immutable({
  user: {
    team: {
      name: ""
    },
    block: {
      name: ""
    }
  },
  score: {
    currentMonthlyReport: {
      workingCount: 0,
      meetingCount: 0,
      talkCount: 0,
      grossCount: 0,
      stoveCount: 0,
      cancelCount: 0,
      cancelStoveCount: 0,
      withdrawalCount: 0,
      withdrawalStoveCount: 0,
      grossPoint: null,
      totalCancelCount: 0,
      totalCancelStoveCount: 0,
      totalCancelPoint: 0,
      netCount: 0,
      netStoveCount: 0,
      netPoint: 0,
      cancelWithdrawalRate: null,
      breakthroughRate: null,
      determinedRate: null,
      estimatedNet: null,
      achievementRate: null
    },
    previousMonthlyReport: {
      workingCount: 0,
      meetingCount: 0,
      talkCount: 0,
      grossCount: 0,
      stoveCount: 0,
      cancelCount: 0,
      cancelStoveCount: 0,
      withdrawalCount: 0,
      withdrawalStoveCount: 0,
      grossPoint: null,
      totalCancelCount: 0,
      totalCancelStoveCount: 0,
      totalCancelPoint: 0,
      netCount: 0,
      netStoveCount: 0,
      netPoint: 0,
      cancelWithdrawalRate: null,
      breakthroughRate: null,
      determinedRate: null,
      estimatedNet: null,
      achievementRate: null
    }
  },
  reportsConnection: {
    pageInfo: {},
    edges: []
  },
  page: 1
});

const user = (state = initialState, action) => {
  switch (action.type) {
    case ME["REQUEST"]:
      return initialState;
    case ME["SUCCESS"]:
      return state.merge({ ...action.response, page: 1 });
    case ME["UPDATE_SCORE"]:
      return state.merge({
        score: action.response
      });
    case ME["LOADMORE"]:
      return state.merge({
        reportsConnection: {
          pageInfo: action.response.reportsConnection.pageInfo,
          edges: [
            ...state.reportsConnection.edges,
            ...action.response.reportsConnection.edges
          ]
        },
        page: state.page + 1
      });
    default:
      return state;
  }
};

export default user;
