import React, { Component } from "react";
import styled from "styled-components";
import { Flex, Box } from "rebass";
import { Formik, Field } from "formik";
import { Toggle } from "react-formik-ui";
import { RoundedButton } from "../themes";
import API from "../services/api";
import moment from "moment";

export default class UpdateOrderForm extends Component {
  constructor(props) {
    super(props);

    this.handleConfirmContract = this.handleConfirmContract.bind(this);
    this.handleIsEvent = this.handleIsEvent.bind(this);
  }
  state = {
    displayDepositAparatment: false
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleConfirmContract() {
    this.setState({ confirmContract: !this.state.confirmContract });
  }

  handleIsEvent() {
    this.setState({ isEvent: !this.state.isEvent });
  }

  handleSubmit(event) {
    event.preventDefault();
  }

  render() {
    const currentDate = moment().format("YYYY/MM/DD");
    const { order } = this.props;
    return (
      <Formik
        initialValues={{
          orderTime: order.orderTime,
          isRevisited: order.isRevisited,
          name: order.name,
          nameKana: order.nameKana,
          noticeDate: moment(order.noticeDate).format("YYYY-MM-DD") || "",
          constructionDate:
            moment(order.constructionDate).format("YYYY-MM-DD") || "",
          confirmContract: order.confirmContract,
          depositType: order.depositType,
          depositApartment: order.depositApartment,
          hasElectricityApplication: order.hasElectricityApplication,
          address: order.address,
          homePhoneNumber: order.homePhoneNumber,
          cellPhoneNumber: order.cellPhoneNumber,
          previousCompany: order.previousCompany,
          previousCompanyOffice: order.previousCompanyOffice,
          bombName: order.bombName,
          salesOffice: order.salesOffice,
          eventNumber: order.eventNumber,
          isEvent: order.isEvent,
          status: order.status,
          description: order.description,
          excluded: order.excluded
        }}
        validate={values => {
          let errors = {};
          if (!values.orderTime) {
            errors.orderTime = "必須項目です";
          }
          if (!values.name) {
            errors.name = "必須項目です";
          }
          if (!values.nameKana) {
            errors.nameKana = "必須項目です";
          }
          if (!values.noticeDate) {
            errors.noticeDate = "必須項目です";
          }
          if (!values.constructionDate) {
            errors.constructionDate = "必須項目です";
          }
          if (!values.address) {
            errors.address = "必須項目です";
          }
          if (!values.depositType) {
            errors.depositType = "必須項目です";
          }
          if (values.depositType == "AP" && !values.depositApartment) {
            errors.depositApartment = "必須項目です";
          }
          if (!values.previousCompany) {
            errors.previousCompany = "必須項目です";
          }
          if (!values.bombName) {
            errors.bombName = "必須項目です";
          }
          return errors;
        }}
        onSubmit={async (values, { setSubmitting }) => {
          API.setToken(localStorage.getItem("token"));
          let res = await API.updateOrder(order.id, values);

          if (res.ok) {
            setSubmitting(false);
            this.props.history.goBack();
          } else {
            alert(
              "エラーが発生しました。しばらくたってから再度お試しください。"
            );
            setSubmitting(false);
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting
        }) => (
          <OrderFrom onSubmit={handleSubmit}>
            <Flex
              justifyContent="space-between"
              style={{ marginBottom: 25, height: 60 }}
            >
              <Box width={1 / 2}>
                <span>申込日</span>
              </Box>
              <Box width={1 / 2} style={{ textAlign: "right" }}>
                <span>{currentDate}</span>
              </Box>
            </Flex>

            <Flex
              justifyContent="space-between"
              style={{ marginBottom: 35, height: 60 }}
            >
              <Box
                width={1 / 2}
                style={{ display: "flex", flexDirection: "column" }}
              >
                <span>オーダー時間</span>
                <Required>必須項目</Required>
              </Box>
              <Box width={1 / 2}>
                <select
                  name="orderTime"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.orderTime}
                  style={{ fontSize: "16px" }}
                >
                  <option value="9:00 AM~10:00 AM">9:00 AM~10:00 AM</option>
                  <option value="10:00 AM~11:00 AM">10:00 AM~11:00 AM</option>
                  <option value="11:00 AM~12:00 PM">11:00 AM~12:00 PM</option>
                  <option value="12:00 PM~1:00 PM">12:00 PM~1:00 PM</option>
                  <option value="1:00 PM~2:00 PM">1:00 PM~2:00 PM</option>
                  <option value="2:00 PM~3:00 PM">2:00 PM~3:00 PM</option>
                  <option value="3:00 PM~4:00 PM">3:00 PM~4:00 PM</option>
                  <option value="4:00 PM~5:00 PM">4:00 PM~5:00 PM</option>
                  <option value="5:00 PM~6:00 PM">5:00 PM~6:00 PM</option>
                  <option value="6:00 PM~7:00 PM">6:00 PM~7:00 PM</option>
                  <option value="7:00 PM~8:00 PM">7:00 PM~8:00 PM</option>
                  <option value="8:00 PM~9:00 PM">8:00 PM~9:00 PM</option>
                  <option value="9:00 PM~">9:00 PM</option>
                </select>
                {errors.orderTime && touched.orderTime && (
                  <ErrorMessage>{errors.orderTime}</ErrorMessage>
                )}
              </Box>
            </Flex>

            <Flex
              justifyContent="space-between"
              style={{ marginBottom: 35, height: 60 }}
            >
              <Box width={2 / 4}>
                <span>即決/再訪</span>
              </Box>
              <Box width={1 / 4}>
                <div>
                  <label>
                    <Toggle name="isRevisited" />
                  </label>
                </div>
              </Box>
              {values.isRevisited == false && (
                <Box width={1 / 4}>
                  <span>即決</span>
                </Box>
              )}
              {values.isRevisited == true && (
                <Box width={1 / 4}>
                  <span>再訪</span>
                </Box>
              )}
            </Flex>

            <Flex
              justifyContent="space-between"
              style={{ marginBottom: 35, height: 60 }}
            >
              <Box
                width={1 / 2}
                style={{ display: "flex", flexDirection: "column" }}
              >
                <span>お名前</span>
                <Required>必須項目</Required>
              </Box>
              <Box width={1 / 2}>
                <textarea
                  type="text"
                  name="name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                />
                {errors.name && touched.name && (
                  <ErrorMessage>{errors.name}</ErrorMessage>
                )}
              </Box>
            </Flex>

            <Flex
              justifyContent="space-between"
              style={{ marginBottom: 35, height: 60 }}
            >
              <Box
                width={1 / 2}
                style={{ display: "flex", flexDirection: "column" }}
              >
                <span>お名前（かな）</span>
                <Sub>*ひらがなで入力</Sub>
                <Required>必須項目</Required>
              </Box>
              <Box width={1 / 2}>
                <textarea
                  type="text"
                  name="nameKana"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.nameKana}
                />
                {errors.nameKana && touched.nameKana && (
                  <ErrorMessage>{errors.nameKana}</ErrorMessage>
                )}
              </Box>
            </Flex>

            <Flex
              justifyContent="space-between"
              style={{ marginBottom: 35, height: 60 }}
            >
              <Box
                width={1 / 2}
                style={{ display: "flex", flexDirection: "column" }}
              >
                <span>通告日</span>
              </Box>
              <Box width={1 / 2} style={{ textAlign: "right" }}>
                <div>{moment(values.noticeDate).format("YYYY/MM/DD")}</div>
              </Box>
            </Flex>

            <Flex
              justifyContent="space-between"
              style={{ marginBottom: 35, height: 60 }}
            >
              <Box
                width={1 / 2}
                style={{ display: "flex", flexDirection: "column" }}
              >
                <span>工事日</span>
              </Box>
              <Box width={1 / 2} style={{ textAlign: "right" }}>
                <div>
                  {moment(values.constructionDate).format("YYYY/MM/DD")}
                </div>
              </Box>
            </Flex>

            <Flex
              justifyContent="space-between"
              style={{ marginBottom: 35, height: 60 }}
            >
              <Box
                width={2 / 4}
                style={{ display: "flex", flexDirection: "column" }}
              >
                <span>契約書確認</span>
              </Box>
              <Box width={1 / 4}>
                <div>
                  <label>
                    <Toggle name="confirmContract" />
                  </label>
                </div>
              </Box>
              {values.confirmContract == false && (
                <Box width={1 / 4}>
                  <span>なし</span>
                </Box>
              )}
              {values.confirmContract == true && (
                <Box width={1 / 4}>
                  <span>あり</span>
                </Box>
              )}
            </Flex>

            <Flex
              justifyContent="space-between"
              style={{ marginBottom: 35, height: 60 }}
            >
              <Box
                width={1 / 2}
                style={{ display: "flex", flexDirection: "column" }}
              >
                <span>入金体系</span>
                <Required>必須項目</Required>
              </Box>

              <Box width={3 / 6}>
                <RadioButtonGroup
                  id="depositTypeGroup"
                  value={values.depositType}
                  error={errors.depositType}
                  touched={touched.depositType}
                >
                  <Flex justifyContent="space-between" style={{ height: 60 }}>
                    <Box width={1 / 3}>
                      <Field
                        component={RadioButton}
                        name="depositType"
                        id="通常"
                        label="通常"
                      />
                    </Box>
                    <Box width={1 / 3}>
                      <Field
                        component={RadioButton}
                        name="depositType"
                        id="コンロ"
                        label="コンロ"
                      />
                    </Box>
                    <Box width={1 / 3}>
                      <Field
                        component={RadioButton}
                        name="depositType"
                        id="AP"
                        label="AP"
                      />
                    </Box>
                  </Flex>
                </RadioButtonGroup>
              </Box>
            </Flex>

            {values.depositType == "AP" && (
              <Flex
                justifyContent="space-between"
                style={{ marginBottom: 35, height: 60 }}
              >
                <Box
                  width={1 / 2}
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <span>AP本数</span>
                  <Required>必須項目</Required>
                </Box>

                <Box
                  width={1 / 2}
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <div>
                    <select
                      name="depositApartment"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.depositApartment}
                      style={{ fontSize: "16px" }}
                    >
                      <option value="">-</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                      <option value="10">10</option>
                      <option value="11">11</option>
                      <option value="12">12</option>
                      <option value="13">13</option>
                      <option value="14">14</option>
                      <option value="15">15</option>
                      <option value="16">16</option>
                      <option value="17">17</option>
                      <option value="18">18</option>
                      <option value="19">19</option>
                      <option value="20">20</option>
                      <option value="21">21</option>
                      <option value="22">22</option>
                      <option value="23">23</option>
                      <option value="24">24</option>
                      <option value="25">25</option>
                      <option value="26">26</option>
                      <option value="27">27</option>
                      <option value="28">28</option>
                      <option value="29">29</option>
                      <option value="30">30</option>
                      <option value="31">31</option>
                      <option value="32">32</option>
                      <option value="33">33</option>
                      <option value="34">34</option>
                      <option value="35">35</option>
                      <option value="36">36</option>
                      <option value="37">37</option>
                      <option value="38">38</option>
                      <option value="39">39</option>
                      <option value="40">40</option>
                      <option value="41">41</option>
                      <option value="42">42</option>
                      <option value="43">43</option>
                      <option value="44">44</option>
                      <option value="45">45</option>
                      <option value="46">46</option>
                      <option value="47">47</option>
                      <option value="48">48</option>
                      <option value="49">49</option>
                      <option value="50">50</option>
                      <option value="51">51</option>
                      <option value="52">52</option>
                      <option value="53">53</option>
                      <option value="54">54</option>
                      <option value="55">55</option>
                      <option value="56">56</option>
                      <option value="57">57</option>
                      <option value="58">58</option>
                      <option value="59">59</option>
                      <option value="60">60</option>
                      <option value="61">61</option>
                      <option value="62">62</option>
                      <option value="63">63</option>
                      <option value="64">64</option>
                      <option value="65">65</option>
                      <option value="66">66</option>
                      <option value="67">67</option>
                      <option value="68">68</option>
                      <option value="69">69</option>
                      <option value="70">70</option>
                      <option value="71">71</option>
                      <option value="72">72</option>
                      <option value="73">73</option>
                      <option value="74">74</option>
                      <option value="75">75</option>
                      <option value="76">76</option>
                      <option value="77">77</option>
                      <option value="78">78</option>
                      <option value="79">79</option>
                      <option value="80">80</option>
                      <option value="81">81</option>
                      <option value="82">82</option>
                      <option value="83">83</option>
                      <option value="84">84</option>
                      <option value="85">85</option>
                      <option value="86">86</option>
                      <option value="87">87</option>
                      <option value="88">88</option>
                      <option value="89">89</option>
                      <option value="90">90</option>
                      <option value="91">91</option>
                      <option value="92">92</option>
                      <option value="93">93</option>
                      <option value="94">94</option>
                      <option value="95">95</option>
                      <option value="96">96</option>
                      <option value="97">97</option>
                      <option value="98">98</option>
                      <option value="99">99</option>
                      <option value="100">100</option>
                    </select>
                    本
                  </div>
                  {errors.depositApartment && touched.depositApartment && (
                    <ErrorMessage>{errors.depositApartment}</ErrorMessage>
                  )}
                </Box>
              </Flex>
            )}

            <Flex
              justifyContent="space-between"
              style={{ marginBottom: 35, height: 60 }}
            >
              <Box
                width={2 / 4}
                style={{ display: "flex", flexDirection: "column" }}
              >
                <span>電力申込</span>
              </Box>
              <Box width={1 / 4}>
                <div>
                  <label>
                    <Toggle name="hasElectricityApplication" />
                  </label>
                </div>
              </Box>
              {values.hasElectricityApplication == false && (
                <Box width={1 / 4}>
                  <span>なし</span>
                </Box>
              )}
              {values.hasElectricityApplication == true && (
                <Box width={1 / 4}>
                  <span>あり</span>
                </Box>
              )}
            </Flex>

            <Flex
              justifyContent="space-between"
              style={{ marginBottom: 35, height: 60 }}
            >
              <Box
                width={1 / 2}
                style={{ display: "flex", flexDirection: "column" }}
              >
                <span>住所</span>
                <Required>必須項目</Required>
              </Box>
              <Box width={1 / 2}>
                <textarea
                  type="text"
                  name="address"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.address}
                />
                {errors.address && touched.address && (
                  <ErrorMessage>{errors.address}</ErrorMessage>
                )}
              </Box>
            </Flex>

            <Flex
              justifyContent="space-between"
              style={{ marginBottom: 35, height: 60 }}
            >
              <Box
                width={1 / 2}
                style={{ display: "flex", flexDirection: "column" }}
              >
                <span>電話番号（自宅）</span>
                <Sub>*ハイフン不要</Sub>
              </Box>
              <Box width={1 / 2}>
                <input
                  type="tel"
                  name="homePhoneNumber"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.homePhoneNumber}
                />
              </Box>
            </Flex>

            <Flex
              justifyContent="space-between"
              style={{ marginBottom: 35, height: 60 }}
            >
              <Box
                width={1 / 2}
                style={{ display: "flex", flexDirection: "column" }}
              >
                <span>電話番号（携帯）</span>
                <Sub>*ハイフン不要</Sub>
              </Box>
              <Box width={1 / 2}>
                <input
                  type="tel"
                  name="cellPhoneNumber"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.cellPhoneNumber}
                />
                {errors.phoneNumberRequired &&
                  (touched.homePhoneNumber || touched.cellPhoneNumber) && (
                    <ErrorMessage>{errors.phoneNumberRequired}</ErrorMessage>
                  )}
              </Box>
            </Flex>

            <Flex
              justifyContent="space-between"
              style={{ marginBottom: 35, height: 60 }}
            >
              <Box
                width={1 / 2}
                style={{ display: "flex", flexDirection: "column" }}
              >
                <span>ガス会社名</span>
                <Required>必須項目</Required>
              </Box>
              <Box width={1 / 2}>
                <textarea
                  type="text"
                  name="previousCompany"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.previousCompany}
                />
                {errors.previousCompany && touched.previousCompany && (
                  <ErrorMessage>{errors.previousCompany}</ErrorMessage>
                )}
              </Box>
            </Flex>

            <Flex
              justifyContent="space-between"
              style={{ marginBottom: 35, height: 60 }}
            >
              <Box
                width={1 / 2}
                style={{ display: "flex", flexDirection: "column" }}
              >
                <span>ガス会社営業所名</span>
                <Sub>*ない場合未記入</Sub>
              </Box>
              <Box width={1 / 2}>
                <textarea
                  type="text"
                  name="previousCompanyOffice"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.previousCompanyOffice}
                />
              </Box>
            </Flex>

            <Flex
              justifyContent="space-between"
              style={{ marginBottom: 35, height: 60 }}
            >
              <Box
                width={1 / 2}
                style={{ display: "flex", flexDirection: "column" }}
              >
                <span>ボンベ名</span>
                <Required>必須項目</Required>
              </Box>
              <Box width={1 / 2}>
                <textarea
                  type="text"
                  name="bombName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.bombName}
                />
                {errors.bombName && touched.bombName && (
                  <ErrorMessage>{errors.bombName}</ErrorMessage>
                )}
              </Box>
            </Flex>

            <Flex
              justifyContent="space-between"
              style={{ marginBottom: 35, height: 60 }}
            >
              <Box width={1 / 2}>
                <span>ニチガス営業所名</span>
              </Box>
              <Box width={1 / 2}>
                <select
                  name="salesOffice"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.salesOffice}
                  style={{ fontSize: "16px" }}
                >
                  <option value="">-</option>
                  <option value="南関東支店開発課">南関東支店開発課</option>
                  <option value="緑営業所">緑営業所</option>
                  <option value="旭営業所">旭営業所</option>
                  <option value="戸塚営業所">戸塚営業所</option>
                  <option value="横須賀営業所">横須賀営業所</option>
                  <option value="湘南営業所">湘南営業所</option>
                  <option value="川崎営業所">川崎営業所</option>
                  <option value="町田営業所">町田営業所</option>
                  <option value="海老名営業所">海老名営業所</option>
                  <option value="平塚営業所">平塚営業所</option>
                  <option value="秦野営業所">秦野営業所</option>
                  <option value="小田原営業所">小田原営業所</option>
                  <option value="三島営業所">三島営業所</option>
                  <option value="富士営業所">富士営業所</option>
                  <option value="静岡営業所">静岡営業所</option>
                  <option value="焼津営業所">焼津営業所</option>
                  <option value="西関東支店開発課">西関東支店開発課</option>
                  <option value="津久井営業所">津久井営業所</option>
                  <option value="第一部開発課">第一部開発課</option>
                  <option value="八王子営業所">八王子営業所</option>
                  <option value="淵野辺営業所">淵野辺営業所</option>
                  <option value="相武台営業所">相武台営業所</option>
                  <option value="第二部開発課">第二部開発課</option>
                  <option value="日高営業所">日高営業所</option>
                  <option value="狭山営業所">狭山営業所</option>
                  <option value="瑞穂準備室">瑞穂準備室</option>
                  <option value="第三部開発課">第三部開発課</option>
                  <option value="甲府営業所">甲府営業所</option>
                  <option value="大月営業所">大月営業所</option>
                  <option value="北杜営業所">北杜営業所</option>
                  <option value="東関東支店開発課">東関東支店開発課</option>
                  <option value="流山営業所">流山営業所</option>
                  <option value="高柳営業所">高柳営業所</option>
                  <option value="市川営業所">市川営業所</option>
                  <option value="成田営業所">成田営業所</option>
                  <option value="八街営業所">八街営業所</option>
                  <option value="八千代営業所">八千代営業所</option>
                  <option value="八日市営業所">八日市営業所</option>
                  <option value="千葉営業所">千葉営業所</option>
                  <option value="東金営業所">東金営業所</option>
                  <option value="茂原営業所">茂原営業所</option>
                  <option value="市原営業所">市原営業所</option>
                  <option value="君津営業所">君津営業所</option>
                  <option value="土浦営業所">土浦営業所</option>
                  <option value="つくば営業所">つくば営業所</option>
                  <option value="藤代営業所">藤代営業所</option>
                  <option value="潮来営業所">潮来営業所</option>
                  <option value="水戸営業所">水戸営業所</option>
                  <option value="水戸南営業所">水戸南営業所</option>
                  <option value="茨城営業所">茨城営業所</option>
                  <option value="館林営業所">館林営業所</option>
                  <option value="太田営業所">太田営業所</option>
                  <option value="高崎営業所">高崎営業所</option>
                  <option value="真岡営業所">真岡営業所</option>
                  <option value="宇都宮営業所">宇都宮営業所</option>
                  <option value="矢板営業所">矢板営業所</option>
                  <option value="福島営業所">福島営業所</option>
                </select>
              </Box>
            </Flex>

            <Flex
              justifyContent="space-between"
              style={{ marginBottom: 35, height: 60 }}
            >
              <Box width={1 / 2}>
                <span>イベントNo.</span>
              </Box>
              <Box width={1 / 2}>
                <select
                  name="eventNumber"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.eventNumber}
                  style={{ fontSize: "16px" }}
                >
                  <option value="">-</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                  <option value="13">13</option>
                  <option value="14">14</option>
                  <option value="15">15</option>
                </select>
                {errors.eventNumber && touched.eventNumber && (
                  <ErrorMessage>{errors.eventNumber}</ErrorMessage>
                )}
              </Box>
            </Flex>

            <Flex
              justifyContent="space-between"
              style={{ marginBottom: 35, height: 60 }}
            >
              <Box width={2 / 4}>
                <span>イベント括弧</span>
              </Box>
              <Box width={1 / 4}>
                <div>
                  <label>
                    <Toggle name="isEvent" />
                  </label>
                </div>
              </Box>
              {values.isEvent == false && (
                <Box width={1 / 4}>
                  <span>なし</span>
                </Box>
              )}
              {values.isEvent == true && (
                <Box width={1 / 4}>
                  <span>あり</span>
                </Box>
              )}
            </Flex>

            <Flex
              justifyContent="space-between"
              style={{ marginBottom: 35, height: 60 }}
            >
              <Box
                width={1 / 2}
                style={{ display: "flex", flexDirection: "column" }}
              >
                <span>メモ</span>
                <Sub>*大家さん情報など</Sub>
              </Box>
              <Box width={1 / 2}>
                <textarea
                  type="text"
                  name="description"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.description}
                />
                {errors.description && touched.description && (
                  <ErrorMessage>{errors.description}</ErrorMessage>
                )}
              </Box>
            </Flex>

            <Flex
              justifyContent="space-between"
              style={{ marginBottom: 35, height: 60 }}
            >
              <Box width={2 / 4}>
                <span>非計上</span>
              </Box>
              <Box width={1 / 4}>
                <div>
                  <label>
                    <Toggle name="excluded" />
                  </label>
                </div>
              </Box>
              {values.excluded == false && (
                <Box width={1 / 4}>
                  <span>なし</span>
                </Box>
              )}
              {values.excluded == true && (
                <Box width={1 / 4}>
                  <span>あり</span>
                </Box>
              )}
            </Flex>

            <div style={{ textAlign: "center" }}>
              <RoundedButton
                style={{ padding: 8, width: 200 }}
                type="submit"
                isSubmitting={isSubmitting}
                disabled={isSubmitting}
              >
                <span>{isSubmitting ? "保存中..." : "保存"}</span>
              </RoundedButton>
            </div>
          </OrderFrom>
        )}
      </Formik>
    );
  }
}

UpdateOrderForm.defaultProps = {
  order: {
    orderTime: "9:00 AM~10:00 AM",
    isRevisted: false,
    name: "",
    nameKana: "",
    noticeDate: "",
    constructionDate: "",
    isRevisited: false,
    confirmContract: false,
    depositType: "",
    depositApartment: "0",
    hasElectricityApplication: false,
    address: "",
    homePhoneNumber: "",
    cellPhoneNumber: "",
    bombName: "",
    salesOffice: "",
    eventNumber: "",
    isEvent: false,
    status: "",
    description: "",
    excluded: false
  }
};

const InputFeedback = ({ error }) =>
  error ? <ErrorMessage>{error}</ErrorMessage> : null;

// Radio group
const RadioButtonGroup = ({
  value,
  error,
  touched,
  id,
  label,
  className,
  children
}) => {
  return (
    <div>
      <fieldset>
        <legend>{label}</legend>
        {children}
        {touched && <InputFeedback error={error} />}
      </fieldset>
    </div>
  );
};

const RadioButton = ({
  field: { name, value, onChange, onBlur },
  id,
  label,
  className,
  ...props
}) => {
  return (
    <div>
      <input
        name={name}
        id={id}
        type="radio"
        value={id}
        checked={id === value}
        onChange={onChange}
        onBlur={onBlur}
        {...props}
      />
      <label htmlFor={id}>{label}</label>
    </div>
  );
};

const NumberInput = styled.input`
  text-align: right;
`;

const Sub = styled.span`
  font-size: 10px;
  color: rgb(101, 119, 134);
`;

const Required = styled.span`
  max-width: 60px;
  font-size: 10px;
  text-align: center;
  display: inline-block;
  padding: 2px;
  margin-top: 10px;
  color: #fff;
  background-color: #fa8072;
  border-radius: 5px;
`;

const ErrorMessage = styled.span`
  margin-top: 5px;
  font-size: 12px;
  font-weight: 600;
  color: #fa8072;
`;

const OrderFrom = styled.form`
  padding: 16px;

  & input {
    font-size: 16px;
    padding: 8px;
  }
`;
