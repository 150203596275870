import React, { Component } from "react";
import styled from "styled-components";
import { Heading } from "rebass";
import { Flex, Box } from "rebass";
import { RoundedButton } from "../themes";
import { Formik } from "formik";
import API from "../services/api";

export default class Login extends Component {
  render() {
    let email = "";
    if (localStorage.getItem("email")) {
      email = localStorage.getItem("email");
    }

    return (
      <div
        style={{
          top: "45%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          position: "absolute",
          height: "200px"
        }}
      >
        <Heading style={{ textAlign: "center", marginBottom: 15 }}>
          ON THE PLANET
          <br />
          日報
        </Heading>

        <Formik
          initialValues={{
            email,
            password: ""
          }}
          validate={values => {
            let errors = {};
            if (!values.password) {
              errors.password = "必須項目です";
            }
            if (!values.email) {
              errors.email = "必須項目です";
            }
            return errors;
          }}
          onSubmit={async (values, { setSubmitting }) => {
            localStorage.setItem("email", values.email);
            const res = await API.login(values);
            if (res.ok) {
              setSubmitting(false);
              localStorage.setItem("token", res.data.token);
              this.props.history.push("/mypage");
            } else {
              alert(
                "エラーが発生しました。しばらくたってから再度お試しください。"
              );
              setSubmitting(false);
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting
          }) => (
            <form onSubmit={handleSubmit}>
              <Box
                justifyContent="space-between"
                style={{ marginBottom: 10, width: 300, height: 90 }}
              >
                <Box>
                  <span>メールアドレス</span>
                </Box>
                <Box>
                  <input
                    type="text"
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                  />
                  {errors.email && touched.email && (
                    <ErrorMessage>{errors.email}</ErrorMessage>
                  )}
                </Box>
                <Box style={{ marginTop: 20 }}>
                  <span>パスワード</span>
                </Box>
                <Box>
                  <input
                    type="password"
                    name="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                  />
                  {errors.password && touched.password && (
                    <ErrorMessage>{errors.password}</ErrorMessage>
                  )}
                </Box>
              </Box>

              <div style={{ textAlign: "center", marginTop: 85 }}>
                <RoundedButton
                  style={{ padding: 8, width: 200 }}
                  type="submit"
                  isSubmitting={isSubmitting}
                  disabled={isSubmitting}
                >
                  <span>{isSubmitting ? "ログイン..." : "ログイン"}</span>
                </RoundedButton>
              </div>
            </form>
          )}
        </Formik>
      </div>
    );
  }
}

const LoginForm = styled.form`
  padding: 16px;
`;

const ErrorMessage = styled.span`
  margin-top: 8px;
  font-size: 12px;
  font-weight: 600;
  color: #fa8072;
`;
