import { ORDERS } from "../actions";
import Immutable from "seamless-immutable";

const initialState = Immutable({
  pageInfo: {},
  edges: [],
  page: 1
});

const orders = (state = initialState, action) => {
  switch (action.type) {
    case ORDERS["REQUEST"]:
      return initialState;
    case ORDERS["SUCCESS"]:
      return state.merge({
        pageInfo: action.response.ordersConnection.pageInfo,
        edges: action.response.ordersConnection.edges,
        page: 1
      });
    case ORDERS["LOADMORE"]:
      return state.merge({
        pageInfo: action.response.ordersConnection.pageInfo,
        edges: [...state.edges, ...action.response.ordersConnection.edges],
        page: state.page + 1
      });
    default:
      return state;
  }
};

export default orders;
