import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Flex, Box } from "rebass";
import * as Icon from "react-feather";
import UpdateOrderForm from "../components/UpdateOrderForm";
import { loadUpdateOrderPage } from "../actions";

class UpdateOrder extends Component {
  componentWillMount() {
    const { id } = this.props.match.params;
    this.props.loadUpdateOrderPage(id);
  }

  render() {
    const { match, history, order } = this.props;
    if (order.isFetching) {
      return null;
    }
    const { id } = match.params;

    return (
      <div className="container">
        <header>
          <Flex justifyContent="space-between">
            <Box width={2 / 3}>
              <Icon.ArrowLeft
                style={{ paddingBottom: "6px" }}
                onClick={() => history.goBack()}
              />
              <h1>オーダーを編集</h1>
            </Box>
          </Flex>
        </header>
        <div style={{ marginBottom: 35 }}>
          <UpdateOrderForm
            order={order.entities.orders[id]}
            history={history}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    order: state.order
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    {
      loadUpdateOrderPage
    }
  )(UpdateOrder)
);
