import { ENTRY_YEAR_REPORTS } from "../actions";
import { merge } from "lodash/object";

const initialState = {
  items: []
};

const entryYearReports = (state = initialState, action) => {
  switch (action.type) {
    case ENTRY_YEAR_REPORTS["REQUEST"]:
      return initialState;
    case ENTRY_YEAR_REPORTS["SUCCESS"]:
      return merge({}, state, { items: action.response });
    default:
      return initialState;
  }
};

export default entryYearReports;
